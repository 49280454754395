import axios from './axios'

export const chainAxiosRequests = (arr, router = null, path = null) => {
  let index = 0
  function request () {
    return axios.get(arr[index].url, { params: arr[index].params }).then(() => {
      index++
      if (index >= arr.length) {
        /* This redircts the page after the API requests have completed
           Really useful when setting search params and then switching
           to search page for viewing */
        if (router && path) { router.push(path) }
        return 'done'
      }
      return request()
    })
  }
  return request()
}
