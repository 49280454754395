import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'MarketShareReport',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const MSIReport = require('./components/MSIReport').default
        const reducer = require('./reducers/msiReducer').default

        injectReducer(store, { key: 'MarketShareReport', reducer })

        /*  Return getComponent   */
        cb(null, MSIReport)

        /* Webpack named bundle   */
      },
      'MSIReport'
    )
  }
})
