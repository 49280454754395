import locales from '../locales/locales.js'

function getUrlForEnvironment () {
  switch (location.hostname.toLowerCase()) {
    case 'development.netfinderonline.com':
    case 'localhost':
    case '127.0.0.1':
    case '0.0.0.0':
      return 'https://nf15-api.netfinderonline.com/netfinder15-api-dev'
      // return 'https://52.49.229.244/netfinder15-api-dev/'
    case 'staging.netfinderonline.com':
      return 'https://nf15-api.netfinderonline.com/netfinder15-api-staging'
    default:
      return 'https://nf15-api.netfinderonline.com/netfinder15-api'
      // return   'https://52.49.229.244/netfinder15-api'
  }
}
export default {
  API: {
    // URL: process.env.URL, // Added local URL modify this based on environment
    URL: getUrlForEnvironment(),
    HOST: process.env.HOST,
    API_BASE: 'api.pl',
    GET_USER_SETTINGS: 'api.pl?f=getUserSettings',
    GET_USER_HISTORY: 'api.pl?f=getUserLogs',
    LOGOUT: 'api.pl?f=logout',
    SEARCH_OPTIONS: 'api.pl?f=getSearchOptions',
    SET_SEARCH_PARAM: 'api.pl?f=setSearchParam',
    GET_COUNTS: 'api.pl?f=getCounts',
    CLEAR_SEARCH: 'api.pl?f=newSearch',
    GET_COMPANY_RESULTS: 'api.pl?f=getCompanyResults',
    GET_LEADS_RESULTS: 'api.pl?f=getContactResults',
    GET_SITE_RESULTS: 'api.pl?f=getSiteResults',
    SAVE_USER_SETTING: 'api.pl?f=setUserSetting',
    GET_SITE_DETAILS: 'api.pl?f=getSiteDetails',
    GET_COMPANY_DETAILS: 'api.pl?f=getCompanyDetails',
    GET_SEARCH_PARAMS: 'api.pl?f=getSearchParams',
    GET_SAVED_SEARCHES: 'api.pl?f=listSearches',
    SAVE_SEARCH: 'api.pl?f=saveSearch',
    GET_SAVE_SEARCH: 'api.pl?f=loadSearch',
    GET_LABEL: 'api.pl?f=getLabel',
    SAVE_FILE: 'api.pl?f=saveFile',
    LOAD_FILE: 'api.pl?f=loadFile',
    LOAD_SUPPRESSION_FILE: 'api.pl?f=loadSuppressionFile',
    GET_FILES_LIST: 'api.pl?f=listFiles',
    SAVE_BUSINESS_CHANGE: 'api.pl?f=submitChange',
    GET_NOTIFICATION_LIST: 'api.pl?f=listNotifications',
    ACK_NOTIFICATION: 'api.pl?f=ackNotification',
    LIST_USERS: 'api.pl?f=listUsers',
    NEW_USER: 'api.pl?f=newUser',
    RESET_PASSWORD: 'api.pl?f=resetPassword',
    GET_EMAIL_TEMPLATE: 'api.pl?f=getNotificationTemplates',
    PUSH_NOTIFICATION: 'api.pl?f=pushNotification',
    GET_SUGGESTIONS: 'api.pl?f=getSuggestions',
    LOGIN_AS_USER: 'api.pl?f=loginAs',
    DELETE_SEARCH: 'api.pl?f=deleteSearch',
    DELETE_DOWNLOAD: 'api.pl?f=deleteFile',
    MARKET_INSIGHTS: 'api.pl?f=getMarketInsights',
    MARKET_SHARE: 'api.pl?f=getMarketShare'
  },
  ENVIRONMENT: 'environment',
  SENTRY_DSN:
    'https://02aa8f1786c541279f4db6a1ca53f6e9@o1301464.ingest.sentry.io/6543902',
  FUSION_CHARTS_LICENSE_KEY:
    'PtB2dF-7wB4C5C3D5G4D3H3H3A8A8D2B3F6tgoD3B1d1fdzoG4I3I-7jD-17A3D5B3D5D4B3B3C3D7D7C2F5A3G1D2G1rmoE6E1C4gI4B3C8zyhD3D2E3vraA4B2C2zbnD1H4B3A5B3F4F3H3G4A19A32aikC6D3RH4moA-31A7C8A3wfA2IC1C5rA-16mD2D5F1A2B2B7C4D6A3A1E4G2H1C3u==',
  HTTP_STATUS: {
    SUCCESS: 200,
    UNAUTHORIZE: '401',
    BAD_REQUEST: '400',
    CREATE_SUCCESS: '201',
    DELETE_SUCCESS: '204',
    UNEXPECTED_ERRORS: ['500', '501', '502', '503', '504', '505']
  },
  CUSTOM_STATUS: {
    SUCCESS: 900,
    INVALID: 906,
    UNAUTHORIZE: 901,
    SEARCH_INVALID: 910,
    UNABLE_LOAD_SEARCH: 908,
    ADMIN_CREDENTIALS_NEEDED: 923,
    RESETMAIL_SUCCESS: 930,
    CHANGE_PASSWORD: 919,
    ENTER_DIFF_PASS: 927,
    WEAK_PASS: 918
  },
  ROLES: {
    ADMIN: 'ADMIN',
    USER: 'USER'
  },
  VALIDATION: {
    EMPTY_USERNAME: 'Username field cannot be empty',
    EMPTY_PASSWORD: 'Password field cannot be empty'
  },
  MISC: {
    DEFAULT_PAGE_SIZE: 20
  },
  CONSTANTS: {
    CURRENT_YEAR: 2021,
    PROJECT_MODULE_NUM: 40,
    SET_SEARCH_PARAM_DELAY: 1000,
    SET_QUANTITY_SEARCH_DELAY: 500,
    REMEMBER_ME_DAYS: 1,
    PAGINATION_LIMIT: 10000,
    UNAUTHORIZED_USER_URL: 'unauthorizeduser',
    TECH_SEARCH_OBJ: 'install',
    VISIBLE_SEARCH_OPTIONS: [
      'id.quantity',
      'id.vendor_name',
      'id.product_name'
    ],
    VISIBLE_ALL_CATEGORY_SEARCH_OPTIONS: ['vendor_name', 'product_name'],
    MAX_INSTALL_RESPONSES: 3,
    VALID_ROUTES: [
      'userhistory',
      'admin',
      'siteDetails',
      'comapnyDetails',
      'companyDetails',
      'complianceCentre',
      'downloads',
      'forgotPassword',
      'login',
      'MarketShareReport',
      'notifications',
      'passwordreset',
      'results',
      'savedsearches',
      'userdetails',
      'maintenance'
    ],
    EXCLUDE_SEARCH_PARAMS: ['project.is_active'],
    VALID_MIN_ZIP_LENGTH: 5,
    VALID_MAX_ZIP_LENGTH: 7,
    ZIPCODE_PARAM_NAME: 'site.location_zip',
    SICCODE_SITE_PARAM_NAME: 'site.industry.id',
    SICCODE_COMPANY_PARAM_NAME: 'company.industry.id',
    SICCODE_PARAM_NAME: 'site.industry.id',

    SICCODE_PARAM_LABEL: { 'site.industry.id': 'Industries' },
    INSTALL_VENDOR_PARAMNAME: 'install.vendor_name',
    INSTALL_PRODUCT_PARAMNAME: 'install.product_name',
    SUGGESTIONS_WITH_OBJECTS: [
      'industry.id',
      'all',
      'location_city_code',
      'dnb_all'
    ],
    LOCATION_SEARCH_PARAMS: [
      'site.location_city_code',
      'site.location_county_code'
    ],
    LOCATION_SET_PARAMS: ['site.location_city', 'site.location_county'],
    DNB_LABELS: {
      'site.dnb_all': 'DUNS Number or Company Name',
      'site.dnb_dom_ult_duns': 'D&B Domestic Ultimate DUNS',
      'site.dnb_dom_ult_name': 'D&B Domestic Ultimate',
      'site.dnb_duns': 'D&B DUNS Number',
      'site.dnb_global_ult_duns': 'D&B Global Ultimate DUNS',
      'site.dnb_global_ult_name': 'D&B GLobal Ultimate',
      'site.dnb_hq_duns': 'D&B Headquarter DUNS',
      'site.dnb_hq_name': 'D&B Headquarter',
      'site.dnb_name': 'D&B Company Name'
    },
    DNB_PARAMS: [
      'site.dnb_duns',
      'site.dnb_hq_duns',
      'site.dnb_dom_ult_duns',
      'site.dnb_global_ult_duns'
    ],
    SEARCH_PARAMS_SET_PARAMS: {
      'site.location_city_code': 'site.location_city',
      'site.location_county_code': 'site.location_county'
    },
    SECTION_CODES: {
      DNB_PARAMS: 'dnbsection',
      LOCATION_SET_PARAMS: 'citysection'
    },
    UI_SPECIFIC_LABELS: {
      'contact.is_remote': locales.t('search:contact_is_remote_label'),
      'site.ctps': locales.t('search:site_ctps_label'),
      'company.ctps': locales.t('search:company_ctps_label'),
      'contact.ctps': locales.t('search:contact_ctps_label'),
      'project.exclude_expired': locales.t(
        'search:sites_with_active_projects_label'
      )
    },
    FLAGS: [
      'contact.is_remote',
      'contact.has_email',
      'site.ctps',
      'company.ctps',
      'contact.ctps',
      'contact.function_code_only',
      'contact.function_group_code_only',
      'project.is_active',
      'project.exclude_expired',
      'contact.function_code_only',
      'install.headless'
    ],
    NET_PROJECT_FLAG: 'project.exclude_expired',
    LEADS_PARAM: 'Contacts',
    ALLOW_NEW_SUGGESTIONS: ['location_zip', 'all', 'name'],
    INSTALL_SEARCH_OPTIONS: [
      'install.id.quantity',
      'install.id.vendor_name',
      'install.id.product_name',
      'install.vendor_name',
      'install.product_name'
    ],
    DOWNLOAD_TECHNOLOGIES_MAP_LABEL: 'Download our technologies map',
    DOWNLOAD_TECHNOLOGIES_MAP_LOCATION:
      'https://s3-eu-west-1.amazonaws.com/netfinder-public-files/Rhetorik+NetFinder+Data+Modules+and+Taxonomy.pdf',
    DOWNLOAD_TECHNOLOGIES_MAP_FILENAME: 'Technologies Taxonomy map.pdf',
    PILLBOX_DISABLED_MESSAGE:
      'This filter is disabled. Your account does not include access to this data set. Please contact Rhetorik Customer support to upgrade your license.',
    EXCLUDED_STATUS: 1,
    DOWNLOAD_VALID_DAYS: 28,
    MSI_BREAKDOWN_TYPES: {
      VERTICAL_MARKET: locales.t('msi:verticalMarket'),
      REGION: locales.t('msi:region')
    },
    NUM_TOP_VENDORS: 5,
    BLUEGREENCOLORS: [
      '#107dac',
      '#8DCDC1',
      '#D3E397',
      '#FFF5C3',
      '#EB6E44',
      '#192e5b'
    ],
    STATE_API_KEYS_MAPPING: [
      { 'site.site_list_id': 'site.site_leadneuron' },
      { 'contact.contact_list_id': 'contact.contact_leadneuron' },
      { 'site.site_list_id': 'site.site_leadneuron' },
      { 'site.site_list_id': 'site.site_leadneuron' }
    ],
    PARAM_NAME_TO_MODULE_NAME: {
      contact_ctps: 'Contacts',
      contact_has_email: 'Contacts',
      contact_phone: 'Contacts',
      contact_function_code: 'Contacts',
      contact_function_code_only: 'Contacts',
      contact_function_group_code: 'Contacts',
      contact_is_remote: 'Contacts',
      contact_list_leadneuron: 'LeadNeuron',
      site_market_code: 'Verticals',
      site_industry_id: 'Industries',
      site_site_type_code: 'Site Type',
      site_ctps: 'Site Type',
      site_location_region_code: 'Locations',
      site_location_country_code: 'Locations',
      // site_staff_global: 'Business Size',
      // site_staff_in_country: 'Business Size',
      // site_staff_in_site: 'Business Size',
      site_staff_global: 'Employees',
      site_staff_in_country: 'Employees',
      site_staff_in_site: 'Employees',
      site_pc_in_country: 'Business Size',
      site_pc_in_site: 'Business Size',
      // site_dnb_sales: 'Business Size',
      // site_sales: 'Business Size',
      /*      site_site_list_id: 'Lists', */
      site_list_standard: 'Lists',
      site_list_leadneuron: 'LeadNeuron',
      site_dnb_sales: 'Revenues',
      site_sales: 'Revenues',
      contact_contact_list_id: 'LeadNeuron',
      contact_list_standard: 'Lists',
      contact_list_leadneuron: 'LeadNeuron',
      site_dnb_all: 'Dun & Bradstreet',
      site_dnb_dom_ult_duns: 'Dun & Bradstreet',
      site_dnb_dom_ult_name: 'Dun & Bradstreet',
      site_dnb_duns: 'Dun & Bradstreet',
      site_dnb_global_ult_duns: 'Dun & Bradstreet',
      site_dnb_global_ult_name: 'Dun & Bradstreet',
      site_dnb_hq_duns: 'Dun & Bradstreet',
      site_dnb_hq_name: 'Dun & Bradstreet',
      site_dnb_name: 'Dun & Bradstreet',
      site_location_zip: 'Locations',
      site_location_radius: 'Locations'
    },
    PARAM_NAME_TO_LABEL_TYPE: {
      'site.industry.id': 'site.industry_id'
    },
    LABEL_TYPE_TO_PARAM_NAME: {
      'site.industry_id': 'site.industry.id',
      'site.location.city_code': 'site.location_county_code'
    },
    PARAMS_WITH_ASYNC_LABELS: [
      'site.industry.id',
      'company.company_id',
      'site.location_city_code',
      'site.location_county_code'
    ],
    // The order of these countries matters, it controlls the order of display in the UI
    COUNTRIES: {
      ALL: 'All Licensed Countries',
      GB: 'United Kingdom',
      GB_: 'UK',
      IE: 'Ireland',
      FR: 'France',
      DE: 'Germany',
      IT: 'Italy',
      ES: 'Spain',
      DK: 'Denmark',
      SE: 'Sweden',
      NO: 'Norway',
      FI: 'Finland',
      BE: 'Belgium',
      NL: 'Netherlands',
      LU: 'Luxembourg',
      AT: 'Austria',
      CH: 'Switzerland',
      PT: 'Portugal',
      PL: 'Poland',
      IL: 'Israel',
      BG: 'Bulgaria',
      CZ: 'Czechia',
      RO: 'Romania',
      GR: 'Greece',
      HR: 'Croatia',
      EE: 'Estonia',
      HU: 'Hungary',
      LV: 'Latvia',
      SI: 'Slovenia',
      UA: 'Ukraine',
      ZA: 'South Africa',
      AO: 'Angola',
      SA: 'Saudi Arabia',
      TR: 'Turkey',
      SA: 'Saudi Arabia',
      RS: 'Serbia',
      AE: 'U.A.E.',
      EG: 'Egypt'
    },
    COUNTRY_NAMES: {
      ALL: 'all Licensed Countries',
      GB: 'the United Kingdom',
      IE: 'Ireland',
      FR: 'France',
      DE: 'Germany',
      LU: 'Luxembourg',
      NL: 'Netherlands',
      BE: 'Belgium',
      AT: 'Austria',
      SE: 'Sweden',
      IT: 'Italy',
      ES: 'Spain',
      CH: 'Switzerland',
      FI: 'Finland',
      NO: 'Norway',
      DK: 'Denmark',
      PT: 'Portugal',
      PL: 'Poland',
      IL: 'Israel',
      BG: 'Bulgaria',
      CZ: 'Czechia',
      RO: 'Romania',
      GR: 'Greece',
      HR: 'Croatia',
      EE: 'Estonia',
      HU: 'Hungary',
      LV: 'Latvia',
      SI: 'Slovenia',
      UA: 'Ukraine',
      ZA: 'South Africa',
      AO: 'Angola',
      SA: 'Saudi Arabia',
      TR: 'Turkey',
      SA: 'Saudi Arabia',
      RS: 'Serbia',
      AE: 'United Arab Emirates',
      EG: 'Egypt'
    },
    CONTINENTS: {
      EU: 'Europe',
      NA: 'North America',
      SA: 'South America',
      AS: 'Asia',
      AF: 'Africa',
      OC: 'Oceania',
      AN: 'Antartica'
    },
    INCLUDE_CORPORATE: true,
    INCLUDE_PROJECTS: false,
    INCLUDE_CONTACTS: false,
    EMAIL_OPT_IN: ['AT', 'BE', 'DK', 'DE', 'IT', 'LU', 'NO', 'ES', 'CH'],
    PHONE_OPT_IN: ['LU'],
    MOBILE_OPT_IN: ['LU'],
    DDI_OPT_IN: ['LU'],
    COUNTRIES_WITH_REGIONS: ['GB', 'IE'],
    ENFORCED_CRITERIA_SITE_LISTS: ['31'],
    SHOW_LOG_AS_USER_LINK: true,
    HIDEDNBREFERENCES: true,
    EXPLORENOW_MIN_SITE: 50,
    EXPLORENOW_MIN_CONTACTS: 1000,
    MOBILE: {
      label: 'Mobile',
      value: 'mobile'
    },
    DDI: {
      label: 'DDI',
      _label: 'Direct Dial-In (DDI)',
      value: 'ddi'
    }
  }
}
