import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'siteDetails/:siteId',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const SiteDetails = require('./containers/SiteDetailsContainer').default
        const reducer = require('./modules/siteDetails').default

        injectReducer(store, { key: 'siteDetails', reducer })

        /*  Return getComponent   */
        cb(null, SiteDetails)

        /* Webpack named bundle   */
      },
      'SiteDetails'
    )
  }
})
