import config from '../../../config'

// These utilities take the checkbox setting and return the associated API setting.
export const GetRemoteContactSetting = (val) => {
  return val === true ? 'false,unknown' : ''
}

export const GetRequireEmailSetting = (val) => {
  return val === true ? 'true' : ''
}

export const GetExcludeCTPSSetting = (val) => {
  return val === true ? 'false' : ''
}

// These utilities take the API setting and returns the checkbox setting
export const isExcludeCTPSChecked = (val) => {
  return !val === false
}

export const isRemoteContactsChecked = (val) => {
  return val === 'false,unknown'
}

const isFuncCodeOnlyChecked = (val) => {
  if (val === true || val === 'true') return false
  return true
}

// isFlagChecked tells us if a search flag is checked based on it's API setting
export const isFlagChecked = (paramString, value) => {
  switch (paramString) {
    case 'contact.is_remote' :
      return isRemoteContactsChecked(value)
    case 'site.ctps' :
    case 'company.ctps' :
    case 'contact.ctps' :
      return isExcludeCTPSChecked(value)
    case 'contact.function_code_only' :
      return isFuncCodeOnlyChecked(value)
  }
  return value
}

/* Some flags in the Search Page need different labels than supplied
   by the API - do the conversion here */
export const getCustomFlagLabel = (flagFilter) => {
  return config.CONSTANTS.UI_SPECIFIC_LABELS[flagFilter.apiString] || flagFilter.label
}
