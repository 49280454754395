import axiosInstance from "axios";
import { TOGGLE_MAINTENANCE_PAGE } from '../modules/global'

/* This utility centralizes all information about the Maintenance page status.
   It provides a function that can be called to check the maintenance status */

const s3PublicFilesPath =
  "https://s3-eu-west-1.amazonaws.com/netfinder-public-files"; 

const s3MaintenanceFile = `${s3PublicFilesPath}/maintenance.json`;

export function getMaintenanceStatus() {

  return dispatch => {

    axiosInstance
      .get(s3MaintenanceFile)
      .then(function (response) {
        dispatch({
          type: TOGGLE_MAINTENANCE_PAGE,
          payload: response.data.FORCE_MAINTENANCE || false
        })
      })
      .catch(_err => {
        dispatch({
          type: TOGGLE_MAINTENANCE_PAGE,
          payload: false
        })
      });
  }
}
