import config from '../config'

const getAllCountries = (counts) => {
  return counts && counts.site && counts.site.location ? counts.site.location.country_code.total : [];
}



export const getFilteredCountries = (counts) => {
  

  let allCountries = getAllCountries(counts);

  let test = Object.keys(allCountries).filter(country => {
        return (counts.site.location.country_code.total[country] &&
          counts.site.location.country_code.total[country] >= config.CONSTANTS.EXPLORENOW_MIN_SITE) ||
          (counts.contact.location.country_code.total[country] &&
            counts.contact.location.country_code.total[country] >= config.CONSTANTS.EXPLORENOW_MIN_CONTACTS);
      });
      
  let listCountriesFiltered = Object.fromEntries(Object.entries(allCountries).filter( ([key]) => test.includes(key) ) );
      
  return listCountriesFiltered;
}

export const getNbCountries = (counts) => {
  return Object.keys(getFilteredCountries(counts)).length
}


