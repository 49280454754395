import React, { Component } from 'react'
import { Checkbox } from 'react-bootstrap'
import { saveLeadNeuronSearch } from '../../routes/Search/modules/Utilities'
import { connect } from 'react-redux'
import { LEADNEURON } from '../../routes/Search/modules/search'

class RadioBoxBtn extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasClicked: false,
      checked: false
    }

    this.radioRef = React.createRef();
    this.clearAll= false
  }

  componentDidMount(){
    this.handleCheck()
  }
  componentDidUpdate() {
    switch (this.props.data.groupName) {
      case 'LeadNeuron Account List':
        if(this.props.state.selections[0] === undefined || this.props.state.selections[0] === -1){
          this.clearAll = true
        }
        else{
          this.clearAll = false
        }
        break;
      case 'LeadNeuron Lead List':
        if(this.props.state.selections[1] === undefined || this.props.state.selections[1] === -1){
          this.clearAll = true
        }
        else{
          this.clearAll = false
        }
        break;
      }

  }
  handleCheck = () => {
    let index = null
    switch (this.props.data.groupName) {
      case 'LeadNeuron Account List':
        index = 0;
        if(this.props.state.selections[index] === this.props.data.itemName){
          this.setState({
            checked: true
          })
        }
        break;
      case 'LeadNeuron Lead List':
        index = 1;
        if(this.props.state.selections[index] === this.props.data.itemName){
          this.setState({
            checked: true
          })
        }
        break;
    }
  }
  handleRadioBoxClick = (e) => {
    this.radioRef.current.click()
    this.setState({
      checked: true
    })
    let index = null
    switch (this.props.data.groupName) {
      case 'LeadNeuron Account List':
        index = 0;
        break;
      case 'LeadNeuron Lead List':
        index = 1;
        break;
    }
    this.props.addSelection(index, this.props.data.itemName)
  }

  render () {
    return (
            <Checkbox key={this.props.data.itemId}>
                <div
                      onClick={ (e) =>  this.handleRadioBoxClick(e)  }
                      style={{ cursor: "pointer" }}>

                  <input
                        type='radio'
                        class="custom-control-input"
                        id={`radio${this.props.data.itemId}`}
                        name={ this.props.data.groupName }
                        ref={this.radioRef}
                        checked={ this.props.data.checked}
                        onChange={ this.props.data.onChange }
                        disabled= { (this.props.data.disabled === true) ? true : false }
                  />
                  {
                  this.props.data.disabled === true
                  ? <span className='blurry-text'>{ this.props.data.label }</span>
                  : this.props.data.label }

                </div>
            </Checkbox>
    )
  }
}

const mapStateToProps = state => {
  return {
      state: state.search.leadneuron
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addSelection: (index, value) => {
      return dispatch({ type: LEADNEURON.ADD_SELECTION, payload: {
        index,
        value
      }})
    },
    removeSelection: (index) => {
      return dispatch({ type: LEADNEURON.REMOVE_SELECTION, payload: index})
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioBoxBtn)
