import moment from 'moment'
/* This utility returns todays date in a string format. If a dayIncrement is
   specified todays date plus the number of days specified is used */

export const StandardDateString = (dayIncrement = 0) => {
  return moment().add(dayIncrement, 'days').format('MMM DD YYYY')
}

export const DownloadDateString = (dayIncrement = 0) => {
  return moment().add(dayIncrement, 'days').format('YYYY_MM_DD')
}

/* This utility returns the expiry date string to append to the
 export files */
export const ExportValidDatesString = (dayIncrement = 0) => {
  return '-' + moment().add(dayIncrement, 'days').format('MMMDDYYYY')
}

/* This utility takes a string returned from the API, parses
it and returns a valid date formatted string */
export const GetDateFromString = (APIstringToParse, dayIncrement = 0) => {
  var momentDate = moment(APIstringToParse, 'YYYY-MM-DD HH:mm:ss')
  return momentDate.add(dayIncrement, 'days').format('MMMM DD YYYY')
}

export const isAfterToday = (compareDate) => {
  return moment(compareDate, 'MMMM DD YYYY').isAfter()
}
