import React, { Component } from 'react'
import { Checkbox } from 'react-bootstrap'

export default class CheckboxBtn extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasClicked: false
    }
  }

  handleCheckBoxClick = () => {
    this.setState({
      hasClicked: !this.state.hasClicked
    })
  }

  render() {
    return (
      <div className='search-checkbox' id={this.props.data.itemId} key={this.props.data.itemId}>

        <Checkbox
          onChange={this.props.data.onChange}
          onClick={this.handleCheckBoxClick}>

          <span className='proxy-checkbox'>
            {this.props.data.indeterminate ? (
              <i className='state-icon fa exclude fa-minus-square' />
            ) : this.props.data.checked ? (
              <i className='state-icon fa include fa-check-square' />
            ) :
              <i className='state-icon fa fa-square-o' />
            }
          </span>
          {this.props.data.label}

        </Checkbox>
        <div className='clear' />
      </div>

    )
  }
}
