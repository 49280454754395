import React, { Component } from 'react';
import Error from '../../routes/Error/components/Error';

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ hasError: true })
    console.log(error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return (<Error />);
    }
    return this.props.children;
  }
}

export default ErrorBoundary
