export const enComplianceCenter = {
  introTitle: 'Rhetorik Compliance Centre',
  introText: 'Rhetorik has been leading the way in data protection and privacy compliance for more than 20 years.' +
         'We regularly review all applicable and enforceable data privacy and data protection regulations to ' +
         'check that we are compliant. Our Compliance Centre is designed to help you be compliant in your use ' +
         'of the licensed data in NetFinder.',
  section1Title: 'Learn More',
  section2Title: 'Get Started',
  section3Title: 'Do It For Me',
  section2Note: ' - Rhetorik GDPR Good Practice Guides',
  button1Text: 'White papers, FAQs and other Documentation to get you up-to-speed on your GDPR responsibilities',
  button2Text: 'Step-by-step advice on how to conduct your own compliant marketing campaigns using NetFinder data',
  button3Text: 'Explore how Rhetorik’s data and marketing services can deliver results AND peace of mind',
  downloadConsentAndOptInFAQ: 'Consent and Opt-in – the FAQs',
  downloadConsentAndOptInFAQText: 'Some national e-privacy laws distinguish between B2B and B2C marketing, ' + 
  'while the GDPR does not - read the FAQs to learn how this impacts your direct marketing efforts.',
  downloadGDPRFAQTitle: 'Rhetorik GDPR FAQ',
  downloadGDPRFAQText: 'Read this to learn about Rhetorik’s approach to data privacy and protection, ' +
                       'how our data is compliant, and how you can use it in a compliant way.',
  downloadGDPRConformityTitle: 'Rhetorik Letter of GDPR Conformity',
  downloadGDPRConformityText: 'Legal opinion on Rhetorik’s approach to data privacy and protection.',
  downloadGDPRWhitePaperTitle: 'Rhetorik GDPR White Paper',
  downloadGDPRWhitePaperText: 'Background to the legislation in the UK & Ireland and Rhetorik’s compliance with it.',
  downloadEMIGWhitePaperTitle: 'EMIG White Paper',
  downloadEMIGWhitePaperText: 'Background to the legislation across the EU, and what that means for B2B email-, ' +
                             'direct- and tele-marketers.',
  downloadB2BMarketersTitle: 'GDPR for B2B Marketers',
  downloadB2BMarketersText: 'Webinar covering the use of legitimate interest as a legal basis for processing ' +
                            'personal data in the form of business card information.',
  downloadEmailMarketingTitle: 'Email Marketing',
  downloadEmailMarketingText: 'Step-by-step guide to sending compliant B2B emails using Rhetorik data, and ' +
                              'responding to GDPR-related questions from email recipients.',
  downloadTelemarketingTitle: 'Telemarketing',
  downloadTelemarketingText: 'Step-by-step guide to conducting compliant B2B telemarketing campaigns using Rhetorik ' +
                             'data, and responding to GDPR-related questions from telephone call recipients.',
  downloadSuppressionListsTitle: 'Suppression Lists',
  downloadSuppressionListsText: 'Step-by-step guide to using the Rhetorik suppression lists to ensure you don’t continue' +
                                ' to market to contacts after they have opted out.',
  downloadRTKDMSTitle: 'Rhetorik Data Management Service – Clean, Enhance & Integrate',
  downloadRTKDMSText: 'By maintaining, enriching and extending your data, Rhetorik can help you comply with data privacy ' +
                      'and protection legislation AND boost the impact of your sales and marketing activities.',
  downloadRTKSMSTitle: 'Rhetorik Suppression List Service',
  downloadRTKSMSText: 'Managing suppression lists can be complicated, but help is at hand with the Rhetorik ' +
                      'Suppression List Service.',
  downloadBrexitTitle: 'Brexit Transition Period Information',
  downloadBrexitText: 'Specific considerations and actions taken by Rhetorik to ensure continuous compliance ' +
                      'and best practice in the matter of Data Privacy of Personal Data during the Brexit Transition Period. ',
  redirectButtonLabel: 'find out more'
}
