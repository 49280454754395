import React, { Component } from 'react'
import '../../../scss/style.scss'
import config from '../../config'

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className='full-width-footer'>
          <div className='pull-right'>Version 2.8.1</div>
          Copyright &copy; {String(new Date().getFullYear())}{' '}
          <strong>
            <a href='http://www.rhetorik.com/' target='_blank'>
              Rhetorik
            </a>
            
          </strong>{' '}
          All rights reserved.
          <div className='footer-clearbit'>Company Logos provided by Clearbit</div>
          <div className='footer-clearbit'>Country Flags provided by Freepik</div>
        </footer>
      </div>
    )
  }
}

export default Footer
