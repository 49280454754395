import axios from 'lib/axios'
import config from '../../../config'
import { TOGGLE_LOADING, TOGGLE_SIDEBAR, ShowToast, RESULTS_OVERVIEW_TAB_ID } from '../../../modules/global'
import { ExportValidDatesString } from '../../../lib/dateUtils'
import _ from 'lodash'
import { sortableCompanyTableColumns } from '../utils/tableFormat'

const SET_PROP = 'SET_PROP'
const SET_COMPANY_RESULTS = 'SET_COMPANY_RESULTS'
const SET_SITES_DETAILS = 'SET_SITES_DETAILS'
const SET_PAGE = 'SET_PAGE'
const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX'
const CLEAR_RESULTS = 'CLEAR_RESULTS'
const SET_TABLE_SORT = 'SET_RESULTS_TABLE_SORT'
const EXPANDED_COMPANY = 'EXPANDED_COMPANY'
const PAGE_POSITION = 'PAGE_POSITION'
const SET_CURRENT_TAB_ID = 'SET_CURRENT_TAB_ID'
const GET_NEXT_PAGE = 'GET_NEXT_PAGE'
const GET_PREV_PAGE = 'GET_PREV_PAGE'
export const LOADING_RESULTS = 'LOADING_RESULTS'
export const SITE_LOADING = 'SITE_LOADING'

export const LEADS_LOADING = 'LEADS_LOADING'
const SET_LEADS_RESULTS = 'SET_LEADS_RESULTS'
const SET_LEADS_NEXT_PAGE = 'SET_LEADS_NEXT_PAGE'
const SET_LEADS_PREV_PAGE = 'SET_LEADS_PREV_PAGE'
const SET_LEADS_PAGE = 'SET_LEADS_PAGE'

export function copyContactInfo (info) {
  return (dispatch) => {
    try {
      navigator.clipboard.writeText(info)
      dispatch(ShowToast('success', 'COPY', 'Info successfuly copied', 3000, false))
    } catch (e) {
      dispatch(ShowToast('error', 'COPY', 'Couldn\'t copy', 3000, false))
    }
  }
}

export function fetchLeads (page = 1, match = 0) {
  console.log('FETCH LEADS:', page)
  return (dispatch, getState) => {
    dispatch({ type: LEADS_LOADING, payload: true })

    const params = {
      page,
      limit: 25,
      match,
    }

    return axios
      .get(config.API.GET_LEADS_RESULTS, { params })
      .then(response => {
        dispatch({ type: LEADS_LOADING, payload: false })
        if (_.get(response, 'data')) {
          const results = response.data.contact.map(contact => {
            let site = '-'
            if (contact.site && contact.site.country && contact.site.city) {
              site = `${contact.site.city}, ${contact.site.country}`
            }

            return {
              id: contact.rtkc_id,
              firstName: contact.first_name,
              lastName: contact.last_name,
              jobTitle: contact.job_title,
              contactInfo: {
                email: contact.contact_information.email, // ARRAY
                phoneNumber: contact.contact_information.phone_number,
                linkedIn: contact.contact_information.linkedin_profile,
              },
              companyName: contact.company_name,
              companyUrl: contact.company_url,
              site,
            }
          })

          dispatch({ type: SET_LEADS_RESULTS, payload: results })
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: LEADS_LOADING, payload: false })
      })
  }
}

export function fetchResults (page, sortType) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_RESULTS, payload: true })
    dispatch({ type: TOGGLE_SIDEBAR, payload: false })
    dispatch({ type: SET_PROP, payload: { key: 'page', value: page } })

    let params = {
      page: page,
      limit: config.MISC.DEFAULT_PAGE_SIZE,
      sort_by: sortType || getState().results.sortType
    }
    return axios
      .get(config.API.GET_COMPANY_RESULTS, { params })
      .then((response) => {
        dispatch({ type: LOADING_RESULTS, payload: false })
        if (_.get(response, 'data')) {
          dispatch({
            type: SET_PROP,
            payload: {
              key: 'siteCount',
              value: _.get(response, 'data.result.site_count', 0)
            }
          })
          let companies = _.get(response, 'data.result.companies', [])
          dispatch({
            type: SET_COMPANY_RESULTS,
            payload: {
              page: page,
              searchResults: companies
            }
          })
        }
      })
      .catch((error) => {
        dispatch({ type: LOADING_RESULTS, payload: false })
        console.log(error)
      })
  }
}

export function fetchSitesResults (companyId) {
  return (dispatch, getState) => {
    dispatch({ type: SITE_LOADING, payload: true })
    let params = {
      company_id: companyId,
    }
    return axios
      .get(config.API.GET_SITE_RESULTS, { params })
      .then((response) => {
        dispatch({ type: SITE_LOADING, payload: false })
        if (_.get(response, 'data')) {
          let sites = _.get(response, 'data.result.sites', [])
          dispatch({
            type: SET_SITES_DETAILS,
            payload: {
              searchSiteResults: sites
            }
          })
        }
      })
      .catch((error) => {
        dispatch({ type: SITE_LOADING, payload: false })
        console.log(error)
      })
  }
}

export function clearResults () {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RESULTS
    })
  }
}

export function setTableSort (sortType) {
  return (dispatch) => {
    dispatch({
      type: SET_TABLE_SORT,
      payload: {
        sortType: sortType
      }
    })

    dispatch(fetchResults(1, sortType))
  }
}

export function SaveCurrentSearch () {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_LOADING, payload: true })
    let params = {}
    var currentState = getState()
    if (currentState && currentState.results) {
      params = {
        label: currentState.results.label_text,
        note: '',
        enforced: currentState.results.enforced_criteria
      }
    }
    dispatch({ type: SET_PROP, payload: { key: 'label_text', value: '' } })
    dispatch({ type: SET_PROP, payload: { key: 'enforced_criteria', value: false } })
    axios
      .get(config.API.SAVE_SEARCH, {
        params: params
      })
      .then(function(response) {
        dispatch(ToggleSaveSearchModal())
        dispatch({ type: TOGGLE_LOADING, payload: false })
        dispatch(ShowToast('success', 'SAVED RESULTS', 'Hi ' + localStorage.getItem('logged_user_name') + ', Your search results have been saved.', 3000, true))
      })
      .catch(function(errors) {
        dispatch({ type: TOGGLE_LOADING, payload: false })
      })
  }
}
export function ExportCurrentSearches () {
  return (dispatch, getState) => {
    dispatch(ShowToast('info', 'Export In progress:', 'We are creating your data extract file...', 3000, true))
    let filename = getState() && getState().results
      ? getState().results.filename + ExportValidDatesString(config.CONSTANTS.DOWNLOAD_VALID_DAYS)
      : '' + ExportValidDatesString(config.CONSTANTS.DOWNLOAD_VALID_DAYS)
    axios
      .get(config.API.SAVE_FILE, { params: { filename } })
      .then(function(response) { })
      .catch(function(errors) { })
    dispatch(ToggleExportModal())
  }
}
export function handleFileNameEdit (eve) {
  return dispatch => {
    dispatch({
      type: SET_PROP,
      payload: {
        key: eve.target.name,
        value: eve.target.value
      }
    })
  }
}
export function ToggleExportModal () {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROP,
      payload: {
        key: 'isExportModal',
        value: getState() && getState().results ? !getState().results.isExportModal : false
      }
    })
  }
}

export function ToggleSaveSearchModal () {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROP,
      payload: {
        key: 'isSearchModal',
        value: getState() && getState().results ? !getState().results.isSearchModal : false
      }
    })
  }
}

export function SetPage (page) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PAGE,
      payload: {
        page: page
      }
    })
  }
}

export function setLeadsPage (page) {
  return (dispatch) => {
    dispatch({
      type: SET_LEADS_PAGE,
      payload: page
    })
  }
}

export function setLeadsNextPage () {
  return (dispatch) => {
    dispatch({ type: SET_LEADS_NEXT_PAGE })
  }
}

export function setLeadsPrevPage () {
  return (dispatch) => {
    dispatch({ type: SET_LEADS_PREV_PAGE })
  }
}

export function setNextPage () {
  return (dispatch, getState) => {
    dispatch({ type: GET_NEXT_PAGE })
  }
}

export function setPrevPage () {
  return (dispatch, getState) => {
    dispatch({ type: GET_PREV_PAGE })
  }
}

export function setActiveIndex (index) {
  return (dispatch, getState) => {
    dispatch({ type: SET_ACTIVE_INDEX,
      payload: {
        activeIndex: index
      }
    })
  }
}

export function ToggleEnforcedCriteria (eve) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROP,
      payload: {
        key: 'enforced_criteria',
        value: eve.target ? eve.target.checked : false
      }
    })
  }
}

export function SaveSearchAttributes (eve) {
  return dispatch => {
    dispatch({
      type: SET_PROP,
      payload: {
        key: eve && eve.target ? eve.target.name : null,
        value: eve && eve.target ? eve.target.value : null
      }
    })
  }
}

export function saveExpandedCompany (companyName, isExpanded) {
  const expandedCompany = isExpanded ? companyName : ''
  return dispatch => {
    dispatch({
      type: EXPANDED_COMPANY,
      payload: {
        companyName: expandedCompany,
      }
    })
  }
}

export function savePagePosition (pagePosition) {
  return dispatch => {
    dispatch({
      type: PAGE_POSITION,
      payload: {
        pagePosition: pagePosition,
      }
    })
  }
}

export function setCurrentTabId (tabId) {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_TAB_ID,
      payload: { tabId }
    })
  }
}

// action handlers

const ACTION_HANDLERS = {
  [SET_PROP]: (state, action) => {
    if (action.payload.key) {
      return Object.assign({}, state, {
        [action.payload.key]: action.payload.value,
      })
    }
    return Object.assign({}, state)
  },

  [SET_PAGE]: (state, action) => {
    if (action.payload.page) {
      return Object.assign({}, state, {
        page: action.payload.page
      })
    }
    return Object.assign({}, state)
  },

  [SET_ACTIVE_INDEX]: (state, action) => {
    if (action.payload.activeIndex) {
      return Object.assign({}, state, {
        activeIndex: action.payload.activeIndex
      })
    }
    return Object.assign({}, state)
  },

  [GET_NEXT_PAGE] : (state, action) => {
    return Object.assign({}, state, {
      activeIndex: state.activeIndex + 1
    })
  },

  [GET_PREV_PAGE]: (state, action) => {
    return Object.assign({}, state, {
      activeIndex: state.activeIndex - 1
    })
  },

  [SET_COMPANY_RESULTS]: (state, action) => {
    if (_.get(action, 'payload.searchResults')) {
      return Object.assign({}, state, {
        companySearchResults: action.payload.searchResults,
        page: action.payload.page
      })
    }
    return Object.assign({}, state)
  },

  [SET_SITES_DETAILS]: (state, action) => {
    if (_.get(action, 'payload.searchSiteResults')) {
      return Object.assign({}, state, {
        siteSearchResults: action.payload.searchSiteResults,
      })
    }
    return Object.assign({}, state)
  },

  [CLEAR_RESULTS]: (state, action) => {
    return Object.assign({}, state, {
      companySearchResults: [],
      siteSearchResults: [],
      page: 1
    })
  },

  [SET_TABLE_SORT]: (state = { tableSort: sortableCompanyTableColumns['emails'] }, action) => {
    if (action.payload) {
      return Object.assign({}, state, {
        sortType: action.payload.sortType || sortableCompanyTableColumns['emails']
      })
    }
    return Object.assign({}, state)
  },

  [LOADING_RESULTS]: (state, action) => {
    return Object.assign({}, state, {
      loading: action.payload || false
    })
  },

  [SITE_LOADING]: (state, action) => {
    return Object.assign({}, state, {
      siteDetailsLoader: action.payload
    })
  },

  [LEADS_LOADING]: (state, action) => {
    console.log('leads loading in action:', action.payload)
    return Object.assign({}, state, {
      leadsLoading: action.payload
    })
  },

  [SET_LEADS_RESULTS]: (state, action) => {
    return Object.assign({}, state, {
      leads: action.payload
    })
  },

  [SET_LEADS_NEXT_PAGE]: (state, action) => {
    return Object.assign({}, state, {
      leadsPage: state.leadsPage + 1
    })
  },

  [SET_LEADS_PREV_PAGE]: (state, action) => {
    return Object.assign({}, state, {
      leadsPage: state.leadsPage - 1
    })
  },

  [SET_LEADS_PAGE]: (state, action) => {
    console.log('ACTION PAGE:', action.payload)
    return Object.assign({}, state, {
      leadsPage: action.payload,
    })
  },

  // [SET_COMPANY_RESULTS]: (state, action) => {
  //   if (_.get(action, 'payload.searchResults')) {
  //     return Object.assign({}, state, {
  //       companySearchResults: action.payload.searchResults,
  //       page: action.payload.page
  //     })
  //   }
  //   return Object.assign({}, state)
  // },

  [EXPANDED_COMPANY]: (state, action) => {
    return Object.assign({}, state, {
      pagePosition: null,
      expandedCompany: action.payload.companyName,
    })
  },

  [PAGE_POSITION]: (state, action) => {
    return Object.assign({}, state, {
      pagePosition: action.payload.pagePosition,
    })
  },
  [SET_CURRENT_TAB_ID]: (state, action) => {
    return Object.assign({}, state, {
      currentTabId: action.payload.tabId
    })
  }
}

// reducer
const initialState = {
  sites: 0,
  contacts: 0,
  enforced_criteria: false,
  note_text: '',
  breadCrumbs: [{ url: '/', name: 'Search Criteria' }, { url: '/results', name: 'Search Results' }],
  isSearchModal: false,
  isExportModal: false,
  loading: false,
  siteDetailsLoader: false,
  page: 1,
  activeIndex: 1,
  sortType: sortableCompanyTableColumns['emails'],
  currentTabId: RESULTS_OVERVIEW_TAB_ID,
  leadsLoading: false,
  leadsChunk: 1,
  leads: [],
  leadsPage: 1,
}
export default function resultsReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
