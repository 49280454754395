import { connect } from 'react-redux'
import Authentication from '../components/Authentication'
import { withRouter } from 'react-router'
import { getMaintenanceStatus } from '../lib/maintenanceUtils'
import {
  ValidateUserToken,
  SignOut,
  SideBarClick,
  EditUser,
  SaveUserParameters,
  GetUserDetails,
  ToggleSideBarHeight,
  UpdateUser,
  UserDetailsEditHandle,
  GetNotifications,
  SetToastContainer,
  getSearchOptions,
  openSplashScreen,
  toggleExploreNow,
  viewExploreNow,
  closeSplashScreen,
  viewSplashScreen,
  sendNetfinderPlusNotification,
  emailNetfinderPlus_Sent,
  viewMaintenancePage,
} from '../modules/global.js'

const mapStateToProps = state => ({
  authenticated: state.global.toJS().isAuthenticated,
  userEmail: state.global.toJS().emailid,
  userName: state.global.toJS().userName,
  isSideBarOpen: state.global.toJS().isSideBarOpen,
  currentUser: state.global.toJS().currentUser,
  sideBarMove: state.global.toJS().sideBarMove,
  userEditable: state.global.toJS().userEditable,
  EditType: state.global.toJS().EditType,
  isLoading: state.global.toJS().isLoading,
  isLoadingPlaces: state.global.toJS().isLoadingPlaces,
  editableUser: state.global.toJS().editableUser,
  rememberMe: state.global.toJS().rememberMe,
  isAdmin: state.global.toJS().isAdmin,
  userPermissions: state.global.toJS().userPermissions,
  notificationsCount: state.global.toJS().notificationsCount,
  searchOptions: state.global.toJS().searchOptions,
  viewExploreNow: state.global.toJS().viewExploreNow,
  counts: _.get(state.search, 'countsData'),
  closeSplashScreen: state.global.toJS().closeSplashScreen,
  viewSplashScreen: state.global.toJS().viewSplashScreen,
  emailNetfinderPlus_Sent: state.global.toJS().emailNetfinderPlus_Sent,
  viewMaintenancePage: state.global.toJS().viewMaintenancePage

})
const mapDispatchToProps = {
  ValidateUserToken,
  SignOut,
  SideBarClick,
  EditUser,
  SaveUserParameters,
  GetUserDetails,
  ToggleSideBarHeight,
  UpdateUser,
  UserDetailsEditHandle,
  SetToastContainer,
  GetNotifications,
  getSearchOptions,
  openSplashScreen,
  toggleExploreNow,
  closeSplashScreen,
  viewSplashScreen,
  emailNetfinderPlus_Sent,
  sendNetfinderPlusNotification,
  //sendNetfinderPlusSortingNotification,
  getMaintenanceStatus,
}
export default function (ComposedComponent) {
  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Authentication(ComposedComponent)))
}
