import React, { Component } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import UserDetails from './_userdetails'
import PropTypes from 'prop-types'
import locales from '../../locales/locales.js'
import { s3FileDetails, downloadS3File, downloadSuppressionLists } from '../../lib/S3DownloadFiles'
import _ from 'lodash'

class SideBar extends Component {
  constructor (props) {
    super(props)
    this.moveBarDown = this.moveBarDown.bind(this)
    this.state = {
      tempCountryPreference: null,
      tempCurrencyPreference: null
    }
  }

  componentDidMount () {
    this.props.GetUserDetails()
    window.addEventListener('scroll', this.moveBarDown)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.moveBarDown)
  }

  componentDidUpdate (prevProps) {
    prevProps.Preferences.country !== this.props.Preferences.country &&
    this.setState({ tempCountryPreference : null })
    prevProps.Preferences.currency !== this.props.Preferences.currency &&
    this.setState({ tempCurrencyPreference : null })
  }

  moveBarDown () {
    window.scrollY > 200 ? this.props.ToggleSideBarHeight(true) : this.props.ToggleSideBarHeight(false)
  }

  render () {
    var countryPreferenceOptions =
      this.props.countryOptions.map(country => {
        let countryCode = Object.keys(country)[0]
        return { value: countryCode, label: country[countryCode] }
      })
    var currencyPreferenceOptions = [
      { value: 'USD', label: 'USD' },
      { value: 'EUR', label: 'EUR' },
      { value: 'GBP', label: 'GBP' },
    ]
    return (
      <aside className={this.props.isOpen ? 'control-sidebar control-sidebar-dark control-sidebar-open' : 'control-sidebar control-sidebar-dark'} id='sidebar'>

        {' '}
        <div className='row'>
          <div className='col-md-6 col-sm-6 col-xs-6'>
            <div className='close-panel-side-bar'>
              <a onClick={this.props.SideBarClick}>
                close <i className='fa fa-times' />
              </a>
            </div>
          </div>
          <div className='col-md-6 col-sm-6 col-xs-6'>
            <Button bsStyle='info' className='btn-sm btn-info pull-right sign-out' onClick={this.props.SignOut}>
              <i className='fa fa-sign-out' /> Sign Out
            </Button>
          </div>
        </div>
        <Tabs id='user-details-tab'>
          <Tab eventKey='aboutyou' title='About You'>
            
            <UserDetails
              EditUser={this.props.EditUser}
              CurrentUser={this.props.CurrentUser}
              userEditable={this.props.userEditable}
              EditType={this.props.EditType}
              UpdateUser={this.props.UpdateUser}
              UserDetailsEditHandle={this.props.UserDetailsEditHandle}
              EditableUser={this.props.EditableUser}
            />
          </Tab>
            <Tab eventKey='support' title='Support'>
              <div className='support-tab-content'>
                <ul className='help-support-list'>
                  <li>
                    <h3 className='help-support-label'>Help & Support</h3>
                  </li>
                  <li>
                    <a href='https://www.rhetorik.com' target='_blank'>
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a href='https://www.rhetorik.com' target='_blank'>
                      Help
                    </a>
                  </li>

                  <li>
                    <span className='right-drawer-downloads'>Contact Us</span>
                    <a href='mailto:customersuccess@rhetorik.com' target='_blank' className='right-drawer-contact-link'>
                     <div class="fa fa-envelope" aria-hidden="true" /> customersuccess@rhetorik.com
                    </a>
                    <a className='right-drawer-contact-link'>
                      <div className='fa fa-phone' aria-hidden="true" /> +44 (0) 118 989 8580
                    </a>
                  </li>
                  <li>
                    <span className='right-drawer-downloads'>Downloads</span>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['OnlineUserguide'])}><div className='fa fa-star new-item' />Netfinder Online User Guide
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['TechTaxonomy'])}>Technologies Map
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['PostCodesMap'])}>Post Codes Map
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['Industry_Codes_UK07'])}>Industry Codes UK07
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['Industry_Codes_UK92'])}>Industry Codes UK92
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['Industry_Codes_NAICS'])}>Industry Codes NAICS
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['Industry_Codes_NACE2'])}>Industry Codes NACE2
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['Industry_Codes_USSIC87'])}>Industry Codes USSIC87
                    </a>
                  </li>
                  <li>
                    <span className='right-drawer-downloads'>Compliance</span>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['ConsentAndOptIn'])}>{locales.t('compliance:downloadConsentAndOptInFAQ')}
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['GDPREmailGuide'])}>GDPR good practice guide for email marketing
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['GDPRTelemarketingGuide'])}>GDPR good practice guide for telemarketing
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadS3File(s3FileDetails['ProUser5'])}>Suppression Lists instructions
                    </a>
                    <a className='right-drawer-downloads-link'
                      onClick={() => downloadSuppressionLists()}>Suppression Lists
                    </a>
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
      </aside>
    )
  }
}

SideBar.propTypes = {
  GetUserDetails: PropTypes.func,
  ToggleSideBarHeight: PropTypes.func,
  SaveUserParameters: PropTypes.func,
  EditUser: PropTypes.func,
  countryOptions: PropTypes.array,
  isOpen: PropTypes.bool,
  SideBarClick: PropTypes.func,
  CurrentUser: PropTypes.object,
  EditableUser: PropTypes.object,
  userID: PropTypes.number,
  Preferences: PropTypes.object,
  setGlobalSearchParams: PropTypes.func

}

export default SideBar
