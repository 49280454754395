import React, { Component } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FlagsSplashScreenContainer from '../../containers/FlagsSplashScreenContainer'
import { PropTypes } from 'prop-types'
import SideBar from './_sideBar'
import ReduxToastr from 'react-redux-toastr'
import _ from 'lodash'

import 'bootstrap/dist/css/bootstrap.css'
import 'react-select/dist/react-select.css'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import 'font-awesome/scss/font-awesome.scss'
import './style.scss'
import '../../styles/core.scss'
import '../../styles/animation.scss'
import '../../styles/confirm.scss'
import '../../styles/variables.scss'
import '../../styles/index.scss'
// import '../../styles/animate.css'
// import '../../styles/toast.css'
// import '../../styles/toastr.css'
import { ToastMessage } from 'react-toastr'
import { Loader } from 'react-loaders'
import 'loaders.css/src/animations/ball-clip-rotate-multiple.scss'
import 'loaders.css/src/animations/square-spin.scss'
import '../../../scss/skin.scss'
import '../../../scss/adminLTE.scss'
import '../../../scss/style.scss'


export class CoreLayout extends Component {
  componentDidMount () {
    this.props.SetToastContainer(this.refs.ReduxToastr)
    if (!this.props.searchOptions) { this.props.getSearchOptions() }
    window.addEventListener('click', e => {
      if (
        !(document.getElementById('sidebar') && document.getElementById('sidebar').contains(e.target)) &&
        this.props.isSideBarOpen &&
        document.getElementById('usernameAnchor') &&
        !document.getElementById('usernameAnchor').contains(e.target)
      ) {
        this.props.SideBarClick()
      }
    })
  }

  render () {
    return (
      <div className='app wrapper search-wrapper'>
        {this.props.isLoading ? (
          <div className='loading'>
            <Loader type='square-spin' active />
          </div>
        ) : null}

        <ReduxToastr newestOnTop={false} preventDuplicates transitionIn='fadeIn' transitionOut='fadeOut' />
        <Header
          router={this.props.router}
          userName={this.props.userName}
          isAdmin={this.props.isAdmin}
          userEmail={this.props.userEmail}
          SignOut={this.props.SignOut}
          SideBarClick={this.props.SideBarClick}
          GetNotifications={this.props.GetNotifications}
          notificationsCount={this.props.notificationsCount}
          userPermissions={this.props.userPermissions}
          openSplashScreen={this.props.openSplashScreen}
          toggleExploreNow={this.props.toggleExploreNow}
          viewExploreNow={this.props.viewExploreNow}
          counts={this.props.counts}
        />
        {this.props.viewExploreNow && <div className='hidden-space-netfinderPlus'>&nbsp; </div>}
        <div className='hidden-space'>&nbsp; </div>
        <div className='top-hidden-nav-space'>&nbsp; </div>
        <div className='app-body'>{this.props.children}</div>
        <SideBar
          isOpen={this.props.isSideBarOpen}
          SideBarClick={this.props.SideBarClick}
          SignOut={this.props.SignOut}
          CurrentUser={this.props.currentUser}
          GetUserDetails={this.props.GetUserDetails}
          ToggleSideBarHeight={this.props.ToggleSideBarHeight}
          sideBarMove={this.props.sideBarMove}
          EditUser={this.props.EditUser}
          userEditable={this.props.userEditable}
          EditType={this.props.EditType}
          UpdateUser={this.props.UpdateUser}
          UserDetailsEditHandle={this.props.UserDetailsEditHandle}
          EditableUser={this.props.editableUser}
          Preferences={_.get(this.props, 'userPermissions.preference', {})}
          userID={_.get(this.props, 'userPermissions.user_id', null)}
          SaveUserParameters={this.props.SaveUserParameters}
          countryOptions={_.get(this.props, 'searchOptions.site.search_options.location_country_code.options', [])}
        />

        {this.props.counts && 
            <FlagsSplashScreenContainer
              closeSplashScreen={this.props.closeSplashScreen}
              viewsplashscreen={this.props.viewSplashScreen}
              counts={this.props.counts}
              currentUser={this.props.currentUser}
              userID={this.props.userID}
              sendNetfinderPlusNotification={this.props.sendNetfinderPlusNotification}
              //sendNetfinderPlusSortingNotification={this.props.sendNetfinderPlusSortingNotification}
              emailNetfinderPlus_Sent={this.props.emailNetfinderPlus_Sent} />}

        <Footer />
      </div>
    )
  }
}

CoreLayout.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string
}
export default CoreLayout
