export const enLogin = {
  top_title: 'Netfinder is Evolving',
  top_text: 'Rhetorik is working hard to improve your user ' +
  'experience and always seeking new ways to help you succeed.' +
  ` That's why we regularly innovate and update our products.`,
  marketing_title: `What's NEW in Netfinder`,
  marketing_text1: 'Have you noticed our most recent updates?',
  section1_title: 'Corporate Profile Views',
  section1_text: 'Collates a company’s offices, contacts ' +
  'and tech installs into one comprehensive view. All your account-based' +
  ' needs in one place!',
  section2_title: '',
  section2_text: '',
  marketing_contact: 'Contact a customer service or sales representative for more details ...',
}
