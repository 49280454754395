import { injectReducer } from '../../store/reducers'
import { PageNotFound, UnAuthorize } from '../PageNotFound'
import config from '../../config'

export default store => ({
  path: '',
  getComponent (nextState, cb) {
    if (nextState && nextState.params) {
      let isSearch = nextState.params.splat ? (parseInt(nextState.params.splat) > 0) : true

      if (isSearch) {
        require.ensure(
          [],
          require => {
            const Search = require('./containers/SearchContainer').default

            let reducer = require('./modules/search').default
            injectReducer(store, { key: 'search', reducer })

            reducer = require('./modules/APIData').default
            injectReducer(store, { key: 'APIData', reducer })

            reducer = require('../Results/modules/results').default
            injectReducer(store, { key: 'results', reducer })

            /*  Return getComponent   */
            cb(null, Search)

            /* Webpack named bundle   */
          },
          'Search'
        )
      } else if (nextState && nextState.params && nextState.params.splat == config.CONSTANTS.UNAUTHORIZED_USER_URL) {
        require.ensure(
          [],
          require => {
            /*  Return getComponent   */
            cb(null, UnAuthorize)

            /* Webpack named bundle   */
          },
          'unauthorize'
        )
      } else {
        require.ensure(
          [],
          require => {
            /*  Return getComponent   */
            cb(null, PageNotFound)

            /* Webpack named bundle   */
          },
          'pagenotfound'
        )
      }
    }
  }
})
