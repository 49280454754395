export const enDownloads = {
  clickToDownload: '(Click to download)',
  expiredFileMessage: 'Your download file has expired. ' +
    'You can reload your search to get updated results and then re-export the file',
  expiredFileTitle: 'Expired File',
  no_downloads_to_display: 'No downloads to display',
  reloadSearch: 'Reload Search',
  pageTitle: 'Downloads',
  reloadSearchButtonText: 'Reload Search'
}
