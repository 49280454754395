import { getCountryNameSVGCoded } from "../lib/countriesUtils";

export const getFlagLogo = country => {
  let path;

  try {
    path = require("../../public/assets/images/flags/" + getCountryNameSVGCoded(country) + ".svg");
  }
  catch (e) {
    path = require("../../public/assets/images/no_logo.gif");
    console.log(e);
  }
  return path;
};
