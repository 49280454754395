import axios from 'lib/axios'
import config from '../../../config'
import { createSearchOptionsObject,
  insertCountsIntoSearchOptions,
  getLabelsForSearchParams,
  getLabelForSearchParam } from '../utils/apiDataUtils'
import {
  SetSearchParam,
  updateSearchData,
  updateSearchParams,
  setDefaultParamsOnSave } from './search'
import { GetRemoteContactSetting,
         GetRequireEmailSetting,
         GetExcludeCTPSSetting } from '../utils/flagUtils'
import { getMultipleLabels } from '../../../lib/autocompleteUtils.js'
import { chainAxiosRequests } from 'lib/axiosUtils'
import _ from 'lodash'

export const API_DATA_INIT = 'API_DATA_INIT'
export const GET_SEARCH_PARAMS = 'GET_SEARCH_PARAMS'
export const GET_COUNTS = 'GET_COUNTS'
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
export const SET_ASYNC_LABELS = 'SET_ASYNC_LABELS'
export const POPUP_OPEN = 'POPUP_OPEN'
export const BLUE_BOX_TAB = 'BLUE_BOX_TAB'
export const REDIRECT_MODAL = 'REDIRECT_MODAL'

export function searchAPIDataInit (searchOptions, searchParams, counts) {
  return (dispatch) => {
    dispatch({
      type: API_DATA_INIT,
      payload: {
        APIsearchOptions: searchOptions,
        APIsearchParams: searchParams,
        APIcounts: counts,
      }
    })
  }
}

export function setParam (paramName, value) {
  return (dispatch, getstate) => {
    axios
    .get(config.API.SET_SEARCH_PARAM, {
      params: {
        param_name: paramName,
        param_value: value
      }
    })
    .then(function (response) {
      dispatch(updateSearchData())
    })
  }
}

export function SetMultipleSearchParams (paramPairs, options) {
  return dispatch => {
    let APIRequest = chainAxiosRequests(_.map(paramPairs, (param) => {
      setDefaultParamsOnSave(param.name, param.value)
      return {
        url: config.API.SET_SEARCH_PARAM,
        params: {
          param_name: param.name,
          param_value: param.value
        }
      }
    }))

    APIRequest.then(function (results) {
      dispatch(updateSearchParams())
    })
  }
}

export function PerformSearch() {
  return dispatch => {
    dispatch(updateSearchData())
  }
}

export function getCompanyDetails (companyId) {
  axios
  .get(config.API.GET_COMPANY_DETAILS, {
    params: {
      company_id: companyId,
    }
  })
}

export function searchAPIGetCounts (countsData = null, options) {
  return (dispatch, getstate) => {
    let cleanSearchOptions = getstate().APIData.cleanSearchOptions
    // If we receive the countsData then we don't need to go get
    // it from the API - send the event with existing data
    if (cleanSearchOptions && countsData) {
      dispatch({
        type: GET_COUNTS,
        payload: {
          APIcounts: countsData,
          cleanSearchOptions: cleanSearchOptions
        }
      })
    } else if (cleanSearchOptions) {
      // If we already have the search options in the store,
      // only get the counts data from the API
      axios.get(config.API.GET_COUNTS)
        .then(function (response) {
          dispatch({
            type: GET_COUNTS,
            payload: {
              APIcounts: response.data.result,
              cleanSearchOptions: cleanSearchOptions
            }
          })
          axios.get(config.API.GET_RESULTS)
        })
        .catch(function (error) {
          // console.log(error)
        })
    } else {
      // If we can't find the search options then we need to re-initialize the data
      // dispatch(searchAPIDataInit())
    }
  }
}

export function getAsyncLabels (searchParams) {
  const asyncParams = config.CONSTANTS.PARAMS_WITH_ASYNC_LABELS
  return (dispatch, getstate) => {
    const paramsWithAsyncLabels = _.pickBy(searchParams, (key, value) => {
      return (value !== 'site.dnb_sales' && (asyncParams.includes(value) || value.includes('site.dnb')))
    })
    let splitParams = []
    _.map(paramsWithAsyncLabels, (values, paramName) => {
      _.map(values.split(','), (value) => {
        splitParams.push({
          param_name: paramName,
          query: value })
      })
    })

    getMultipleLabels(splitParams).then((response) => {
      const nonEmptyResponses = _.filter(response, (item) => {
        return !_.isEmpty(item)
      })
      if (_.isEmpty(nonEmptyResponses)) return null
      dispatch({
        type: SET_ASYNC_LABELS,
        payload: { asyncLabels: response }
      })
    })
  }
}

export function addAsyncLabelsToLookup (labels) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ASYNC_LABELS,
      payload: {
        asyncLabels: labels,
        specialCase: true
      }
    })
  }
}

export function openRedirectModal (companyName, url, last_visited_url) {
  return (dispatch, getState) => {
    dispatch({
      type: REDIRECT_MODAL,
      payload: {
        companyName,
        url,
        last_visited_url
      }
    })
  }
}

export function closeRedirectModal (companyName, url) {
  return (dispatch, getState) => {
    dispatch({
      type: REDIRECT_MODAL,
      payload: null
    })
  }
}

export function setPopupOpenState (popupOpenState) {
  return (dispatch, getstate) => {
    dispatch({
      type: POPUP_OPEN,
      payload: { popupOpenState: popupOpenState }
    })
  }
}

export function searchAPIGetParams (searchParamsData) {
  return (dispatch, getstate) => {
    if (searchParamsData) dispatch(getAsyncLabels(searchParamsData))
    let cleanSearchOptions = getstate().APIData.cleanSearchOptions

    if (cleanSearchOptions && searchParamsData) {
      dispatch({
        type: GET_SEARCH_PARAMS,
        payload: {
          APIsearchParams: searchParamsData,
          cleanSearchOptions: cleanSearchOptions
        }
      })
    }
  }
}

export function removeParam (paramName) {
  const value = paramName === 'contact.function_code_only'
  ? 'true'
  : ''
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_PARAMS,
      payload: {
        paramString: paramName,
        paramValue: value
      }
    })
    dispatch(SetSearchParam(paramName, value))
  }
}

export function searchAPIsetParams (paramString, value) {
  switch (paramString) {
    case 'contact.is_remote' :
      value = GetRemoteContactSetting(value)
      break
    case 'contact.has_email' :
      value = GetRequireEmailSetting(value)
      break
    case 'site.ctps' :
      value = GetExcludeCTPSSetting(value)
      break
    case 'company.ctps' :
      value = GetExcludeCTPSSetting(value)
      break
    case 'contact.ctps' :
    value = GetExcludeCTPSSetting(value)
    break
    case 'contact.function_code_only' :
      value = !['', 'false'].includes(value) ? 'true' : 'false'
      break
      case 'install.headless' :
        value = (value !== null) ? value : '';
        break
  }

  return (dispatch, getstate) => {
    dispatch({
      type: SET_SEARCH_PARAMS,
      payload: {
        paramString: paramString,
        paramValue: value
      }
    })
    dispatch(SetSearchParam(paramString, value))
  }
}

function mergeLabels (oldSet, newSet) {
  var deduped = {}

  _.forEach(oldSet, (item) => {
    deduped[item.param_value] = item
  })

  _.forEach(newSet, (item) => {
    deduped[item.param_value] = item
  })

  return _.map(deduped, item => item)
}

export function setBlueBoxTab (tabId) {
  return (dispatch) => {
    dispatch({
      type: BLUE_BOX_TAB,
      payload: tabId
    })
  }
}

// APIData Reducers
export default function APIDataReducer (state = { popupOpen: false, blueBoxTab: 1 }, action) {
  // If the APIData action creators have returned the APISearchOptions object in
  // the payload it means that for some reason the cleanedSearchOptions didn't
  // exitst or there has been a request to (re)intiialize all the cleaned objects.
  // In this case we clean both the search options and the parameters and add both
  // objects to the state
  switch (action.type) {
    case SET_ASYNC_LABELS:
      return { ...state,
        asyncLabels: mergeLabels(state.asyncLabels, action.payload.asyncLabels)
      }
    case POPUP_OPEN:
      return Object.assign({}, state, { popupOpen: action.payload.popupOpenState })
    case BLUE_BOX_TAB:
      return Object.assign({}, state,
        { blueBoxTab: action.payload || 1 })
    case REDIRECT_MODAL:
      return Object.assign({}, state,
        { redirectModal: action.payload })
  }
  if ([API_DATA_INIT, GET_SEARCH_PARAMS, GET_COUNTS, SET_SEARCH_PARAMS].includes(action.type)) {
    let payload = action.payload
    let APISearchOptions = payload.APIsearchOptions
    let APIsearchParams = payload.APIsearchParams
    let APIcounts = payload.APIcounts

    // If both APISearchOptions and APIsearchParams objects exist in any of the payloads it means that we
    // initialized the data - we need to add both the new cleaned Search Options and Parameters to state.
    if (APISearchOptions && APIsearchParams) {
      let cleanSearchOptions = createSearchOptionsObject(APISearchOptions, APIcounts).searchOptionsObject
      let cleanSearchParams = getLabelsForSearchParams(cleanSearchOptions, APIsearchParams).paramsWithLabels
      return { ...state, cleanSearchOptions, cleanSearchParams }
    }
    switch (action.type) {
      case GET_SEARCH_PARAMS:
        let cleanSearchOptions = payload.cleanSearchOptions
        if (cleanSearchOptions && APIsearchParams) {
          let cleanSearchParams = getLabelsForSearchParams(cleanSearchOptions, APIsearchParams).paramsWithLabels
          return { ...state, cleanSearchParams }
        } else {
          // error handler
          console.log('Raise an error')
          return state
        }
      case SET_SEARCH_PARAMS:
        let paramValue = action.payload.paramValue
        let paramString = action.payload.paramString
        let paramLabel = getLabelForSearchParam(state.cleanSearchOptions, paramString, paramValue)
        return { ...state,
          cleanSearchParams : { ...state.cleanSearchParams,
            [ paramString ] : {
              'apiString' : paramString,
              'value': paramValue,
              'label': paramLabel }
          }
        }
      case GET_COUNTS:
        if (action.payload.cleanSearchOptions && APIcounts) {
          return Object.assign({}, state,
            { cleanSearchOptions:
                insertCountsIntoSearchOptions(Object.assign({},
                  action.payload.cleanSearchOptions), APIcounts) })
        } else {
          // error handler
          console.log('Raise an error')
          return state
        }

      default:
        return state
    }
  } else {
    return state
  }
}
