import { fromJS } from 'immutable'
import config from '../config'
import axios from 'lib/axios'
import { chainAxiosRequests } from 'lib/axiosUtils'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'

export const USER_LOG_IN = 'USER_LOG_IN'
export const USERNAME_CHANGED = 'USERNAME_CHANGED'
export const USER_LOGGING_OUT = 'USER_LOGGING_OUT'
export const USER_ROLE = 'USER_ROLE'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const USER_NAME = 'USER_NAME'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_USER = 'SET_USER'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'
export const TOGGLE_SIDEBAR_POS = 'TOGGLE_SIDEBAR_POS'
export const TOGGLE_EDIT_USER = 'TOGGLE_EDIT_USER'
export const SET_EDIT_TYPE = 'SET_EDIT_TYPE'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const TOGGLE_LOADING_COUNTS_SUMMARY = 'TOGGLE_LOADING_COUNTS_SUMMARY'
export const TOGGLE_LOADING_COUNTS_DETAILS = 'TOGGLE_LOADING_COUNTS_DETAILS'
export const TOGGLE_LOADING_CD_SUMMARY = 'TOGGLE_LOADING_CD_SUMMARY'
export const TOGGLE_LOADING_CD_COUNTS = 'TOGGLE_LOADING_CD_COUNTS'
export const TOGGLE_LOADING_CD_SITES = 'TOGGLE_LOADING_CD_SITES'
export const TOGGLE_LOADING_CD_CONTACTS = 'TOGGLE_LOADING_CD_CONTACTS'
export const TOGGLE_LOADING_CD_INSTALLS = 'TOGGLE_LOADING_CD_INSTALLS'
export const TOGGLE_LOADING_PLACES = 'TOGGLE_LOADING_PLACES'
export const SET_IMMUTABLE_PROPS = 'SET_IMMUTABLE_PROPS'
export const SET_INSTANCE = 'SET_INSTANCE'
export const REMEMBER_ME = 'REMEMBER_ME'
export const IS_ADMIN = 'IS_ADMIN'
export const UPDATE_SEARCH_PAGE = 'UPDATE_SEARCH_PAGE'
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_SEARCH_PARMS = 'SET_SEARCH_PARMS'
export const TOGGLE_SPLASHSCREEN = 'TOGGLE_SPLASHSCREEN'
export const TOGGLE_EXPLORENOW = 'TOGGLE_EXPLORENOW'
export const SET_EMAIL_NETFINDERPLUS_SENT = 'SET_EMAIL_NETFINDERPLUS_SENT'
export const TOGGLE_MAINTENANCE_PAGE = 'TOGGLE_MAINTENANCE_PAGE'
export const RESULTS_COMPANIES_TAB_ID = 'companies'
export const RESULTS_OVERVIEW_TAB_ID = 'overview'
export const RESULTS_LEADS_TAB_ID = 'leads'

export function closeSplashScreen (userID, dontShowAgain) {
  return dispatch => {
    dispatch({ type: TOGGLE_SPLASHSCREEN, payload: false })
    if (dontShowAgain) {
      axios
      .get(config.API.SAVE_USER_SETTING, {
        params: {
          user_id: userID,
          setting_name: 'preference.hide_splash_screen',
          setting_value: true
        }
      })
      .catch(console.error)
    }
  }
}

export function openSplashScreen () {
  return dispatch => {
    dispatch({
      type: TOGGLE_SPLASHSCREEN, payload: true })
  }
}

export function toggleExploreNow (value) {
  return dispatch => {
    dispatch({
      type: TOGGLE_EXPLORENOW,
      payload: value
    })
  }
}

const adminId = 15302
// const fpersohn = 15576

export function sendNetfinderPlusNotification (userInfo) {
  return dispatch => {
    dispatch({ type: TOGGLE_LOADING, payload: true })
    axios
      .get(config.API.PUSH_NOTIFICATION, {
        params: {
          // user_id: fpersohn,
          user_id: adminId,
          subject: 'NF+ Demo Request by ' + userInfo.name + ' from ' + userInfo.company,
          body: userInfo.name + ' expressed interest in the NetFinder+ service.' +
            ' email: ' + userInfo.email + ' company: ' + userInfo.company,
          type: 'Custom',
          email: true
        }
      })
      .then(() => {
        dispatch({ type: SET_EMAIL_NETFINDERPLUS_SENT, payload: true })
        dispatch({ type: TOGGLE_LOADING, payload: false })
      })
      .catch(err => {
        console.error(err)
        dispatch({ type: TOGGLE_LOADING, payload: false })
      })
  }
}

export function ValidateUserToken () {
  return dispatch => {
    const token = localStorage.getItem('access_token') || sessionStorage['access_token']
    const email = localStorage.getItem('logged_user_email')
    const role = localStorage.getItem('logged_user_role')
    const username = localStorage.getItem('logged_user_name')
    if (token && username) {
      dispatch({
        type: USER_LOG_IN,
        payload: true
      })
      dispatch({
        type: USERNAME_CHANGED,
        payload: email
      })
      dispatch({
        type: USER_ROLE,
        payload: role
      })
      dispatch({
        type: USER_NAME,
        payload: username
      })
      return true
    }
    return false
  }
}

export function SignOut () {
  return () => {
    axios
      .get(config.API.LOGOUT)
      .catch(console.error)
      .then(() => {
        _logout()
      })
  }
}
export function SideBarClick () {
  return (dispatch, getState) => {
    let isSideBarOpen = false
    if (getState() && getState().global && getState().global.toJS()) {
      isSideBarOpen = !getState().global.toJS().isSideBarOpen
    }
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: isSideBarOpen
    })
  }
}
export function ToggleSideBarHeight (value) {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_POS,
      payload: value
    })
  }
}

export function getSearchOptions () {
  /* Try to get the search options that are stored in session storage */
  const storedSearchOptions = JSON.parse(sessionStorage.getItem('SearchOptions'))
  return dispatch => {
    /* If we were able to get the search options from storage
       put it in the global state */
    if (storedSearchOptions) {
      dispatch({
        type: SET_SEARCH_OPTIONS,
        payload: storedSearchOptions
      })
    } else {
    /* If not in session storage get the search options from the API,
       store it in the session storage and global state */
      axios.get(config.API.SEARCH_OPTIONS)
      .then(function(response) {
        const searchOptionsObject = JSON.stringify(response.data.result)
        sessionStorage.setItem('SearchOptions', searchOptionsObject)
        dispatch({
          type: SET_SEARCH_OPTIONS,
          payload: response.data.result
        })
      })
    }
  }
}

export function clearSearchOptionsCache () {
  sessionStorage.removeItem('SearchOptions')
}

export function GetUserDetails (refresh = false) {
  const storedUserDetails = JSON.parse(sessionStorage.getItem('UserDetails'))
  return (dispatch) => {
    if (storedUserDetails && !refresh) {
      dispatch({ type: SET_USER, payload: storedUserDetails.profile })
      dispatch({ type: SET_USER_PERMISSIONS, payload: storedUserDetails })
      dispatch({ type: IS_ADMIN, payload: parseInt(storedUserDetails.is_admin) })
    } else {
      axios
        .get(config.API.GET_USER_SETTINGS)
        .then(function(response) {
          if (response && response.data) {
            const storedUserDetails = JSON.stringify(response.data.result)
            sessionStorage.setItem('UserDetails', storedUserDetails)
            dispatch({ type: SET_USER, payload: response.data.result.profile })
            dispatch({ type: SET_USER_PERMISSIONS, payload: response.data.result })
            dispatch({ type: IS_ADMIN, payload: parseInt(response.data.result.is_admin) })
          }
        })
    }
  }
}

export function GetNotifications () {
  return dispatch => {
    axios
      .get(config.API.GET_NOTIFICATION_LIST, {
        params: {
          user_id: '',
          type: '',
          include_viewed: true,
          include_dismissed: false
        }
      })
      .then(function(response) {
        dispatch({
          type: SET_NOTIFICATION_COUNT,
          payload: response.data.result && response.data.result.length ? response.data.result.length : null
        })
        dispatch({
          type: SET_NOTIFICATIONS,
          payload: response.data.result
        })
      })
  }
}

export function UpdateUser () {
  return (dispatch, getState) => {
    if (getState().global && getState().global.toJS() && getState().global.toJS().EditType) {
      if (getState().global.toJS().EditType === 'userDetails' && getState().global.toJS().editableUser) {
        dispatch(SaveUserParameters('profile.title', getState().global.toJS().editableUser['title'], true))
        dispatch({
          type: TOGGLE_EDIT_USER,
          payload: false
        })
      }
      if (getState().global.toJS().EditType === 'userAddressDetails' && getState().global.toJS().editableUser) {
        dispatch(
          UpdateEditedUser(
            Object.keys(getState().global.toJS().editableUser).filter(
              f => f !== 'name' && f !== 'title' && f !== 'company'
            )
          )
        )
      }
      if (getState().global.toJS().EditType === 'userPasswordDetails' && getState().global.toJS().editableUser) {
        axios
          .post(
            config.API.API_BASE,
            `f=setPassword&passkey=&new_password=` +
              `${getState().global.toJS().editableUser.new_password}&old_password=` +
              `${getState().global.toJS().editableUser.old_password}`
          )
          .then(function(response) {
            dispatch(ToggleEditUser(false))
            if (response && response.data) {
              dispatch(
                ShowToast(
                  config.CUSTOM_STATUS.SUCCESS === response.data.status_code ? 'success' : 'error',
                  '',
                  config.CUSTOM_STATUS.SUCCESS === response.data.status_code
                    ? 'Hi ' + localStorage.getItem('logged_user_name') + ', your password has been updated.'
                    : response.data.status_code !== config.CUSTOM_STATUS.SUCCESS
                      ? response.data.status_msg
                      : response.data.result,
                  3000,
                  true
                )
              )
            }
          })
          .catch(() => {
            dispatch(ToggleEditUser(false))
          })
      }
    }
  }
}

export function UpdateEditedUser (updatedetails) {
  return (dispatch, getState) => {
    if (updatedetails && updatedetails.length) {
      let currentdet = updatedetails[0]
      axios
        .get(config.API.SAVE_USER_SETTING, {
          params: {
            user_id: '',
            setting_name: `profile.${currentdet}`,
            setting_value: getState().global.toJS().editableUser[currentdet]
          }
        })
        .then(() => {
          dispatch(UpdateEditedUser(updatedetails.filter(f => f !== updatedetails[0])))
        })
    }
    if (updatedetails.length === 0) {
      dispatch(ToggleEditUser(false))
      dispatch(ShowToast('success', '', 'User details are updated successfully', 3000, true))
    }
  }
}
export function UserDetailsEditHandle (event) {
  return (dispatch, getState) => {
    let settingName = event.target.name
    let settingValue = event.target.value
    let editableUser = getState().global.toJS().editableUser
    editableUser[settingName] = settingValue
    dispatch({
      type: SET_IMMUTABLE_PROPS,
      payload: {
        key: 'editableUser',
        value: editableUser
      }
    })
  }
}
export function SaveUserParameters (settingName, settingValue, lastone, userID = null) {
  return (dispatch) => {
    axios
      .get(config.API.SAVE_USER_SETTING, {
        params: {
          user_id: userID || '',
          setting_name: settingName,
          setting_value: settingValue
        }
      })
      .then(() => {
        settingName === 'preference.currency' && dispatch(UpdateSearchPage(true))
        if (lastone) {
          setTimeout(() => {
            dispatch(ToggleEditUser(false))
          }, 500)
        }
      })
      .catch(() => {
        dispatch(ToggleEditUser(false))
      })
  }
}

export function EditUser (EditType) {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_EDIT_USER,
      payload: !getState().global.toJS().userEditable
    })
    dispatch({
      type: SET_IMMUTABLE_PROPS,
      payload: {
        key: 'editableUser',
        value: Object.assign({}, getState().global.toJS().currentUser)
      }
    })
    dispatch({
      type: SET_EDIT_TYPE,
      payload: EditType
    })
  }
}

export function LoadSearch (paramPairs, router, updateSearch = null) {
  return () => {
    return axios
      .get(config.API.CLEAR_SEARCH)
      .then(() => {
        chainAxiosRequests(_.map(paramPairs, (param) => {
          return {
            url: config.API.SET_SEARCH_PARAM,
            params: {
              param_name: param.name,
              param_value: param.value
            }
          }
        }), router, '/').then(updateSearch && setTimeout(updateSearch, 1500))
      })
      .catch(console.error)
  }
}

export function setGlobalSearchParams (params) {
  return dispatch => {
    if (!params) {
      axios
        .get(config.API.GET_SEARCH_PARAMS)
        .then((response) => {
          dispatch({
            type: SET_SEARCH_PARMS,
            payload: response.data.result
          })
        })
    } else {
      dispatch({
        type: SET_SEARCH_PARMS,
        payload: params
      })
    }
  }
}

export function SetPageTitle (title) {
  return dispatch => {
    document.title = title
    dispatch({
      type: SET_IMMUTABLE_PROPS,
      payload: {
        key: 'title',
        value: title
      }
    })
  }
}

export function ToggleEditUser (val) {
  return dispatch => {
    dispatch({ type: TOGGLE_EDIT_USER, payload: val })
    if (!val) {
      dispatch(GetUserDetails(true))
    }
  }
}

export function SetToastContainer (reference) {
  return dispatch => {
    dispatch({
      type: SET_INSTANCE,
      payload: reference
    })
  }
}

/* Types of messages: succes, info, warning, light, error, remove ans removeByType */
export function ShowToast (messagetype, title, message, time, close, fooBar) {
  return () => {
    if (fooBar != null) {
      toastr[messagetype](title, message, {
        timeOut: time,
        showCloseButton: close,
        onCloseButtonClick: fooBar()
      })
    } else {
      toastr[messagetype](title, message, {
        timeOut: time,
        showCloseButton: close
      })
    }
  }
}
export function SetRememberMe (eve) {
  return dispatch => {
    dispatch({
      type: REMEMBER_ME,
      payload: eve.target.checked
    })
  }
}

export function UpdateSearchPage (toUpdate) {
  return dispatch => {
    dispatch({
      type: UPDATE_SEARCH_PAGE,
      payload: toUpdate
    })
  }
}

let _logout = () => {
  sessionStorage.clear()
  localStorage.removeItem('access_token')
  localStorage.removeItem('logged_user_email')
  localStorage.removeItem('logged_user_name')
  localStorage.removeItem('logged_user_id')
  localStorage.removeItem('logged_user_search_modules')
  localStorage.removeItem('logged_user_role')
  window.location.href = '/login'
}

const ACTION_HANDLERS = {
  [USER_LOG_IN]: (state, action) => state.set('isAuthenticated', action.payload),
  [AUTH_TOKEN]: (state, action) => state.set('authToken', action.payload),
  [USER_ROLE]: (state, action) => state.set('role', action.payload),
  [USERNAME_CHANGED]: (state, action) => state.set('emailid', action.payload),
  [USER_NAME]: (state, action) => state.set('userName', action.payload),
  [USER_LOGGING_OUT]: (state) => state.set('isAuthenticated', false).set('authToken', null),
  [TOGGLE_SIDEBAR]: (state, action) => state.set('isSideBarOpen', action.payload),
  [SET_USER]: (state, action) => state.set('currentUser', action.payload),
  [SET_USER_PERMISSIONS]: (state, action) => state.set('userPermissions', action.payload),
  [TOGGLE_SIDEBAR_POS]: (state, action) => state.set('sideBarMove', action.payload),
  [TOGGLE_EDIT_USER]: (state, action) => state.set('userEditable', action.payload),
  [SET_EDIT_TYPE]: (state, action) => state.set('EditType', action.payload),
  [TOGGLE_LOADING]: (state, action) => state.set('isLoading', action.payload),
  [TOGGLE_LOADING_PLACES]: (state, action) => state.set('isLoadingPlaces', action.payload),
  [TOGGLE_LOADING_COUNTS_SUMMARY]: (state, action) => state.set('isLoadingCountSummary', action.payload),
  [TOGGLE_LOADING_COUNTS_DETAILS]: (state, action) => state.set('isLoadingCountDetails', action.payload),
  [TOGGLE_LOADING_CD_SUMMARY]: (state, action) => state.set('isLoadingCDSummary', action.payload),
  [TOGGLE_LOADING_CD_COUNTS]: (state, action) => state.set('isLoadingCDCounts', action.payload),
  [TOGGLE_LOADING_CD_SITES]: (state, action) => state.set('isLoadingCDSites', action.payload),
  [TOGGLE_LOADING_CD_CONTACTS]: (state, action) => state.set('isLoadingCDContacts', action.payload),
  [TOGGLE_LOADING_CD_INSTALLS]: (state, action) => state.set('isLoadingCDInstalls', action.payload),
  [SET_IMMUTABLE_PROPS]: (state, action) => state.set(action.payload.key, action.payload.value),
  [SET_INSTANCE]: (state, action) => state.set('toastContainer', action.payload),
  [REMEMBER_ME]: (state, action) => state.set('rememberMe', action.payload),
  [IS_ADMIN]: (state, action) => state.set('isAdmin', action.payload),
  [SET_NOTIFICATION_COUNT]: (state, action) => state.set('notificationsCount', action.payload),
  [SET_NOTIFICATIONS]: (state, action) => state.set('notifications', action.payload),
  [SET_SEARCH_PARMS]: (state, action) => state.set('searchParams', action.payload),
  [SET_SEARCH_OPTIONS]: (state, action) => state.set('searchOptions', action.payload),
  [UPDATE_SEARCH_PAGE]: (state, action) => state.set('updateSearchPage', action.payload),
  [TOGGLE_SPLASHSCREEN]: (state, action) => state.set('viewSplashScreen', action.payload),
  [TOGGLE_EXPLORENOW]: (state, action) => state.set('viewExploreNow', action.payload),
  [SET_EMAIL_NETFINDERPLUS_SENT]: (state, action) => state.set('emailNetfinderPlus_Sent', action.payload),
  [TOGGLE_MAINTENANCE_PAGE]: (state, action) => state.set('viewMaintenancePage', action.payload),
}

const initialState = fromJS({
  isAuthenticated: false,
  emailid: null,
  password: null,
  role: null,
  isLoading: false,
  isLoadingPlaces: false,
  authToken: null,
  isSideBarOpen: false,
  userEditable: false,
  rememberMe: false,
  updateSearchPage: false,
  viewSplashScreen: !localStorage.getItem('logged_user_hide_splashscreen'),
  viewExploreNow: true,
  emailNetfinderPlus_Sent: false,
  viewMaintenancePage: false,
})

export default function globalReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
