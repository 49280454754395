import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'savedsearches',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const SavedSearches = require('./containers/SavedSearchesContainer').default
        const reducer = require('./modules/savedSearches').default

        injectReducer(store, { key: 'savedsearches', reducer })

        /*  Return getComponent   */
        cb(null, SavedSearches)

        /* Webpack named bundle   */
      },
      'Savedsearches'
    )
  }
})
