import React, { Component } from 'react'
import { FormGroup, HelpBlock } from 'react-bootstrap'
import RenderInput from './RenderInput'

export default class FieldGroup extends Component {

  render () {
    return (
      <FormGroup controlId={this.props.id}>
        {this.props.isRequired ? <span className='text-danger'> *</span> : null}
        {RenderInput(this.props)}
        {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>}
      </FormGroup>
    )
  }
}
