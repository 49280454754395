import axios from 'lib/axios'
import config from '../config'
import _ from 'lodash'

const convertParamNames = params => {
  return params.split(',').map(item => {
    return config.CONSTANTS.PARAM_NAME_TO_LABEL_TYPE[item.trim()] || item.trim()
  }).join(',')
}

export const convertParamNameToLabelType = param => {
  return param
    ? config.CONSTANTS.PARAM_NAME_TO_LABEL_TYPE[param.trim()] || param.trim()
    : param
}

export const convertLabelTypeToParamName = (labelType, context) => {
  if (labelType === 'install.vendor_id') return `install.${context}.vendor_id`
  return labelType
    ? config.CONSTANTS.LABEL_TYPE_TO_PARAM_NAME[labelType.trim()] || labelType.trim()
    : labelType
}

export const formatAutocompleteResponse = (response, taxonomy) => {
  if (!_.get(response, 'data.result.suggestion')) return []
  return response.data.result.suggestion.map((item) => {
    const techId = item.context
    const techCode = _.get(taxonomy, `id_to_code[${techId}]`)
    const techCategoryLabel = _.get(taxonomy, `tech[${techCode}].label`)
    return {
      param_name: convertLabelTypeToParamName(item.param, item.context),
      param_value: item.value,
      param_value_label: item.label,
      param_context_label: techCategoryLabel
    }
  })
}

/* The paramsToSearch is a comma delimited string containing the param names */
export function getAutocompleteSuggestions (paramsToSearch, intensity, context, query) {
  return axios
    .get(config.API.GET_SUGGESTIONS, {
      params: {
        param_name: convertParamNames(paramsToSearch),
        intensity,
        context,
        query
      }
    })
    .then(response => {
      if (_.isEmpty(response.data.result)) return []
      return formatAutocompleteResponse(response)
    })
}

export const getMultipleLabels = (queries) => {
  let promiseArray = _.map(queries, (query) => {
    return axios.get(config.API.GET_LABEL, {
      params: {
        param_name: convertParamNames(query.param_name),
        value: query.query.replace(/"/g, '')
      }
    }).then((response) => {
      const labelResult = response.data.result
      return {
        param_name: convertLabelTypeToParamName(labelResult.param),
        param_value: labelResult.value,
        param_value_label: labelResult.label }
    })
  })

  return Promise.all(promiseArray)
}
