import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/react'
import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import ReactFC from 'react-fusioncharts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import AppContainer from '../src/containers/AppContainer'
import createStore from './store/createStore'
import config from './config'

// ========================================================
// Sentry module
// ========================================================
Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.ENVIRONMENT,
  integrations: [new BrowserTracing()],

  tracesSampler: () => {
    if (config.ENVIRONMENT === 'development') {
      return 1.0
    } else {
      return 0.4
    }
  },
})

// ======================================================
// Fusion Charts license keys
// ======================================================
FusionCharts.options.license({
  key: config.FUSION_CHARTS_LICENSE_KEY,
  creditLabel: false,
})
// ======================================================
// Fusion Charts configuration
// ======================================================
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme)
// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')
const root = createRoot(MOUNT_NODE)

let render = () => {
  const routes = require('./routes/index').default(store)

  root.render(<AppContainer store={store} routes={routes} />)
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = error => {
      const RedBox = require('redbox-react').default

      root.render(<RedBox error={error} />)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }
    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        root.unmount()
        render()
      })
    )
  }
}

// ========================================================
// Go!
// ========================================================
render()
