import React from 'react'
import './style.scss'

export class UnAuthorize extends React.Component {
  render () {
    return (
      <div className='container-fluid'>
        <div className='auth-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 col-sm-6 col-sm-offset-3  col-md-offset-4 well log-in-well'>
                <h3>UnAuthorized User</h3>
                <div>
                  <p>You are not authorized to use this page</p>
                </div>
                <div>
                  <a onClick={() => this.props.router.push('/')}>
                    <i className='fa fa-reply' /> Back to the Search page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default UnAuthorize
