import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'maintenance',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const Maintenance = require('./containers/MaintenanceContainer').default

        /*  Return getComponent   */
        cb(null, Maintenance)

        /* Webpack named bundle   */
      },
      'Maintenance'
    )
  }
})
