import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'forgotPassword',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const ForgotPassword = require('./containers/ForgotPasswordContainer').default
        const reducer = require('./modules/forgotpassword').default

        injectReducer(store, { key: 'forgotpassword', reducer })

        /*  Return getComponent   */
        cb(null, ForgotPassword)

        /* Webpack named bundle   */
      },
      'ForgotPassword'
    )
  }
})
