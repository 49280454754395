import axiosInstance from 'axios'
import axios from 'lib/axios'
import FileDownload from 'react-file-download'
import config from '../config'
import { DownloadDateString } from './dateUtils'
/* This utility centralizes all information about the files we have available for public download on S3
   It provides a function that can be called to trigger a file download */

const s3PublicFilesPath = 'https://s3-eu-west-1.amazonaws.com/netfinder-public-files'

export const s3FileDetails = {
  'ProUser1' :
  { 'location' : `${s3PublicFilesPath}/NetFinder-Pro-User-Series-1.pdf`,
    'filename' : 'NetFinder-Pro-User-Series-1.pdf'
  },
  'ProUser2' :
  { 'location' : `${s3PublicFilesPath}/NetFinder-Pro-User-Series-2.pdf`,
    'filename' : 'NetFinder-Pro-User-Series-2.pdf'
  },
  'ProUser3' :
  { 'location' : `${s3PublicFilesPath}/NetFinder-Pro-User-Series-3.pdf`,
    'filename' : 'NetFinder-Pro-User-Series-3.pdf'
  },
  'ProUser4' :
  { 'location' : `${s3PublicFilesPath}/Netfinder-Pro-User-Series-4.pdf`,
    'filename' : 'Netfinder-Pro-User-Series-4.pdf'
  },
  'ProUser5' :
  { 'location' : `${s3PublicFilesPath}/Netfinder-Pro-User-Series-6.pdf`,
    'filename' : 'Netfinder-Pro-User-Series-6.pdf'
  },
  'TransitionGuide' :
  { 'location' : `${s3PublicFilesPath}/Netfinder-Transition-Guide.pdf`,
    'filename' : 'Netfinder-Transition-Guide.pdf'
  },
  'PostCodesMap' :
  { 'location' : `${s3PublicFilesPath}/PostcodeMap.pdf`,
    'filename' : 'PostcodesMap.pdf'
  },
  'TechTaxonomy' :
  { 'location' : `${s3PublicFilesPath}/Rhetorik+NetFinder+Data+Modules+and+Taxonomy.pdf`,
    'filename' : 'RhetorikTechnologiesMap.pdf'
  },
  'GDPREmailGuide' :
  { 'location' : `${s3PublicFilesPath}/B2B opt-out email marketing-GDPR good practice guide-Rhetorik-2202.pdf`,
    'filename' : 'B2B opt-out email marketing-GDPR good practice guide-Rhetorik-2202.pdf'
  },
  'GDPRTelemarketingGuide' :
  { 'location' : `${s3PublicFilesPath}/B2B opt-out telemarketing-GDPR good practice guide-Rhetorik-2202.pdf`,
    'filename' : 'B2B opt-out telemarketing-GDPR good practice guide-Rhetorik-2202.pdf'
  },
  'EMIG_Whitepaper' :
  { 'location' : `${s3PublicFilesPath}/EMIG-GDPR-whitepaper.pdf`,
    'filename' : 'EMIG-GDPR-whitepaper.pdf'
  },
  'GDPRForB2B' :
  { 'location' : `${s3PublicFilesPath}/GDPR-for-B2B-marketers.pdf`,
    'filename' : 'GDPR-for-B2B-marketers.pdf'
  },
  'GDPR_FAQ' :
  { 'location' : `${s3PublicFilesPath}/Rhetorik GDPR FAQs-2301.pdf`,
    'filename' : 'Rhetorik GDPR FAQs-2301.pdf'
  },
  'GDPR_Whitepaper' :
  { 'location' : `${s3PublicFilesPath}/Rhetorik-GDPR-White-Paper.pdf`,
    'filename' : 'Rhetorik-GDPR-White-Paper.pdf'
  },
  'GDPR_Conformity' :
  { 'location' : `${s3PublicFilesPath}/Letter of Conformity-Jan23.pdf`,
    'filename' : 'Rhetorik-Letter-of-GDPR-Conformity.pdf'
  },
  'Industry_Codes_UK07' :
  { 'location' : `${s3PublicFilesPath}/UK07_SIC_Codes.pdf`,
    'filename' : 'Industry_Codes_UK07.pdf'
  },
  'Industry_Codes_UK92' :
  { 'location' : `${s3PublicFilesPath}/UK92_SIC_Codes.pdf`,
    'filename' : 'Industry_Codes_UK92.pdf'
  },
  'Industry_Codes_NAICS' :
  { 'location' : `${s3PublicFilesPath}/Industry Codes NAICS.pdf`,
    'filename' : 'Industry Codes NAICS.pdf'
  },
  'Industry_Codes_NACE2' :
  { 'location' : `${s3PublicFilesPath}/Industry Codes NACE2.pdf`,
    'filename' : 'Industry Codes NACE2.pdf'
  },
  'Industry_Codes_USSIC87' :
  { 'location' : `${s3PublicFilesPath}/Industry Codes USSIC87.pdf`,
    'filename' : 'Industry Codes USSIC87.pdf'
  },
  'OnlineUserguide' :
  { 'location' : `${s3PublicFilesPath}/NetFinder Online User Guide November 2023.pdf`,
    'filename' : 'NetFinder Online User Guide November 2023.pdf'
  },
  'ConsentAndOptIn' :
  { 'location' : `${s3PublicFilesPath}/Consent and Opt-in-FAQs-2107.pdf`,
    'filename' : 'Consent and Opt-in-FAQs-2107.pdf'
  },
  'BrexitTransitionPeriodInformation' :
  { 'location' : `${s3PublicFilesPath}/Rhetorik-Brexit-Transition-Period-Information.pdf`,
    'filename' : 'Rhetorik-Brexit-Transition-Period-Information.pdf'
  }
}

export function downloadS3File (file) {
  axiosInstance.get(file.location,
    {
      headers: {
        'Content-Type': 'application/pdf'
      },
      responseType: 'blob'
    })
    .then((response) => {
      FileDownload(response.data, file.filename)
    })
}

export function downloadSuppressionLists () {
  axios
      .get(config.API.LOAD_SUPPRESSION_FILE)
      .then(function(response) {
        let filename = response.data.result.filename
        axiosInstance
          .get(response.data.result.url, {
            headers: {
              'Content-Type': response.data.result.mime_type
            },
            responseType: 'arraybuffer'
          })
          .then(response => {
            let todaysDate = DownloadDateString()
            FileDownload(response.data, filename.replace('.', `_${todaysDate}.`))
          })
      })
}
