export const enCommon = {
  admin: 'Admin',
  all_categories: 'All Categories',
  apply: 'Apply',
  appTitle: 'Netfinder',
  cancel: 'Cancel',
  clear_all: 'Clear all',
  clear: 'clear',
  complianceCentre: 'Compliance Centre',
  contact_link: 'https://www.rhetorik.com/contact-us',
  continue: 'Continue',
  company: 'Company',
  contacts: 'Contacts',
  deleted: 'Deleted',
  delete_selected: 'Delete Selected',
  downloads: 'Downloads',
  expirationDate: 'Expiration Date',
  expiry_date: 'Expiry Date',
  exporting: 'Exporting',
  export_date: 'Export Date',
  file_name: 'File Name',
  file_size: 'File Size',
  lastLogin: 'Last Login',
  msiReport: 'Market Analytics',
  name: 'Name',
  notifications: 'Notifications',
  numberFormatDecimal: '0,0.0',
  numberFormatInteger: '0,0',
  revID: 'RevID',
  search: 'Search',
  search_criteria: 'Search Criteria',
  savedSearches: 'Saved Searches',
  select_all: 'Select all',
  site: 'Site',
  techs: 'Technologies',
  username: 'Username',
  upgrade_license_message: 'Please contact Rhetorik customer support to upgrade your license.',
  yes: 'Yes',
  tablefirstName: 'First Name',
  tablelastName: 'Last Name',
  tablejobTitle: 'Job Title',
  tablecontactInfo: 'Contact Info',
  tablecompanyName: 'Company Name',
  tablecompanyUrl: 'Company URL',
  tablesite: 'Site',
  tablename: 'Name',
  tableprimaryJobFunction: 'Primary Job Function',
  tableemail: 'Email',
  tablephone: 'Telephone',
  tablelinkedinUrl: 'LinkedIn URL',
  tableoffice: 'Office',
  tablecity: 'City',
  tablecountry: 'Country',
  tablemobile: 'Mobile',
  tableddi: 'DDI',
}
