import React from 'react'
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap'
import logo from '../../../../public/assets/images/logo.png'
import './style.scss'
export class PageNotFound extends React.Component {
  render () {
    return (
      /* <div>
                <Navbar className='brand-nav'>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <img src={logo} className='brand-logo'/>
                        </Navbar.Brand>
                    </Navbar.Header>
                </Navbar>
                <h3 className='text-info error-message'>You're looking for something which is not there</h3>
            </div> */
      <div className='container-fluid'>
        <div className='auth-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 col-sm-6 col-sm-offset-3  col-md-offset-4 well log-in-well'>
                <h3>Page Not Found</h3>
                <div>
                  <p>You're looking for something which is not there</p>
                </div>
                <div>
                  <a onClick={() => this.props.router.push('/')}>
                    <i className='fa fa-reply' /> Back to the Search page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default PageNotFound
