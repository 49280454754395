const NODE_ENV = process.env.NODE_ENV || 'production'
const HOST = process.env.HOST || '0.0.0.0'
const PORT = process.env.PORT || '3000'

// If absolute URL not specified, the relative will be use (proxy required)
const URL = (()=>
{
     switch(process.env.NODE_ENV) {
           case 'development': return 'https://nf15-api.netfinderonline.com/netfinder15-api-dev';
           case 'staging'    : return 'https://nf15-api.netfinderonline.com/netfinder15-api-staging';
           case 'production' : return 'https://nf15-api.netfinderonline.com/netfinder15-api';
           default           : return 'https://nf15-api.netfinderonline.com/netfinder15-api';
          }
})();

const ENABLE_HTTPS = process.env.enable_https || true
const RELEASE_BUILD = process.env.RELEASE_BUILD === 'true'

module.exports = {
  /** The environment to use when building the project */
  env: NODE_ENV,
  /** The full path to the project's root directory */
  basePath: __dirname,
  /** The name of the directory containing the application source code */
  srcDir: 'src',
  /** The file name of the application's entry point */
  main: 'main',
  /** The name of the directory in which to emit compiled assets */
  outDir: 'dist',
  /** The base path for all projects assets (relative to the website root) */
  publicPath: '/',
  /** Whether to generate sourcemaps */
  sourcemaps: !RELEASE_BUILD,
  /** A hash map of keys that the compiler should treat as external to the project */
  externals: {},
  /** A hash map of variables and their values to expose globally */
  globals: {},
  /** Whether to enable verbose logging */
  verbose: false,
  /** The list of modules to bundle separately from the core application code */
  vendors: ['@babel/polyfill', 'react', 'react-dom', 'redux', 'react-redux', 'redux-thunk', 'react-router'],
  www_server_port: PORT,
  www_server_host: HOST,
  api_url: URL,
  enable_https: ENABLE_HTTPS
}
