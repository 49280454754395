export const enMSIReport = {
  autocompletePlaceholder: 'Please enter the name of a tech category',
  autocompleteTooltip: 'The Tech Category defines the group of products to be analyzed.' +
    " If you don't know which Tech Category to choose, enter the name of a related product" +
    ' or keywords and we will try to suggest a suitable Tech Category for you.',
  categoryDelimiter: '>',
  allVerticalMarkets: 'All Vertical Markets',
  allRegions: 'All Regions',
  allStatsHeader: {
    verticalMarket: 'Installations of {{techs}} in all Vertical Markets',
    region: 'Installations of {{techs}} in all Regions',
  },
  breakdownBy: 'Breakdown by',
  country: 'Country',
  greatBritain: 'United Kingdom',
  insightsTableTitle: 'Installations of {{techs}}',
  installationsCountLabel: 'Installations of {{techs}} ',
  installations: 'Installations',
  ireland: 'Ireland',
  pageTitle: 'Market Share Report',
  landingPageIntro: "Welcome to Rhetorik's",
  landingPageTitle: 'Market Analytics Report',
  landingBigText: 'Explore and understand the competitive landscape within the universe' +
   " of Netfinder's technographic coverage. NetFinder Market Analytics allows you to see" +
   ' the leading vendors of a particular technology' +
   ' across all major industry sectors and regions.',
  landingPageSmallText: ' Just type in the product or technology you want to explore to' +
   ' view live summaries for the various vertical markets and regions. Compare all segments using the' +
   ' Market Presence tab.',
  landingTechLinkText: 'Click here to download a list of our Tech Categories',
  marketShareTabTitle: 'Market Diversity',
  marketInsightsTabTitle: 'Market Presence',
  no_permission: "Your account doesn't have permission to access the Market Analytics Report.",
  no_permission_link: ' Please contact a Customer Service or Sales representative to activate this feature.',
  notes: 'Notes',
  note1: '- An <span>installation</span> is the detected presence of a technology used at an office.',
  note2: '- Office and installation counts may not match, as more than one installation of a technology may be detected at an office.',
  note3: '- Data according to the Netfinder Universe.',
  note4: '- Company and contact details for these installations are available only for offices within your licensed dataset.',
  not_applicable: 'N/A',
  otherVendors: 'Other Vendors',
  product_series: 'Product/Series',
  ranking: 'Ranking',
  rankWithNumber: 'Rank {{number}}',
  region: 'Region',
  rtcPath: 'RTC Path',
  rtcPathTooltip: "The RTC Path is the Tech Category's position within the Rhetorik Technological Classification (RTC) Tree." +
    ' You can go up one or two levels to widen the number of products analysed by this report.',
  seeAllVendors: 'See Vendor Details',
  seeAllVendors_plural: 'See all {{count}} Vendors',
  sites: 'Offices',
  StatsHeader: {
    verticalMarket: 'Installations of {{techs}} in the {{market}} Sector',
    region: 'Installations of {{techs}} in the {{market}} Region',
  },
  techLinkText: '(show search results)',
  techCategory: 'Tech Category',
  techWarning: 'This category does not contain enough data ' +
    'to generate a report. Please select another tech category',
  topVendors: 'Top Vendor',
  topVendors_plural: 'Top {{count}} Vendors',
  up_next: 'Up Next: ',
  vdi: 'VDI',
  vdiTooltip: 'The Vendor Diversity Index (VDI) is an index between 1 and 100 ' +
    "which measures the market's concentration and diversity to quantify its competitiveness." +
    ' High VDI values suggest near monopolistic markets where lower ' +
    'values indicate almost perfect competition scenarios.',
  vdiNotAvailable: 'Not enough data',
  vendorDiversityIndex: 'Vendor Diversity Index',
  vendors: 'Vendors',
  vendor: 'Vendor',
  vendorLinkText: ' (click to see search results)',
  vendorsPrefix: 'Vendors with ',
  verticalMarket: 'Vertical Market',
  vdiHighlyDiverse: 'Highly Diverse Vendors',
  vdiModeratelyDiverse: 'Moderately Diverse Vendors',
  vdiModeratelyDominated: 'Moderately Dominated by Top Vendors',
  vdiHighlyDominated: 'Highly Dominated by Top Vendors'
}
