import React, { Component } from 'react'
import logoNetFinderPlus from '../../../../public/assets/images/logoNetFinderPlus-white.jpg'
import logoNetFinderPlus2 from '../../../../public/assets/images/rhetorik_new_logo_white.svg'
import errorWrench from '../../../../public/assets/images/NFMaintenanceWrench.png'
import './style.scss'
import '../../../../scss/adminLTE.scss'
import '../../../../scss/skin.scss'
import '../../../../scss/custom.scss'
import '../../../../scss/style.scss'

class Error extends Component {
  render () {
    return (
      <div className='header-login'>
        <div className='body'>
          <div className='login-topbar'>
            <a href='https://rhetorik.com/' target='_blank'>
              <img src={logoNetFinderPlus2} alt='NetFinder+' />
            </a>
          </div>
          <div className='login-wrapper'>
            <section className='login-panels'>
              <div className='row'>
                <div className='col-lg-5 col-md-6 col-sm-9 col-xs-12'>
                  <div className='left-panel'>
                    <div className='login-logoNetFinder'>
                      <img src={logoNetFinderPlus} alt='NetFinder+' />
                    </div>
                    <div className='error'>
                      <img src={errorWrench} alt='NetFinder+' className='errorWrench' />
                      <h1>
                        Sorry, NetFinder ran into an unexpected error. Our team has been notified and is working to fix the issue.
                        <h2>We apologise for any inconvenience</h2>
                      </h1>
                      <h2>You can reach us at <a href='mailto:support@rhetorik.com'>support@rhetorik.com </a></h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default Error
