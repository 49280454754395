import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'notifications',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const Notifications = require('./containers/NotificationContainer').default
        const reducer = require('./modules/notifications').default

        injectReducer(store, { key: 'notifications', reducer })

        /*  Return getComponent   */
        cb(null, Notifications)

        /* Webpack named bundle   */
      },
      'Notifications'
    )
  }
})
