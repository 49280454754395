import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import FieldGroup from '../../components/FieldGroup'

export default class UserDetails extends Component {
  render () {
    var getAddress = (addOne, addTwo, addThree) => {
      let address = []
      if (addOne) {
        address.push(addOne)
      }
      if (addTwo) {
        address.push(addTwo)
      }
      if (addThree) {
        address.push(addThree)
      }
      return address
    }

    return (
      <div>
        {this.props.CurrentUser ? (
          <div className='tab-pane active' id='control-sidebar-home-tab'>
            <h2 className='control-sidebar-heading'>
              Your Details{' '}
              <a
                onClick={() => {
                  this.props.EditUser('userDetails')
                }}
                className='btn-sm btn-info edit-user-btn'
              >
                Edit <i className='fa fa-pencil-square-o' />
              </a>
            </h2>
            <div className='row col-md-12 pull-left'>
              <ul className='user-details'>
                <li className='profile-label'>Name</li>
                <li className='profile-data'>{this.props.CurrentUser.name}</li>
                <li className='profile-label'>Job Title</li>
                <li className='profile-data'>{this.props.CurrentUser.title}</li>
                <li className='profile-label'>Company</li>
                <li className='profile-data'>{this.props.CurrentUser.company}</li>
              </ul>
            </div>
            <div className='clearfix' />
            <h2 className='control-sidebar-heading'>
              Address Details{' '}
              <a
                onClick={() => {
                  this.props.EditUser('userAddressDetails')
                }}
                className='btn-sm btn-info edit-user-btn'
              >
                Edit <i className='fa fa-pencil-square-o' />
              </a>
            </h2>
            <div className='row col-md-12 pull-left'>
              <ul className='user-details'>
                <li className='profile-label'>Address: </li>
                <li className='profile-data'>
                  {getAddress(
                    this.props.CurrentUser.address_1,
                    this.props.CurrentUser.address_2,
                    this.props.CurrentUser.address_3
                  ).map((addr, i) => (
                    <span key={i}>
                      {addr}
                      {i !=
                      getAddress(
                        this.props.CurrentUser.address_1,
                        this.props.CurrentUser.address_2,
                        this.props.CurrentUser.address_3
                      ).length -
                        1 ? (
                          <span>, </span>
                      ) : null}
                      <br />
                    </span>
                  ))}
                </li>
                <li className='profile-label'>Town/city: </li>
                <li className='profile-data'>{this.props.CurrentUser.city}</li>
                <li className='profile-label'>Postcode: </li>
                <li className='profile-data'>{this.props.CurrentUser.zip}</li>
                <li className='profile-label'>Country: </li>
                <li className='profile-data'>{this.props.CurrentUser.country}</li>
                <li className='profile-label'>Telephone: </li>
                <li className='profile-data'>{this.props.CurrentUser.phone}</li>
                <li className='profile-label'>Email: </li>
                <li className='profile-data'>{this.props.CurrentUser.email}</li>
              </ul>
            </div>
            <div className='clearfix' />
            <h2 className='control-sidebar-heading'>
              Your Password{' '}
              <a
                onClick={() => {
                  this.props.EditUser('userPasswordDetails')
                }}
                className='btn-sm btn-info edit-user-btn'
              >
                Edit <i className='fa fa-pencil-square-o' />
              </a>
            </h2>
          </div>
        ) : (
          'User details Not found'
        )}
        <Modal show={this.props.userEditable} onHide={() => this.props.EditUser()} className='user-details-modal'>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.EditType == 'userDetails'
                ? 'Edit User'
                : this.props.EditType == 'userAddressDetails'
                  ? 'Edit User Address'
                  : this.props.EditType == 'userPasswordDetails' ? 'Edit User Password' : 'Edit Details'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.EditType == 'userDetails' ? (
              <div className='user-bio-details'>
                <FieldGroup type='text' name='name' disabled value={this.props.CurrentUser.name} label='Name' />
                <FieldGroup
                  type='text'
                  name='title'
                  defaultValue={this.props.CurrentUser.title}
                  onChange={this.props.UserDetailsEditHandle}
                  label='Job Title'
                />
                <FieldGroup
                  type='text'
                  name='company'
                  disabled
                  defaultValue={this.props.CurrentUser.company}
                  label='Company'
                />
              </div>
            ) : this.props.EditType == 'userAddressDetails' ? (
              <div className='user-demography-details'>
                <FieldGroup
                  type='text'
                  name='address_1'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.address_1}
                  label='Address Line 1'
                />
                <FieldGroup
                  type='text'
                  name='address_2'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.address_2}
                  label='Address Line 2'
                />
                <FieldGroup
                  type='text'
                  name='address_3'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.address_3}
                  label='Address Line 3'
                />
                <FieldGroup
                  type='text'
                  name='city'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.city}
                  label='City'
                />
                <FieldGroup
                  type='text'
                  name='zip'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.zip}
                  label='Postal/ Zip Code'
                />
                <FieldGroup
                  type='text'
                  name='country'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.country}
                  label='Country'
                />
                <FieldGroup
                  type='text'
                  name='phone'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.phone}
                  label='Phone'
                />
                <FieldGroup
                  type='text'
                  name='email'
                  onChange={this.props.UserDetailsEditHandle}
                  defaultValue={this.props.CurrentUser.email}
                  label='Email'
                />
              </div>
            ) : this.props.EditType == 'userPasswordDetails' ? (
              <div className='user-password-details'>
                <FieldGroup
                  type='password'
                  name='old_password'
                  onChange={this.props.UserDetailsEditHandle}
                  label='Old Password'
                />
                <FieldGroup
                  type='password'
                  name='new_password'
                  onChange={this.props.UserDetailsEditHandle}
                  label='New Password'
                />
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle='default' className='pull-left' onClick={() => this.props.EditUser()}>
              Cancel
            </Button>
            <Button bsStyle='primary' className='pull-right' bsStyle='primary' onClick={() => this.props.UpdateUser()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
