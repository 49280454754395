import i18n from 'i18next'
import { enCommon } from './en/enCommon.js'
import { enDownloads } from './en/enDownloads.js'
import { enAdmin } from './en/enAdmin.js'
import { enDetails } from './en/enDetails.js'
import { enSearch } from './en/enSearch.js'
import { enComplianceCenter } from './en/enComplianceCenter.js'
import { enMSIReport } from './en/enMSIReport.js'
import { enResults } from './en/enResults.js'
import { enLogin } from './en/enLogin.js'
import { enCompanyDetails } from './en/enCompanyDetails.js'
import { enModals } from './en/enModals.js'

if (i18n.isInitialized) {
  i18n.addResources('en', 'common', enCommon)
  i18n.addResources('en', 'downloads', enDownloads)
  i18n.addResources('en', 'admin', enAdmin)
  i18n.addResources('en', 'details', enDetails)
  i18n.addResources('en', 'search', enSearch)
  i18n.addResources('en', 'compliance', enComplianceCenter)
  i18n.addResources('en', 'msi', enMSIReport)
  i18n.addResources('en', 'results', enResults)
  i18n.addResources('en', 'results', enLogin)
  i18n.addResources('en', 'results', enCompanyDetails)
  i18n.addResources('en', 'results', enModals)
} else {
  i18n.init({
    interpolation: {
      escapeValue: false
    },
    lng: 'en',
    fallbackLng: 'en',
    ns: [
      'common',
      'downloads',
      'admin',
      'details',
      'search',
      'compliance',
      'msi',
      'modals',
      'results',
      'login',
      'company'
    ],
    defaultNS: 'common',
    resources: {
      en: {
        common: enCommon,
        downloads: enDownloads,
        admin: enAdmin,
        details: enDetails,
        search: enSearch,
        compliance: enComplianceCenter,
        modals: enModals,
        msi: enMSIReport,
        results: enResults,
        login: enLogin,
        company: enCompanyDetails
      }
    }
  })
}

export default i18n
