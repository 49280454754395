import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'companyDetails/:companyId',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const companyDetails = require('./components/companyDetails').default
        const reducer = require('./reducers/companyDetails').default

        injectReducer(store, { key: 'companyDetails', reducer })

        /*  Return getComponent   */
        cb(null, companyDetails)

        /* Webpack named bundle   */
      },
      'CompanyDetails'
    )
  }
})
