import _ from 'lodash'
import config from '../../../config'

export const isIncludedInSearchParams = (value, params, apiString) => {
  if (_.get(params, 'value', '').split(',').includes(value)) {
    return true
  } else {
    return false
  }
}

export const getDistanceDisableStatus = defaultSearchParams => {
  if (defaultSearchParams) {
    if (!defaultSearchParams['site.location_zip']) {
      return true
    } else if (defaultSearchParams['site.location_zip']) {
      let reqArr = getArray(defaultSearchParams['site.location_zip'])

      if (!reqArr.length) {
        return true
      } else if (reqArr.length === 1) {
        if (_.inRange(reqArr[0].replace(' ', '').length, config.CONSTANTS.VALID_MIN_ZIP_LENGTH, config.CONSTANTS.VALID_MAX_ZIP_LENGTH + 1)) {
          return false
        }
        return true
      } else if (reqArr.length > 1) {
        return true
      }
    }
  }
  return true
}

export const addDoubleQuotesToParams = str => {
  let allOptions = str.split('),')
  let requiredOptions = []

  allOptions.map(opt => {
    let optionType = opt[0] == '-' || opt[0] == '+' ? opt[0] : ''
    opt
      .replace('(', '')
      .replace(')', '')
      .split(',')
      .map(o => requiredOptions.push(`${optionType}\"${o.replace(`${optionType}`, '')}\"`))
  })

  return requiredOptions && requiredOptions.length ? requiredOptions.join(',') : ''
}

// gives the checkbox status based value of contact.is_remote in defaultSearchParams
export const getRemoteContacsCheckboxState = setting => {
  return !_.isNil(setting) ? _.some(['false', 'unknown'], value => _.includes(setting.toString(), value)) : false
}

// gives the checkbox status based value of contact.has_email in defaultSearchParams
export const getRequireEmailCheckboxState = setting => {
  return setting ? setting.toString() === 'true' : false
}

// converts csv string to an array
export const getArray = csv => {
  let reqArr = []
  if (csv) {
    reqArr = csv
      .replace('(', '')
      .replace(')', '')
      .replace(new RegExp('-', 'g'), '')
      .split(',')
  }
  return reqArr
}

// converts array(it may contain strings and objects) to csv string
// if the current value of array is an object, then it csv value will include value of key 'valueof' of that object
export const getParamValueArray = (array, valueof) => {
  let reqCsv = ''
  if (array) {
    reqCsv = []
    array.map(a => {
      if (typeof a === 'string' && reqCsv.indexOf(a) == -1) {
        reqCsv.push(a)
      } else if (typeof a === 'object' && Object.keys(a).indexOf(valueof) != -1 && reqCsv.indexOf(a[valueof]) == -1) {
        reqCsv.push(a[valueof])
      }
    })
    reqCsv = reqCsv && reqCsv.length ? reqCsv.join(',') : ''
  }
  return reqCsv
}

// converts array(it may contain strings and objects) to csv string
export const getCSV = array => {
  let reqcsv = ''
  let updatedarray = []
  if (array) {
    array.map(a => {
      if (typeof a === 'string') {
        updatedarray.push(a)
      } else if (typeof a === 'object') {
        updatedarray.push(a[''])
      }
    })
  }
  if (updatedarray && updatedarray.length) {
    reqcsv = updatedarray.join(',')
  }
  return reqcsv
}

export const getProjectCSV = array => {
  let reqcsv = ''
  let updatedarray = []
  if (array) {
    array.map(a => {
      if (typeof a === 'string') {
        updatedarray.push(a)
      } else if (typeof a === 'object') {
        updatedarray.push(a['label'])
      }
    })
  }
  if (updatedarray && updatedarray.length) {
    reqcsv = updatedarray.join(',')
  }
  return reqcsv
}

export const getAllSuggestions = (allsuggs, defaultSearchParams, searchModule) => {
  let reqsugs = []
  let alltype = !checkSearchModule(searchModule) ? 'all_with_projects' : 'all_without_projects'
  if (defaultSearchParams['all.' + alltype] && defaultSearchParams['all.' + alltype].split(',').length) {
    defaultSearchParams['all.' + alltype].split(',').map(a => reqsugs.push(a))
  }
  if (allsuggs) {
    allsuggs.map(a => {
      if (reqsugs.indexOf(a.param_value_label) == -1) {
        reqsugs.push(a.param_value_label)
      }
    })
  }
  return reqsugs
}

// Checks whether the search_module of a search_option(get search_options
// from getSearchOptions()) is valid for logged in user or not
export const checkSearchModule = moduleId => {
  return false
}