import React, { Component } from 'react'

export default class NewAlertLabel extends Component {
  render () {
    return (
      <div className='new-alert-label'>
        <i className='fa fa-certificate' />
        <span className='new-alert-text'>NEW!</span>
      </div>
    )
  }
}
