export const enCompanyDetails = {
  all_licensed_countries: 'All Licensed Countries',
  breakdown: 'Breakdown',
  broad_group: 'Technology Function',
  business: 'Business',
  city: 'City',
  company_id: 'Company_ID',
  contacts: 'Contacts',
  compliance_center: 'Compliance Centre',
  contact_id: 'Contact_ID',
  county: 'State',
  country: 'Country',
  countries_title: 'Countries',
  details: 'Details',
  disabled_country: '* This country is not included in your package.',
  disabled_items_sites: 'Some offices are not included in your licence. Please contact our sales teams to get a quote at sales@rhetorik.com',
  disabled_items_contacts: 'Some contacts are not included in your licence. Please contact our sales teams to get a quote at sales@rhetorik.com',
  disabled_items_installs: 'Some technologies are not included in your licence. Please contact our sales teams to get a quote at sales@rhetorik.com',
  disabled2: 'To view this data please contact your customer ' +
  'service representative to extend your license',
  email: 'Email',
  employees: 'Employees',
  employees_onsite: 'Employees in Office',
  employees_range: 'Employees Range',
  first: 'First',
  function: 'Function',
  functional_group: 'Functional Group',
  geographical_distribution: 'Geographical Distribution',
  globalHQ_title: 'Global HQ Address',
  it_contacts: 'IT Contacts',
  in: 'in',
  installs: 'Installs',
  item_match_message: 'This item matches your search criteria',
  key_contacts: 'By Contacts',
  licensed_countries: 'Your license includes data in',
  last: 'Last',
  map_note: `Click on a office's map-marker to link to its office details page.`,
  major_group: 'Technology Category',
  market: 'Market',
  markets_title: 'By Vertical Market',
  matches: `Found {{count}} match for '{{filterString}}'`,
  matches_plural: `Found {{count}} matches for '{{filterString}}'`,
  no_data: `No {{item_type}} Found`,
  opt_in_warning: 'Attention: Due to their geographical location, direct marketing (phone and/or email)' +
  ' to some contacts on this page may require their prior consent.' +
  ' Please ensure your company’s policy and practices ' +
  'are aligned before using emails and/or phone to engage these contacts. For ' +
  'more information and guidance please visit our ',
  opt_in_email: 'Local e-privacy legislation requires ' +
  'prior consent for email marketing to this contact',
  opt_in_phone: 'Local e-privacy legislation requires ' +
  'prior consent for tele-marketing to this contact',
  other_key_contacts: 'Other Key Functions',
  overview: 'Overview',
  overview_installs: `{{count}} install in this category`,
  overview_installs_plural: `{{count}} installs in this category`,
  pcs: 'PCs',
  pcs_range: 'PCs Range',
  pageTitle: 'CompanyDetails',
  phone: 'Phone',
  plus_more: '+ {{count}} more',
  product: 'Product',
  search_results: 'Search Results',
  region: 'Region',
  revenue: 'Revenue',
  site: 'Office',
  site_id: 'Office_ID',
  sites: 'Offices',
  site_name: 'Office Name',
  site_type: 'Type',
  technology: 'Technology',
  techs: 'Technologies',
  mobile: 'Mobile',
  ddi: 'DDI',
  tech_short: 'Tech',
  tech_overview: 'Technology Map',
  title: 'Title',
  tracked_title: 'Tracked by Rhetorik',
  unspecified: 'Unspecified',
  vendor: 'Vendor'
}
