export const cleanRestorelocalStorage = () => {
  var logged_user_email = localStorage.getItem("logged_user_email")
  var export_limit = localStorage.getItem("export_limit")
  var logged_user_search_modules = localStorage.getItem("logged_user_search_modules")
  var logged_user_role = localStorage.getItem("logged_user_role")
  var logged_user_name = localStorage.getItem("logged_user_name")
  var logged_user_id = localStorage.getItem("logged_user_id")
  var logged_user_hide_splashscreen = localStorage.getItem("logged_user_hide_splashscreen")

  setTimeout(() => {
  localStorage.clear()

  localStorage.setItem("export_limit", export_limit)
  localStorage.setItem("logged_user_search_modules", logged_user_search_modules)
  localStorage.setItem("logged_user_hide_splashscreen", logged_user_hide_splashscreen)
  localStorage.setItem("logged_user_id", logged_user_id)
  localStorage.setItem("logged_user_name", logged_user_name)
  localStorage.setItem("logged_user_email", logged_user_email)
  localStorage.setItem("logged_user_role", logged_user_role)
  }, 1000)
}

export const saveLeadNeuronSearch = (groupename, value) => {
  setTimeout(() => {
    localStorage.setItem(`${groupename}`, [groupename, value])
    }, 300)
}
