export const enSearch = {
  business_size_section_label: 'Business Size',
  employees_section_label: 'Employees',
  sales_section_label: 'Revenues',
  contact_is_remote_label: 'Exclude Remote Contacts',
  contact_ctps_label: 'Exclude DNC Contacts',
  contacts_section_label: 'Contacts',
  city: 'City',
  company_donut_label: 'Companies',
  contact_donut_label: 'Contacts',
  county: 'State',
  counts_message: 'The grey number beside each search filter indicates the number of matching {{entities}}.',
  diabledTechMessage: 'Your license does not include access to technology information. ' +
  'Please contact Rhetorik Sales and Support to Upgrade your License.',
  dnb_section_label: 'Dun & Bradstreet',
  email_donut_label: 'Contacts with emails',
  exclude_industry_codes: 'Exclude Industry',
  companyHeadCount: 'Company Head Count',
  officeHeadCount: 'Office Head Count',
  industries_section_label: 'Industries',
  install_donut_label: 'Technologies',
  Industry_Codes_UK07: 'Download the UK07 Industry Codes',
  less_options: 'Less Search Options...',
  locations_section_label: 'Locations',
  limited_results_note: '* Please note that the criteria below can only be applied' +
  ' to offices and companies in the United Kingdom and Ireland and will limit your ' +
  'results set to those countries',
  lists_section_label: 'Lists',
  office_revenue_label : 'Office Revenue',
  company_revenue_label : 'Company Revenue',
  more_options: 'More Search Options...',
  nav_bar_title: 'Refine your Search with Filters',
  note_industry_code: 'n.b. Industry codes are derived from data gathered by Dun and Bradstreet',
  note_site_type: 'Branches are offices that include significant tech installs and/or Key Decision Maker(s).',
  note_vertical_market: 'n.b. Vertical Markets are derived from SIC codes supplied by Dun and Bradstreet.',
  pageTitle: 'Search',
  project_donut_label: 'Projects',
  radius_tooltip: 'The radius search only applies to offices and companies within Great Britain.' +
  ' It requires a single valid post code in Great Britain to be activated',
  range_equals: '=',
  range_between: 'to',
  range_greater: '>',
  range_lesser: '<',
  rollover_company: 'The number of organisations included within the search criteria',
  rollover_contact: 'The number of business contacts included within the search criteria',
  rollover_email: 'The number of business contacts with email addresses within the search criteria ',
  rollover_site: 'The number of physical offices relating to organisations within the search criteria',
  rollover_install: ' Technologies, vendors and products that have been detected or phone-verified at the site and/or company level',
  sales_label: `Revenues per Office in {{preferredCurrency}}`,
  site_donut_label: 'Offices',
  site_section_label: 'Office Type',
  sites_with_active_projects_label: 'Offices with Active Projects',
  site_ctps_label: 'Exclude DNC Offices',
  company_ctps_label: 'Exclude DNC Companies',
  technologies_section_label: 'Technologies',
  vertical_markets_section_label: 'Verticals',
  lead_neuron_section_label: 'LeadNeuron',
  location_all_offices: 'All Offices',
  location_headquarter: 'Headquarter',

}
