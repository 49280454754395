import React, {useState, Component} from 'react'
import PropTypes from 'prop-types'

import config from '../../config'
import { getFilteredCountries, getNbCountries } from '../../lib/ExploreNowUtils'
import logoNetFinderPlus from '../../../public/assets/images/logoNetFinderPlus-white.jpg'
import xClose from          '../../../public/assets/images/x_gray.png'
import { getFlagLogo } from '../../lib/flagUtils'
import { Modal, Carousel } from 'react-bootstrap'
import {addCommas} from '../../lib/globalUtils'
import {getCountryName} from '../../lib/countriesUtils'


function ControlledCarousel (props) {
  
  let listCountriesFiltered = getFilteredCountries(props.compteurs)

  let displayedCountries = Object.keys(listCountriesFiltered).map((country) => {
    return {
    "countryCode": country,
    "nbSites": props.compteurs.site.location.country_code.total[country] ? props.compteurs.site.location.country_code.total[country] : 0,
    "nbInstalls": props.compteurs.install.location.country_code.total[country] ? props.compteurs.install.location.country_code.total[country] : 0,
    "nbContacts": props.compteurs.contact.location.country_code.total[country] ? props.compteurs.contact.location.country_code.total[country] : 0,
    }
  })
  
  switch(props.classement) {
     case 'Sites':
      displayedCountries.sort(function(a, b) {return (b.nbSites - a.nbSites)}); 
       break
       case 'Installs':
        displayedCountries.sort(function(a, b) {return (b.nbInstalls - a.nbInstalls)}); 
      break
      case 'Contacts':
        displayedCountries.sort(function(a, b) {return (b.nbContacts - a.nbContacts)}); 
      break
      default:
        displayedCountries.sort(function(a, b) {return (b.nbSites - a.nbSites)}); 
        break
  }
  
  // Group countries 4 by 4
  const groupBy = 4
  const groupedCountries = []
  for (let i = 0; i < displayedCountries.length; i=i+groupBy) {
    groupedCountries.push(displayedCountries.slice(i, i+groupBy))
   }

    // Get one country description
    const getFlag = (country) => {
      const logo = getFlagLogo(country.countryCode)
      //console.log("logo", country, logo)
      return (
        <div key={country.countryCode} className='splashscreen-country'>
          <div className='title'>
            <img src={logo} className='flag-logo' alt={country.countryCode} />
            <span className='banner-text'>{getCountryName(country.countryCode)}</span>
          </div>
          <div className='banner-counts'>
            <div className='countryCounts'>
              <div>Offices</div>
              <div className='right'>
                {country.nbSites != 0 ? addCommas(country.nbSites) : <i>Coming soon</i>}
              </div>
            </div>
            <div className='countryCounts'>
              <div>Contacts</div>
              <div className='right'>
              {country.nbContacts != 0 ? addCommas(country.nbContacts) : <i>Coming soon</i>}
              </div>
            </div>
            <div className='countryCounts'>
              <div>Technologies</div>
              <div className='right'>
                {country.nbInstalls != 0 ? addCommas(country.nbInstalls) : <i>Coming soon</i>}
              </div>
            </div>
          </div>
        </div>
      )
    }


  // Build Carousel items
  console.log("groupedCountries", groupedCountries)
  return (


    <Carousel slide={false}  controls={(groupedCountries.length) > 1} indicators={(groupedCountries.length) > 1}>
      {(groupedCountries.slice(0, 5)).map((countries, index) => {
        return (
          <Carousel.Item key={"carroussel"+index}>
            <div className='carouselItemContainer'>
              <div className='carouselItem'>
                {countries.map((country, index) => {
                  return getFlag(country, index)
                })}
              </div>
            </div>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}

export default class FlagsSplashScreen extends Component {
  constructor () {
    super()
    this.state = {
      sorting: 'Sites'
    }
  }

  getCountriesText (counts) {
    return     <div><span>Live counts for a selection of countries</span></div>
  }
  
  render () {

  return (
    <Modal dialogClassName='modal-dialog modal-dialog-centered modal-netfinderPlus' show={this.props.viewsplashscreen}>
      <Modal.Body>
        <div className='ss-logoNetFinder'>
          <img src={logoNetFinderPlus} alt='NetFinder+' />
          <div className='right'>
            <img src={xClose} alt="Close" id='xClose' onClick={() => this.props.closeSplashScreen(this.props.userID, false)} />
          </div>
        </div>
        <div className='ss-title'>
          {this.getCountriesText(this.props.counts)}
        </div>
        <div className='sortingSelectContainer'>
          <div className='sortingRight'>
        <select
            className='sortingSelect'
            name='range_type'
            onChange={(e) => {
                //this.props.sendNetfinderPlusSortingNotification(this.props.currentUser, e.target.value);
                this.setState({sorting: e.target.value});
              }
            }
          >
            <option value='Sites'>Sort by: Offices</option>
            <option value='Contacts'>Sort by: Contacts</option>
            <option value='Installs'>Sort by: Technologies</option>
          </select>
          </div>
        </div>

        <ControlledCarousel compteurs={this.props.counts} classement={this.state.sorting} />

        <div className='flagModalDemo'>
          {!this.props.emailNetfinderPlus_Sent && (
            <div>
              <div>
                NetFinder+ is extending its coverage, adding more Contacts at more
                <br />
                Offices in more Countries. Contact our team for more information.
              </div>
              <div>
                <button type='button' className='scheduleDemoBtn' onClick={() => this.props.sendNetfinderPlusNotification(this.props.currentUser)}>
                  Get started
                </button>
              </div>
            </div>
          )}
          {this.props.emailNetfinderPlus_Sent && <div>A NetFinder+ more informations request has been sent to the Rhetorik team. We will contact you shortly.</div>}
        </div>
        <div className='flagModalFooter'>
          <div className=''>
            <a onClick={() => this.props.closeSplashScreen(this.props.userID, true)}>Don't show again</a>
          </div>
          <div className='right'>Rhetorik &#169; {String(new Date().getFullYear())}</div>
        </div>
      </Modal.Body>
    </Modal>
  )
  }
}

FlagsSplashScreen.propTypes = {
  currentUser: PropTypes.object,
  closeSplashScreen: PropTypes.func,
  viewsplashscreen: PropTypes.bool,
  emailNetfinderPlus_Sent: PropTypes.bool,
  sendNetfinderPlusNotification: PropTypes.func,
  //sendNetfinderPlusSortingNotification: PropTypes.func,
}
