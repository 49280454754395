import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'downloads',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const Downloads = require('./containers/DownloadsContainer').default
        const reducer = require('./modules/downloads').default

        injectReducer(store, { key: 'downloads', reducer })

        /*  Return getComponent   */
        cb(null, Downloads)

        /* Webpack named bundle   */
      },
      'Downloads'
    )
  }
})
