import CoreLayout from '../layouts/CoreLayout'
import UnAuthLayout from '../layouts/UnAuthLayout'
import Login from './Login'
import Search from './Search'
import SiteDetails from './SiteDetails'
import SavedSearches from './SavedSearches'
import Admin from './Admin'
import UserDetails from './UserDetails'
import UserHistory from './UserHistory'
import Downloads from './Downloads'
import MarketShareReport from './MSIReport'
import Notifications from './Notifications'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import ComplianceCenter from './ComplianceCenter'
import CompanyDetails from './CompanyDetails'
import { UnAuthorize } from './PageNotFound'
import Authentication from 'containers/Authentication'
import Maintenance from './Maintenance'

export const createRoutes = store => [
  {
    path: '(/:searchId)',
    component: Authentication(CoreLayout),
    indexRoute: Search(store),
    childRoutes: [
      Admin(store),
      UserDetails(store),
      UserHistory(store),
      SiteDetails(store),
      SavedSearches(store),
      Downloads(store),
      Notifications(store),
      ComplianceCenter(store),
      MarketShareReport(store),
      CompanyDetails(store)
    ]
  },
  {
    path: '/',
    component: UnAuthLayout,
    childRoutes: [Login(store), ForgotPassword(store), ResetPassword(store), Maintenance(store)]
  },
  {
    path: '/unauthorized',
    component: Authentication(CoreLayout),
    indexRoute: UnAuthorize
  },
  {
    path: '*',
    component: Authentication(CoreLayout),
    indexRoute: Search(store)
  }
]
export default createRoutes
