export const enResults = {
  contact_total: 'Contacts with emails',
  city: 'City',
  company: 'Company',
  company_plural: 'Companies',
  emails: 'Contacts with emails',
  email_total: 'Contacts with emails',
  mobileddi: 'Contacts with Mobile/DDI',
  mobileddi_total: 'Contacts with Mobile/DDI',
  match: 'Match',
  matching_companies_sites: 'Matched Companies and Offices',
  matching_contacts: 'Matched Contacts',
  all_companies_sites: 'All Companies and Offices',
  all_contacts: 'All Contacts',
  name: 'Name',
  noSitesFound: 'No Offices Found',
  projects: 'Projects',
  presence: 'Presence',
  see_more: 'See More ...',
  site_total: 'Offices',
  siteCount: 'Site Count',
  total: 'Total',
  title_rollover: 'Only the offices matching your search criteria' +
  ' are listed in this table, grouped together by parent company.' +
  ' To see ALL the offices contained in a particular parent company, ' +
  'click on it to view the company details page',
  leads_title_rollover: 'Only the Contacts that match your serach criteria' +
  ' are listed here. to see all the contacts of a specific' +
  ' site or company, click in the company tab on' +
  ' the appropriate company to access the company deatils page.',
  install_total: 'Technologies',
  view_details: 'View details'
}
