import React, { Component } from 'react'
import _ from 'lodash'
import config from '../../config'

export default function (ComposedComponent) {
  class Authentication extends Component {

    componentDidMount () {
      
      if (!this.props.ValidateUserToken() && !this.props.router.isActive('/login')) {
        this.props.router.push('/login')
      }
      this.props.getMaintenanceStatus()

      let token = sessionStorage['access_token'] || localStorage.getItem('access_token')
      if (!_.isEmpty(token)) {
        if (
          this.props.router &&
          this.props.router.location &&
          this.props.router.location.pathname &&
          this.props.router.location.pathname == '/'
        ) {
          this.props.router.push('/')
        } else if (this.props.router && this.props.router.location && this.props.router.location.pathname) {
          let paths = this.props.router.location.pathname
            .substring(1, this.props.router.location.pathname.length)
            .split('/')
          if (paths && paths.length && config.CONSTANTS.VALID_ROUTES.indexOf(paths[0]) != -1) {
            this.props.router.push(this.props.router.location.pathname)
          }
        } else {
          this.props.router.push('/pagenotfound')
        }
      }
    }
    UNSAFE_componentWillUpdate (nextProps) {

      if (!nextProps.authenticated && !this.props.router.isActive('/login')) {
        this.props.router.push('/login')
      }

      if(this.props.viewMaintenancePage && !this.props.router.isActive('/maintenance')) {
        console.log("Maintenance redirect")
        this.props.router.push('/maintenance')
      }

    }

    render () {
      return <ComposedComponent {...this.props} />
    }
  }
  return Authentication
}
