import React from 'react'
import PropTypes from 'prop-types'
import 'font-awesome/scss/font-awesome.scss'
import './UnAuth.scss'

export const UnAuthLayout = ({ children }) => (
  <div className='app unauth-layout'>
    <div className='bluebackground'>{children}</div>
  </div>
)

UnAuthLayout.protoTypes = {
  children: PropTypes.element
}
export default UnAuthLayout
