import axios from 'axios'
import config from '../config'
import uuidv1 from 'uuid/v1'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'

const token = sessionStorage['access_token'] || localStorage.getItem('access_token')
const headers = { Pragma: 'no-cache' }

if (token) {
  headers['Token'] = token
}

var instance = axios.create({ baseURL: config.API.URL, headers })

instance.interceptors.response.use(
  function(response) {
    /* Used for Global 2.0 
    if (response.config.url.includes('getSearchOptions')) {
      response.data.result.site.search_options.location_country_code.options = [
        { GB: 'United Kingdom' },
        { FR: 'France' },
        { NL: 'Netherlands' },
        { BE: 'Belgium' },
        { IE: 'Ireland' },
        { LU: 'Luxembourg' },
        { DE: 'Germany' },
        { ES: 'Spain' },
        { PT: 'Portugal' },
        { IT: 'Italy' },
        { AT: 'Austria' },
        { CH: 'Switzerland' },
        { DK: 'Danemark' },
        { FI: 'Finland' },
        { SE: 'Sweden' },
        { PL: 'Poland' },
        { IL: 'Israel' },
        { NO: 'Norway' },
        { BG: 'Bulgaria'},
        { HR: 'Croatia'},
        { CZ: 'Czech Republic'},
        { EE: 'Estonia'},
        { GR: 'Greece'},
        { HU: 'Hungary'},
        { LV: 'Latvia'},
        { RO: 'Romania'},
        { SI: 'Slovenia'},
        { UA: 'UKraine'},
      ]
    } 
     FIN */
    if (response.data.status_code === config.CUSTOM_STATUS.UNAUTHORIZE) {
      sessionStorage.clear()
      localStorage.removeItem('access_token')
      localStorage.removeItem('logged_user')
      localStorage.removeItem('logged_user_role')
      window.location.href = '/login'
      return
    }
    return response
  },
  function(error) {

    const status = error.response ? error.response.status : null
    let originalRequest = error.config

    if (error.response && error.response.status === config.HTTP_STATUS.UNAUTHORIZE && token) {
      // sessionStorage.clear()
      // localStorage.removeItem('access_token')
      // localStorage.removeItem('logged_user')
      // localStorage.removeItem('logged_user_role')
      // window.location.href = '/login'
        console.log("401-UNAUTHORIZE ERROR :", error)
        return Promise.reject(error)
    } else if ( status === 500 ) {
        
        if(originalRequest.params) {
          if(originalRequest.params["retry"]) {
            let nb_retry = originalRequest.params["retry"]
            originalRequest.params["retry"] = nb_retry++;
          } else {
            originalRequest.params["retry"] = 1;
          }
        } else {
          let params = {}
          params["retry"] = 1
          originalRequest["params"] = params
        }
        if (originalRequest.params["retry"] < 3) {
          return _.delay((axios(originalRequest).catch(err => {})), 2000)
        } else {
          console.log("2 ATTEMPS FAILED - ERROR :", error)
          return Promise.reject(error)
        }
    } else if (
      _.includes(
        config.HTTP_STATUS.UNEXPECTED_ERRORS,
        _.get(error, 'response.status')
      )
    ) {
      toastr['error'](
        'Unexpected Error',
        'An unexpected error occurred while contacting the API, please try again by refreshing this page.',
        {
          showCloseButton: true,
          timeOut: 0,
          extendedTimeout: 0,
          removeOnHover: false,
          preventDuplicates: true,
          tapToDismiss: false,
          onCloseButtonClick: () => {
            window.location.reload()
          },
        }
      )
    }
    console.log('*** This is not called ***')
    return Promise.reject(error)
  }
)

instance.interceptors.request.use(function(request) {
  
  // For every request, a unique correlation-id will be
  // passed in request headers
  request.headers.correlationId = uuidv1();
  
  // We use a uniqueID param to ensure there is no cache on server
  // if(request.params) {
  //   request.params["uniqueID"] = _.random(1000000000);
  // }
  // else {
  //   let params = {}
  //   params["uniqueID"] = _.random(1000000000);
  //   request["params"] = params
  // }

  if (request.url === "https://maps.googleapis.com/maps/api/geocode/json") {
    delete request.headers.Pragma
    delete request.headers.Token
    delete request.headers.correlationId
    delete request.params.uniqueID
  }

  return request
})

export default instance
