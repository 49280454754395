export const formatBucketRange = numberString => {
  const numbers = numberString
    .trim()
    .split('-')
    .filter(string => string !== '')
  const valueArray = numbers.map(number => {
    const addMillions = number.length > 6 && number.length <= 9
    const addBillions = number.length > 9
    // Handle the case where we have 9999999 ... - round it up
    number = addMillions || addBillions ? (parseInt(number) + 1).toString() : number
    number = addMillions ? number.slice(0, number.length - 6) : number
    number = addBillions ? number.slice(0, number.length - 9) : number
    number = addCommas(number)
    number = addMillions ? number + 'M' : number
    number = addBillions ? number + 'B' : number
    return number
  })
  if (numberString[0] === '-') return 'Less than ' + valueArray[0]
  if (numberString[numberString.length - 1] === '-') return 'Greater than ' + valueArray[0]
  return valueArray.join(' - ')
}

export const addCommas = nStr => {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const addSpaces = num => {
  if (num) {
  var str = num.toString().split('.');
  if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  }
  if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}else{
  return "0"
}
}


export const getDomainFromUrl = url => {
  var psl = require('psl')
  var parsed
  if (url) {
  parsed = psl.parse(extractHostname(url))
  return parsed.domain
}
else {
  return null
}
}

function extractHostname (url) {
  var hostname
  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url) {
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2]
    } else {
      hostname = url.split('/')[0]
    }

    // find & remove port number
    hostname = hostname.split(':')[0]
    // find & remove "?"
    hostname = hostname.split('?')[0]

    return hostname
  } else {
    return ''
  }
}
