import React from 'react'
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip'
import {Popover, OverlayTrigger} from 'react-bootstrap'

import {getCountryName} from '../../../lib/countriesUtils'
import { getFlagLogo } from '../../../lib/flagUtils'


export const sortableCompanyTableColumns = {
  /* These strings are passed to the API to let it
  know on which column it should apply the sort. */
  sites: 'site_total',
  contacts: 'contact_total',
  technologies: 'install_total',
  emails: 'email_total',
  mobileddi: 'mobileddi_total',
}

export const getCellWithEllipsis = (text) => {
  return (
    <div data-test='cell-with-ellipsis'>
      <EllipsisWithTooltip placement='bottom'
        className='cell-with-ellipsis'>
        {text}
      </EllipsisWithTooltip>
    </div>
  )
}

export const getCompanyName = (row) => {
  if (!row) return ''
  return getCellWithEllipsis(<a className='company-name'>{row}</a>)
}

export const getCompanyNameNoLink = (row, cell) => {
  if (!row) return ''
  return getCellWithEllipsis(<OverlayTrigger placement="right" overlay={popoverTop(cell)}><span className='company-name'>{row}</span></OverlayTrigger>)
}


export const TABLE_ITEMS = 10

export const viewDetails = (id, router, path) => {
  router.push(`/${path}/${id}`)
}

export const tableLocation = (cell, row) => {
  return (
    cell ? cell.city : 'unknown'
  )
}

export const tableCountry = (cell, row) => {
  return (
    cell ? getCountryName(cell.country_code) : 'unknown'
  )
}

const popoverTop = (cell) => {

  const flag = getFlagLogo(cell.location.country_code)
  const countryName = getCountryName(cell.location.country_code)
  const title = <span><img src={flag} className='flag-logo' alt={countryName} />{countryName} could be added to your license.</span>

  return (  <Popover id="popover-positioned-top" className="country_popover"
  title={title}>
    You can expand your marketing reach by adding countries.<br />
    Please contact our sales team to get a quote at <a href='mailto:sales@rhetorik.com' target='_blank' className='whatsnew_link'>sales@rhetorik.com</a>.
  </Popover>
)  
};
