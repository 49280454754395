import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'userdetails/:userId',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const UserDetails = require('./containers/UserDetailsContainer').default
        const reducer = require('./modules/userdetails').default

        injectReducer(store, { key: 'userdetails', reducer })

        /*  Return getComponent   */
        cb(null, UserDetails)

        /* Webpack named bundle   */
      },
      'UserDetails'
    )
  }
})
