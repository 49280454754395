export const enDetails = {
  company_dnc_message1: 'This organisation has expressed a preference for NO TELE-MARKETING:',
  company_dnc_message2:' Do not contact with unsolicited sales and marketing telephone calls.',
  global_employees: 'Employees Globally',
  email_opt_in_rollover: 'Local e-privacy legislation requires prior consent for email marketing to this contact. ' +
  'Please visit the Compliance Center section for more details.',
  emps_in_country: 'Employees in Country',
  emps_on_site: 'Employees in Office',
  linkedin: 'LinkedIn Profile',
  nature_of_business: 'Nature of Business',
  pcs_in_country: 'PCs in Country',
  pcs_on_site: 'PCs on Site',
  restricted_contact: 'Restricted Contact',
  restricted_contact_plural: 'Restricted Contacts',
  restricted_contacts_message: 'The restrictions to your account do not permit you to view the details for this contact.',
  restricted_contacts_message_plural: 'The restrictions to your account do not permit you to view the details for these contacts.',
  site: 'Site',
  site_type: 'Office Type',
  site_dnc_popover1: 'Under GDPR and other National Directives, organisations have the right' +
  ' to notify their direct marketing preferences, which can cover Email, Postal and Tele-Marketing.' +
  ' It is a legal requirement that companies do not conduct email, postal or tele-marketing' +
  ' to those organisations who have exercised their right to not receive such direct marketing.',
  site_dnc_popover2:' Rhetorik provides insight into such preferences where it has received direct or indirect knowledge of them.' +
  ' We encourage our clients to download national Do Not Call lists to add to Rhetorik’s insight.',
  telephone_dnc_rollover: 'This telephone number is registered' +
  ' on a national Do Not Call list, do not contact with unsolicited sales ' +
  'and marketing telephone calls.',
  telephone_opt_in_rollover: 'Local e-privacy legislation requires prior consent for telephone marketing to this contact. ' +
  'Please visit the Compliance Center section for more details.',
  telephone_opt_in_rollover_site: 'Local e-privacy legislation requires prior consent for telephone marketing to this office. ' +
  'Please visit the Compliance Center section for more details.',
  vertical_market: 'Vertical Market'
}
