import { injectReducer } from '../../store/reducers'

export default store => ({
  path: 'userhistory/:userId',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const UserHistory = require('./containers/UserHistoryContainer').default
        const reducer = require('./modules/userhistory').default

        injectReducer(store, { key: 'userhistory', reducer })

        /*  Return getComponent   */
        cb(null, UserHistory)

        /* Webpack named bundle   */
      },
      'UserHistory'
    )
  }
})
