import React, { Component } from 'react'
import { Navbar, Nav, OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
import locales from '../../locales/locales.js'
import PropTypes from 'prop-types'
import NewAlertLabel from './NewAlertLabel'
import logo from '../../../public/assets/images/NetfinderPlusLogo.png'
import x_white from '../../../public/assets/images/x_white.png'
import information from '../../../public/assets/images/information.svg'
import information_button from '../../../public/assets/images/information-button.png'
import { getNbCountries } from '../../lib/ExploreNowUtils'


class Header extends Component {
  componentDidMount () {
    if (this.props && this.props.GetNotifications) {
      this.props.GetNotifications()
    }
  }

  getNavIcon (translationKey, iconClass) {
    return (
      <OverlayTrigger
        data-test='vdi-with-overlay'
        key={locales.t(translationKey)}
        placement='bottom'
        overlay={
          <Tooltip className='header' id={locales.t(translationKey)}>
            {locales.t(translationKey)}
          </Tooltip>
        }
      >
        <i className={iconClass} />
      </OverlayTrigger>
    )
  }

  getNavItem (path, translationKey, iconClass, newAlertLabel = false, disabled = false) {
    if(disabled) {
      return (
        <span>
          {this.getNavIcon(translationKey, iconClass)}
          <span className='main-nav-text'>{locales.t(translationKey)}</span>
          {newAlertLabel && <NewAlertLabel />}
        </span>
      )
    }
    else {
    return (
      <a
        onClick={() => {
          this.props.router.push(path)
        }}
        data-test='nav-item'
      >
        {this.getNavIcon(translationKey, iconClass)}
        <span className='main-nav-text'>{locales.t(translationKey)}</span>
        {newAlertLabel && <NewAlertLabel />}
      </a>
    )}
  }

  getBadgeNavItem (path, translationKey, iconClass, badgeCount) {
    return (
      <a
        onClick={() => {
          this.props.router.push(path)
        }}
        data-test='nav-link'
      >
        {badgeCount ? (
          <span className='fa-stack has-badge' data-count={badgeCount} data-test='badge-count'>
            {this.getNavIcon(translationKey, iconClass)}
          </span>
        ) : (
          this.getNavIcon(translationKey, iconClass)
        )}
        <span className='main-nav-text'>{locales.t(translationKey)}</span>
      </a>
    )
  }

getHeaderText (counts) {

  return 'Expand your sales and marketing strategies' +
   (counts ? ' across ' + getNbCountries(counts) + ' countries.' : '')
}

  getSplashScreenOffset () {
    return (localStorage.getItem('access_token') || sessionStorage['access_token']) && this.props.viewExploreNow
      ? 'navbar navbar-fixed-top fifty-px-offset'
      : 'navbar navbar-fixed-top'
  }

  render () {

    const popoverTop = () => {

      const title = <span><img src={information_button} className='flag-logo' alt='Market Analytics' />Market Analytics</span>
    
      return (  
        <Popover id="popover-positioned-top" className="country_popover" title={title}>
          We are currently experiencing difficulties in the generation of the data necessary for Market Analytics.<br />
          We are working on the problem to make it available as soon as possible.
        </Popover>
    )  
    };
    

    return localStorage.getItem('access_token') || sessionStorage['access_token'] ? (
      <React.Fragment>
        <div id='header' className='hold-transition skin-black-light sidebar-mini' data-test='nav-header'>
          <div className='main-header'>
    
    
            {this.props.viewExploreNow && (
              <div className='navbar navbar-default navbar-fixed-top exploreNowContainer'>
                <div className=' exploreNow'>
                <div className='exploreNowLeftSection'>
                    <img src={information} className='exploreNowInfo' alt='Information' />
                    <div className='exploreNowText'>
                      <div><b>{'With NetFinder+'}</b></div>
                      <div>{this.getHeaderText(this.props.counts)}</div>
                    </div>
                </div>
                <div className='exploreNowRightSection'>
                  <button type='button' className='exploreNowBtn' onClick={() => this.props.openSplashScreen()}>
                    Explore Now!
                  </button>
                  <img src={x_white} className='exploreNowClose' alt='Close' onClick={() => this.props.toggleExploreNow(false)} />
                  {/* <span className='exploreNowClose' onClick={() => this.props.toggleExploreNow(false)}>X</span> */}
                </div>
              </div>
              </div>
            )}

            <Navbar className={this.getSplashScreenOffset()}>
              <Navbar.Header>
                <Navbar.Brand>
                  <a href='/' className='logo'>
                    <span className='logo-lg'>
                      <img src={logo} className='brand-logo' alt='User Image' />
                    </span>
                  </a>
                </Navbar.Brand>
              </Navbar.Header>
              {localStorage.getItem('access_token') || sessionStorage['access_token'] ? (
                <Nav className={this.getSplashScreenOffset()}>
                  <div className='navbar-custom-menu'>
                    <ul className='nav navbar-nav'>
                      {/* <OverlayTrigger placement="bottom" overlay={popoverTop()}>
                        <li data-test='msi-link' className='msi-link'  >{this.getNavItem('/MarketShareReport', 'msiReport', 'fa fa-pie-chart', false, true)}</li>
                      </OverlayTrigger> */}
                      <li data-test='msi-link' >{this.getNavItem('/MarketShareReport', 'msiReport', 'fa fa-pie-chart')}</li>
                      <li>{this.getNavItem('/compliancecenter', 'complianceCentre', 'fa fa-check')}</li>
                      <li data-test='admin-link'>{this.props.isAdmin && this.getNavItem('/admin', 'admin', 'fa fa-cog')}</li>
                      <li>{this.getNavItem('/', 'search', 'fa fa-search')}</li>
                      <li>{this.getNavItem('/savedsearches', 'savedSearches', 'fa fa-floppy-o')}</li>
                      <li>{this.getNavItem('/downloads', 'downloads', 'fa fa-download')}</li>
                      <li>{this.getBadgeNavItem('/notifications', 'notifications', 'fa fa-bell', this.props.notificationsCount)}</li>
                      <li className='user'>
                        <a id='usernameAnchor' onClick={this.props.SideBarClick}>
                          <span className='profile-nav-text'>
                            <span className='user-name'>{this.props.userName}</span>
                            <i className='fa fa-angle-down' />
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Nav>
              ) : null}
            </Navbar>
          </div>
        </div>
      </React.Fragment>
    ) : null
  }
}

Header.propTypes = {
  SideBarClick: PropTypes.func,
  GetNotifications: PropTypes.func,
  userName: PropTypes.string,
  notificationsCount: PropTypes.number,
  isAdmin: PropTypes.number,
  router: PropTypes.object,
  openSplashScreen: PropTypes.func,
  toggleExploreNow: PropTypes.func,
  viewExploreNow: PropTypes.bool
}

export default Header
