import React, { Component, PropTypes } from 'react'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import '../../scss/style.scss'
import ReactGA from 'react-ga4'
import { env } from '../../project.config'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'

const production = env === 'production'

class AppContainer extends Component {
  /*
  * Initialize React GA (google analytics) when in production mode
  */

  fireGATracking = () => {
    if (!this.GAInitialized) {
      ReactGA.initialize('G-V7G0VF6XN7')
      this.GAInitialized = true
    }
    if (!production) {
      ReactGA.gtag('config', { 'debug_mode': true })
    }
    ReactGA.send('pageview')
  }

  render () {
    const { routes, store } = this.props

    return (
      <Provider store={store}>
        <ErrorBoundary>
          <div className='main-route-div'>
            <Router onUpdate={this.fireGATracking} history={browserHistory} children={routes} />
          </div>
        </ErrorBoundary>
      </Provider>
    )
  }
}

export default AppContainer
