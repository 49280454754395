export default store => ({
  path: 'compliancecenter',
  getComponent (nextState, cb) {
    require.ensure(
      [],
      require => {
        const ComplianceCenter = require('./components/ComplianceCenter').default

        /*  Return getComponent   */
        cb(null, ComplianceCenter)

        /* Webpack named bundle   */
      },
      'ComplianceCenter'
    )
  }
})
