import React from 'react'
import {
  Radio,
  Checkbox,
  ControlLabel,
  FormControl,
  OverlayTrigger,
  Popover,
  Tooltip
} from 'react-bootstrap'
import CheckboxBtn from './CheckBoxBtn';
import RadioBoxBtn from './RadioBoxBtn';
import { getFlagLogo } from '../../lib/flagUtils'

const popoverTop = (country_option, option_type) => {

  const flag = getFlagLogo(country_option.value)
  const title = (option_type === 'filter') ?
            <span><img src={flag} className='flag-logo' alt={country_option.label} /> {country_option.label} could be added to your license.</span>
            :
            <span>{country_option.label} could be added to your license.</span>
  return (
       <Popover id="popover-positioned-top" className="country_popover"  title={title}>
          You can expand your marketing reach by adding {(option_type === 'filter') ? 'countries' : 'continents'}.<br />
          Please contact our sales team to get a quote at <a href='mailto:sales@rhetorik.com' target='_blank' className='whatsnew_link'>sales@rhetorik.com</a>.
        </Popover>
        )
};


export default function RenderInput (props, keepClickValue) {
  const { indeterminate, ...propsForChildren } = props

  const labelWithTooltip =
      props.tooltip
      ? <OverlayTrigger
        data-test='input-tooltip'
        placement='bottom'
        overlay={
          <Tooltip className='input-tooltip' id={props.name}>
            {props.tooltip}
          </Tooltip>
        }
      >
        <ControlLabel>{props.label}</ControlLabel>
      </OverlayTrigger>
      : <ControlLabel>{props.label}</ControlLabel>

  const checkBoxesForLocationFilter =
  <div className='search-checkbox'>
    <Checkbox {...propsForChildren}>
      <span className='proxy-checkbox'>
        {props.indeterminate ? (
          <i className='state-icon fa exclude fa-minus-square' />
        ) : props.checked ? (
          <i className='state-icon fa include fa-check-square' />
        ) : (
          <i className='state-icon fa fa-square-o' />
        )}
      </span>
      {props.label}
    </Checkbox>
    <div className='clear' />
  </div>

  switch (props.type) {
    case 'checkbox':
      return (
        <div>
          <Checkbox {...props}>{props.label}</Checkbox>
        </div>
      )
    case 'custom-checkbox':
      return (
        <CheckboxBtn key={ props.itemId } propsForChildren = { propsForChildren } data = { props }/>
      )
    case 'custom-checkbox-with-tooltip':
      if (props.item_tooltip.disabled) {
        return (
          <OverlayTrigger placement="bottom" overlay={popoverTop(props.item_tooltip, props.type_tooltip)}>
            {checkBoxesForLocationFilter}
          </OverlayTrigger>
        )
      } else return checkBoxesForLocationFilter

    case 'large-custom-checkbox':
      return (
        <div className='search-checkbox'>
          <Checkbox {...propsForChildren}>
            <span className='proxy-checkbox'>
              {props.checked ? (
                <i className='state-icon fa fa-check-square fa-lg' name='custom-checkbox-checked' />
              ) : (
                <i className='state-icon fa fa-square-o fa-lg' name='custom-checkbox-unchecked' />
              )}
            </span>
            {props.label}
          </Checkbox>
          <div className='clear' />
        </div>
      )
    case 'deactivate-checkbox':
      return (
        <div className='search-checkbox'>
          <Checkbox {...propsForChildren}>
            <span className='proxy-checkbox'>
              {props.checked
                ? <i className='state-icon fa fa-minus-square fa-lg' />
                : <i className='state-icon fa fa-square-o fa-lg' />}
            </span>
            {props.label}
          </Checkbox>
          <div className='clear' />
        </div>
      )

    case 'radio':
      return (
        <RadioBoxBtn
          propsForChildren={propsForChildren}
          data={props} />
      )
    default:
      return (
        <div>
          {labelWithTooltip}
          <FormControl {...propsForChildren} />
        </div>
      )
  }
}
