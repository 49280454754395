import _ from "lodash";

const allContinents = [
  {
    continent_code: "EU",
    continent_name: "Europe",
    order: "1",
    disabled: false
  },
  {
    continent_code: "NA",
    continent_name: "North America",
    order: "2",
    disabled: false
  },
  {
    continent_code: "AS",
    continent_name: "Asia",
    order: "3",
    disabled: false
  },
  {
    continent_code: "SA",
    continent_name: "South America",
    order: "4",
    disabled: false
  },
  {
    continent_code: "AF",
    continent_name: "Africa",
    order: "5",
    disabled: false
  },
  {
    continent_code: "OC",
    continent_name: "Oceania",
    order: "6",
    disabled: true
  },
  {
    continent_code: "AN",
    continent_name: "Antarctica",
    order: "7",
    disabled: true
  }
];

const allCountries = [
  {
    order: 4,
    country_code: "AF",
    country_name: "Afghanistan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "AX",
    country_name: "Aland Islands",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "AL",
    country_name: "Albania",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "DZ",
    country_name: "Algeria",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "AS",
    country_name: "American Samoa",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "AD",
    country_name: "Andorra",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "AO",
    country_name: "Angola",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "AI",
    country_name: "Anguilla",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "AQ",
    country_name: "Antarctica",
    continent_code: "AN",
    continent_name: "Antarctica"
  },
  {
    order: 4,
    country_code: "AG",
    country_name: "Antigua and Barbuda",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "AR",
    country_name: "Argentina",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "AM",
    country_name: "Armenia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "AW",
    country_name: "Aruba",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "AU",
    country_name: "Australia",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "AT",
    country_name: "Austria",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "AZ",
    country_name: "Azerbaijan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "BS",
    country_name: "Bahamas",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "BH",
    country_name: "Bahrain",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "BD",
    country_name: "Bangladesh",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "BB",
    country_name: "Barbados",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "BY",
    country_name: "Belarus",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "BE",
    country_name: "Belgium",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "BZ",
    country_name: "Belize",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "BJ",
    country_name: "Benin",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "BM",
    country_name: "Bermuda",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "BT",
    country_name: "Bhutan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "BO",
    country_name: "Bolivia",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "BQ",
    country_name: "Bonaire, Sint Eustatius and Saba",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "BW",
    country_name: "Botswana",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "BV",
    country_name: "Bouvet Island",
    continent_code: "AN",
    continent_name: "Antarctica"
  },
  {
    order: 4,
    country_code: "BR",
    country_name: "Brazil",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "IO",
    country_name: "British Indian Ocean Territory",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "BN",
    country_name: "Brunei Darussalam",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "BG",
    country_name: "Bulgaria",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "BF",
    country_name: "Burkina Faso",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "BI",
    country_name: "Burundi",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "KH",
    country_name: "Cambodia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "CM",
    country_name: "Cameroon",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "CA",
    country_name: "Canada",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "CV",
    country_name: "Cape Verde",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "KY",
    country_name: "Cayman Islands",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "CF",
    country_name: "Central African Republic",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "TD",
    country_name: "Chad",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "CL",
    country_name: "Chile",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "CN",
    country_name: "China",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "CX",
    country_name: "Christmas Island",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "CC",
    country_name: "Cocos (Keeling) Islands",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "CO",
    country_name: "Colombia",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "KM",
    country_name: "Comoros",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "CG",
    country_name: "Congo",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "CD",
    country_name: "Congo, Democratic Republic of the Congo",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "CK",
    country_name: "Cook Islands",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "CR",
    country_name: "Costa Rica",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "CI",
    country_name: "Cote D'Ivoire",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "HR",
    country_name: "Croatia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "CU",
    country_name: "Cuba",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "CW",
    country_name: "Curacao",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "CY",
    country_name: "Cyprus",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "CZ",
    country_name: "Czechia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "DK",
    country_name: "Denmark",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "DJ",
    country_name: "Djibouti",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "DM",
    country_name: "Dominica",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "DO",
    country_name: "Dominican Republic",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "EC",
    country_name: "Ecuador",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "EG",
    country_name: "Egypt",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "SV",
    country_name: "El Salvador",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "ER",
    country_name: "Eritrea",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "EE",
    country_name: "Estonia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "ET",
    country_name: "Ethiopia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "FK",
    country_name: "Falkland Islands (Malvinas)",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "FO",
    country_name: "Faroe Islands",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "FJ",
    country_name: "Fiji",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "FI",
    country_name: "Finland",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 3,
    country_code: "FR",
    country_name: "France",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "GF",
    country_name: "French Guiana",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "PF",
    country_name: "French Polynesia",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "TF",
    country_name: "French Southern Territories",
    continent_code: "AN",
    continent_name: "Antarctica"
  },
  {
    order: 4,
    country_code: "GA",
    country_name: "Gabon",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "GM",
    country_name: "Gambia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "GE",
    country_name: "Georgia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 2,
    country_code: "DE",
    country_name: "Germany",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "GH",
    country_name: "Ghana",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "GI",
    country_name: "Gibraltar",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "GR",
    country_name: "Greece",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "GL",
    country_name: "Greenland",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "GD",
    country_name: "Grenada",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "GP",
    country_name: "Guadeloupe",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "GU",
    country_name: "Guam",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "GT",
    country_name: "Guatemala",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "GG",
    country_name: "Guernsey",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "GN",
    country_name: "Guinea",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "GW",
    country_name: "Guinea-Bissau",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "GY",
    country_name: "Guyana",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "HT",
    country_name: "Haiti",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "HM",
    country_name: "Heard Island and Mcdonald Islands",
    continent_code: "AN",
    continent_name: "Antarctica"
  },
  {
    order: 4,
    country_code: "VA",
    country_name: "Holy See (Vatican City State)",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "HN",
    country_name: "Honduras",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "HK",
    country_name: "Hong Kong",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "HU",
    country_name: "Hungary",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "IS",
    country_name: "Iceland",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "IN",
    country_name: "India",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "ID",
    country_name: "Indonesia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "IR",
    country_name: "Iran, Islamic Republic of",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "IQ",
    country_name: "Iraq",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 1,
    country_code: "IE",
    country_name: "Ireland",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "IM",
    country_name: "Isle of Man",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "IL",
    country_name: "Israel",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "IT",
    country_name: "Italy",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "JM",
    country_name: "Jamaica",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "JP",
    country_name: "Japan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "JE",
    country_name: "Jersey",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "JO",
    country_name: "Jordan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "KZ",
    country_name: "Kazakhstan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "KE",
    country_name: "Kenya",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "KI",
    country_name: "Kiribati",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "KP",
    country_name: "Korea, Democratic People's Republic of",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "KR",
    country_name: "Korea, Republic of",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "XK",
    country_name: "Kosovo",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "KW",
    country_name: "Kuwait",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "KG",
    country_name: "Kyrgyzstan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "LA",
    country_name: "Lao People's Democratic Republic",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "LV",
    country_name: "Latvia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "LB",
    country_name: "Lebanon",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "LS",
    country_name: "Lesotho",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "LR",
    country_name: "Liberia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "LY",
    country_name: "Libyan Arab Jamahiriya",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "LI",
    country_name: "Liechtenstein",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "LT",
    country_name: "Lithuania",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "LU",
    country_name: "Luxembourg",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "MO",
    country_name: "Macao",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "MK",
    country_name: "Macedonia, the Former Yugoslav Republic of",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "MG",
    country_name: "Madagascar",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MW",
    country_name: "Malawi",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MY",
    country_name: "Malaysia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "MV",
    country_name: "Maldives",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "ML",
    country_name: "Mali",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MT",
    country_name: "Malta",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "MH",
    country_name: "Marshall Islands",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "MQ",
    country_name: "Martinique",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "MR",
    country_name: "Mauritania",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MU",
    country_name: "Mauritius",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "YT",
    country_name: "Mayotte",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MX",
    country_name: "Mexico",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "FM",
    country_name: "Micronesia, Federated States of",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "MD",
    country_name: "Moldova, Republic of",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "MC",
    country_name: "Monaco",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "MN",
    country_name: "Mongolia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "ME",
    country_name: "Montenegro",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "MS",
    country_name: "Montserrat",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "MA",
    country_name: "Morocco",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MZ",
    country_name: "Mozambique",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "MM",
    country_name: "Myanmar",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "NA",
    country_name: "Namibia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "NR",
    country_name: "Nauru",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "NP",
    country_name: "Nepal",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "NL",
    country_name: "Netherlands",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "AN",
    country_name: "Netherlands Antilles",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "NC",
    country_name: "New Caledonia",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "NZ",
    country_name: "New Zealand",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "NI",
    country_name: "Nicaragua",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "NE",
    country_name: "Niger",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "NG",
    country_name: "Nigeria",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "NU",
    country_name: "Niue",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "NF",
    country_name: "Norfolk Island",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "MP",
    country_name: "Northern Mariana Islands",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "NO",
    country_name: "Norway",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "OM",
    country_name: "Oman",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "PK",
    country_name: "Pakistan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "PW",
    country_name: "Palau",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "PS",
    country_name: "Palestinian Territory, Occupied",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "PA",
    country_name: "Panama",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "PG",
    country_name: "Papua New Guinea",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "PY",
    country_name: "Paraguay",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "PE",
    country_name: "Peru",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "PH",
    country_name: "Philippines",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "PN",
    country_name: "Pitcairn",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "PL",
    country_name: "Poland",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "PT",
    country_name: "Portugal",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "PR",
    country_name: "Puerto Rico",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "QA",
    country_name: "Qatar",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "RE",
    country_name: "Reunion",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "RO",
    country_name: "Romania",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "RU",
    country_name: "Russian Federation",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "RW",
    country_name: "Rwanda",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "BL",
    country_name: "Saint Barthelemy",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "SH",
    country_name: "Saint Helena",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "KN",
    country_name: "Saint Kitts and Nevis",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "LC",
    country_name: "Saint Lucia",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "MF",
    country_name: "Saint Martin",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "PM",
    country_name: "Saint Pierre and Miquelon",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "VC",
    country_name: "Saint Vincent and the Grenadines",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "WS",
    country_name: "Samoa",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "SM",
    country_name: "San Marino",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "SA",
    country_name: "Saudi Arabia",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "SN",
    country_name: "Senegal",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "RS",
    country_name: "Serbia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "CS",
    country_name: "Serbia and Montenegro",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "SC",
    country_name: "Seychelles",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "SL",
    country_name: "Sierra Leone",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "SG",
    country_name: "Singapore",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "SX",
    country_name: "Sint Maarten",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "SK",
    country_name: "Slovakia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "SI",
    country_name: "Slovenia",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "SB",
    country_name: "Solomon Islands",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "SO",
    country_name: "Somalia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "ZA",
    country_name: "South Africa",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "GS",
    country_name: "South Georgia and the South Sandwich Islands",
    continent_code: "AN",
    continent_name: "Antarctica"
  },
  {
    order: 4,
    country_code: "SS",
    country_name: "South Sudan",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "ES",
    country_name: "Spain",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "LK",
    country_name: "Sri Lanka",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "SD",
    country_name: "Sudan",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "SR",
    country_name: "Suriname",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "SJ",
    country_name: "Svalbard and Jan Mayen",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "SZ",
    country_name: "Swaziland",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "SE",
    country_name: "Sweden",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "CH",
    country_name: "Switzerland",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "SY",
    country_name: "Syrian Arab Republic",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TW",
    country_name: "Taiwan, Province of China",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TJ",
    country_name: "Tajikistan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TZ",
    country_name: "Tanzania, United Republic of",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "TH",
    country_name: "Thailand",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TL",
    country_name: "Timor-Leste",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TG",
    country_name: "Togo",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "TK",
    country_name: "Tokelau",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "TO",
    country_name: "Tonga",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "TT",
    country_name: "Trinidad and Tobago",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "TN",
    country_name: "Tunisia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "TR",
    country_name: "Turkey",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TM",
    country_name: "Turkmenistan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "TC",
    country_name: "Turks and Caicos Islands",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "TV",
    country_name: "Tuvalu",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "UG",
    country_name: "Uganda",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "UA",
    country_name: "Ukraine",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "AE",
    country_name: "U.A.E",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 0,
    country_code: "GB",
    country_name: "United Kingdom",
    continent_code: "EU",
    continent_name: "Europe"
  },
  {
    order: 4,
    country_code: "US",
    country_name: "United States",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "UY",
    country_name: "Uruguay",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "UZ",
    country_name: "Uzbekistan",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "VU",
    country_name: "Vanuatu",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "VE",
    country_name: "Venezuela",
    continent_code: "SA",
    continent_name: "South America"
  },
  {
    order: 4,
    country_code: "VN",
    country_name: "Viet Nam",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "VG",
    country_name: "Virgin Islands, British",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "VI",
    country_name: "Virgin Islands, U.s.",
    continent_code: "NA",
    continent_name: "North America"
  },
  {
    order: 4,
    country_code: "WF",
    country_name: "Wallis and Futuna",
    continent_code: "OC",
    continent_name: "Oceania"
  },
  {
    order: 4,
    country_code: "EH",
    country_name: "Western Sahara",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "YE",
    country_name: "Yemen",
    continent_code: "AS",
    continent_name: "Asia"
  },
  {
    order: 4,
    country_code: "ZM",
    country_name: "Zambia",
    continent_code: "AF",
    continent_name: "Africa"
  },
  {
    order: 4,
    country_code: "ZW",
    country_name: "Zimbabwe",
    continent_code: "AF",
    continent_name: "Africa"
  }
];

export function getAllContinents() {
  const unique = [...new Set(allCountries.map(item => item.continent_name))];
  return unique;
}

export function getAllContinentsWithCode() {
  return [...new Set(allContinents.map(item => {
    return {
      continent_name: item.continent_name,
      continent_code: item.continent_code,
    }
  }))];
}

export function getAllCountries() {
  const unique = [...new Set(allCountries.map(item => item.country_name))];
  return unique;
}

export function getAllCountriesWithCodes() {

const orderedCountries = _.sortBy(allCountries, ["order"]);

  const unique = [...new Set(orderedCountries.map(item => {return {country_code: item.country_code, country_name: item.country_name}}))];
  return unique;
}

export function getContinentName(code) {
  return allContinents.find(item => item.continent_code === code).continent_name;
}

export function getCountryName(code) {
  if (code !== "ALL")
    return allCountries.find(item => item.country_code === code).country_name;
  else return "";
}

export function getCountryNameSVGCoded(code) {
  if (code !== "ALL")
    return allCountries.find(item => item.country_code === code).country_name.replaceAll('.', '').replaceAll(' ', '-').toLowerCase();
  else return "";
}

export function getAllContinentsOptions() {
  const sortedUnique = _.sortBy(allContinents, ["disabled","order"]).map(item => {
    return {
      value: item.continent_code,
      label: item.continent_name,
      disabled: item.disabled
    };
  });
  return sortedUnique;
}

export function getUserContinents(availableCountries) {

  const unique = allContinents.filter(item => isCountriesAvailableInContinent(item.continent_code, availableCountries))
  const sortedUnique = _.sortBy(unique, ["order"]).map(item => {
      return {
          [item.continent_code]: item.continent_name
      };
  });
  return sortedUnique;
}


export function getUserContinentsOptions(enabledCountries, availableCountries) {

    const unique = allContinents.filter(item => isCountriesAvailableInContinent(item.continent_code, availableCountries))

    const sortedUnique = _.sortBy(unique, ["disabled","order"]).map(item => {
        return {
            value: item.continent_code,
            label: item.continent_name,
            disabled: !isCountriesEnabledInContinent(item.continent_code, enabledCountries)
        };
    });
    return sortedUnique;
  }


export function getAllCountriesOptions(enabledCountries) {
  const unique = allCountries.map(item => {
    return {
      value: item.country_code,
      label: item.country_name,
      disabled: !enabledCountries.includes(item.country_code)
    };
  });
  const sortedUnique = _.sortBy(unique, ["disabled", "country_name"]);
  sortedUnique.unshift({
    value: "ALL",
    label: "All licensed countries",
    disabled: false
  });
  return sortedUnique;
}

export function getCountriesOptionsByContinent(continentCode, enabledCountries, addSelectAllOption = true, addAllCountries = true) {

    const unique = allCountries.filter(item => (item.continent_code === continentCode) &&  (addAllCountries || (enabledCountries.includes(item.country_code)) )  ).map(item => {

            return   {
                value: item.country_code,
                label: item.country_name,
                disabled: !enabledCountries.includes(item.country_code),
                order: item.order,
            };
      });
      const sortedUnique = _.sortBy(unique, ["order", "disabled", "country_name"]);
      if(addSelectAllOption) {
        sortedUnique.unshift({
          value: "ALL",
          label: "All licensed countries in " + getContinentName(continentCode),
          disabled: false
        });
      }

      return sortedUnique;
}

export function getCountriesByContinent(continentCode) {
  return _.sortBy(allCountries, ["order"]).filter(item => item.continent_code === continentCode).map(item => {
    return       item.country_code;
  });
}

export function isCountriesAvailableInContinent(continentCode, availableCountries) {
    return allCountries.filter(item => item.continent_code === continentCode && availableCountries.includes(item.country_code)).map(item => {
      return       item.country_code;
    }).length > 0;
}

export function isCountriesEnabledInContinent(continentCode, enabledCountries) {
    return allCountries.filter(item => item.continent_code === continentCode && enabledCountries.includes(item.country_code)).map(item => {
      return       item.country_code;
    }).length > 0;
}
