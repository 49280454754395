import _ from 'lodash'

// Create the searchOptions Object
export const createSearchOptionsObject = (searchOptionsData, APIcounts) => {
  sessionStorage.setItem('searchOptions', JSON.stringify(searchOptionsData.install.taxonomy))
  let searchOptionsObject = _.pickBy(searchOptionsData,
    function (o) { return o.search_options })
  _.forEach(searchOptionsObject, function (value, key) {
    // Remove the unnecessary search_options property (flatten the structure a bit)
    searchOptionsObject[key] = searchOptionsObject[key].search_options
  })

  // Loop through the Search Options Object and:
  _.forEach(searchOptionsObject, function (value, levelOneKey) {
    _.forEach(value, function (value, levelTwoKey) {
      // During reducer replacement (switching pages) createSearchOptionsObject
      // is called with already processed data - don't process counts info if already there.
      if (['count', 'tech_code_counts', 'total'].includes(levelTwoKey)) { return }

      // Add the api string key for each search option
      let objectModuleData = searchOptionsObject[levelOneKey][levelTwoKey]
      objectModuleData['apiString'] = (levelOneKey + '.' + levelTwoKey)

      // If we need to do any special processing for a type of module - do it here:

      // For site.site_list_id change the name property to 'label'
      // and id property to 'value' for consistency
      if ((levelOneKey + '.' + levelTwoKey) === 'site.list_standard') {
        let formattedData =
          _.reduce(objectModuleData.options, (acc, option) => {
            let val = _.mapKeys(option, (value, key) => {
              if (key === 'name') {
                return 'label'
              } else if (key === 'id') {
                return 'value'
              }
              return key
            })
            acc['filters'].push(val)
            acc['lookup'][option.id] = val.label
            return acc
          }, { 'filters':[], 'lookup':{} })
        objectModuleData.filters = formattedData.filters
        objectModuleData.lookup = formattedData.lookup
      // For the modules with group_options data (two-tier-checkboxes) create
      // the filters property
      } else if (objectModuleData.group_options && levelTwoKey !== 'market_code') {
        objectModuleData.containers = getGroupOptionsFilters(objectModuleData.group_options).containers
        objectModuleData.lookup = convertArrayDataToObject(objectModuleData.options)
      // For the modules with options data create
      // the filters property
      } else if (objectModuleData.options) {
        objectModuleData.filters = getOptionsFilters(objectModuleData.options)
        objectModuleData.lookup = convertArrayDataToObject(objectModuleData.options)
      }
    })
  })

  // Now insert the counts into the searchOptionsObject
  try{
    searchOptionsObject = insertCountsIntoSearchOptions(searchOptionsObject, APIcounts)
    }catch(error){
 }
  return { searchOptionsObject }
}

export const insertCountsIntoSearchOptions = (searchOptionsObject, APIcounts) => {
  // Loop through the Counts object and add counts to the Search options object
  _.forEach(APIcounts, function (value, levelOneKey) {
    _.forEach(value, function (value, levelTwoKey) {
      if(!searchOptionsObject[levelOneKey]) return;
      // These are the counts used in the doughnuts counts results
      if (['count', 'total'].includes(levelTwoKey)) {
        searchOptionsObject[levelOneKey][levelTwoKey] = APIcounts[levelOneKey][levelTwoKey]
      } else if (levelOneKey + '.' + levelTwoKey === 'contact.has_email') {
        // These are the counts used in the doughnuts counts results (email counts)
        searchOptionsObject[levelOneKey][levelTwoKey].count = APIcounts[levelOneKey][levelTwoKey]['count']
        searchOptionsObject[levelOneKey][levelTwoKey].total = APIcounts[levelOneKey][levelTwoKey]['total']
      } else {
        _.keys(APIcounts[levelOneKey][levelTwoKey]).filter(key => ['name', 'url', 'USD', 'EUR', 'GBP'].indexOf(key) === -1).map(levelThreeKey => {
          switch (levelThreeKey) {
            case 'count':
            case 'total':
              // Regular counts properties
              searchOptionsObject[levelOneKey] =
                mergeCounts(APIcounts[levelOneKey][levelTwoKey][levelThreeKey],
                  searchOptionsObject[levelOneKey],
                  levelOneKey,
                  _.replace(levelTwoKey, '_bucket', ''))
              break
            default:
              // Handle bucket counts, options counts (checkboxes)
              // and group_options counts (two-tier checkboxes)
              let newLevelTwoKey = _.replace((levelTwoKey + '_' + levelThreeKey), '_bucket', '')
              let countsToMerge = (searchOptionsObject[levelOneKey][newLevelTwoKey].group_options && newLevelTwoKey !== 'market_code')
                ? APIcounts[levelOneKey][levelTwoKey]
                : APIcounts[levelOneKey][levelTwoKey][levelThreeKey]
              mergeCounts(countsToMerge,
                searchOptionsObject[levelOneKey],
                levelOneKey,
                newLevelTwoKey)
              break
          }
        })
      }
    })
  })
  return searchOptionsObject
}

const mergeCounts = (APIcounts, cleanSearchOptions, key1, key2) => {
  switch (key1 + '.' + key2) {
    // There is no equivalent search options property for
    // these counts - don't merge
    case 'project.source_id':
    case 'contact.source_id':
    case 'install.source_id':
    case 'site.source_id':
    case 'project.is_active':
    case 'project.category_id':
    case 'install.major_tech_code':
    case 'install.minor_tech_code':
      break
    case 'install.tech_code':
      cleanSearchOptions.tech_code_counts = APIcounts
      break
    case 'site.list_standard':
    case 'site.list_leadneuron':
    case 'contact.list_leadneuron':
    case 'contact.list_standard':
    case 'site.site_list_id':
    case 'site.sales':
    case 'site.site_type_code':
    case 'contact.is_remote':
    case 'contact.contact_list_id':
    case 'project.type':
    case 'site.market_code':
    case 'company.sales':
    case 'company.staff':

      _.forEach(cleanSearchOptions[key2].filters, filter => {
        filter.counts = APIcounts[filter.value] || 0
      })
      break
    default:
      // There is a bug in the Seach Options Object:
      // industry_id was named industry.id in the DB tables by mistake.
      // This line of code is a temporary fix and should be removed when the
      // searchOptions object is fixed.
      key2 = (key2 === 'industry_id' || !cleanSearchOptions[key2])
        ? 'industry.id'
        : key2

      // Handle group_option case (two tier checkboxes)
      if (cleanSearchOptions[key2].group_options && key2 !== 'market_code') {
        cleanSearchOptions[key2].containers = _.forEach(cleanSearchOptions[key2].containers, container => {
          var totalGroupCount = 0
          container.children.map(filter => {
            let childCount = APIcounts[filter.value] || 0
            filter.counts = childCount
            totalGroupCount = totalGroupCount + childCount
            return filter
          })
          container.filter.counts = totalGroupCount
          return container
        })
      // Handle option case (regular checkboxes)
      } else if (cleanSearchOptions[key2].options) {
        _.forEach(cleanSearchOptions[key2].filters, filter => {
          APIcounts.count && (filter.counts = APIcounts.count[filter.value])
        })
      } else if (_.isBoolean(cleanSearchOptions[key2].type)) {
        // Handle boolean cases
        cleanSearchOptions[key2].counts = APIcounts
      } else {
        // error handler
        // console.log('Raise an error')
      }
      break
  }
  return cleanSearchOptions
}

const getOptionsFilters = options => {
  return _.reduce(options, (acc, option) => {
    let filter = getFilterObject(option)
    acc[filter.value] = filter
    return acc
  }, {})
}

const getGroupOptionsFilters = data => {
  return { containers: _.reduce(data, (acc, option) => {
    let container = getGroupedObject(option)
    acc[container.filter.value] = container
    return acc
  }, {})
  }
}

const getGroupedObject = groupData => {
  return _.transform(groupData, function (result, value, key) {
    if (_.includes(key, 'options')) {
      result.children = _.map(value, getFilterObject)
      result.childrenValues = _.map(value, (data) => { return _.keys(data)[0] })
    } else {
      [result.filter, result.label] = [getFilterObject({ [key] : value }), value]
    }
  }, {})
}

const getFilterObject = filterData => {
  return { label : filterData[_.keys(filterData)[0]], value: _.keys(filterData)[0] }
}

const convertArrayDataToObject = arrayData => {
  return _.reduce(arrayData, (acc, option) => {
    let optionKV = _.keys(option)
    acc[optionKV[0]] = option[optionKV[0]]
    return acc
  }, {})
}

export const getLabelsForSearchParams = (cleanSearchOptions, APIsearchParams) => {
  let paramsWithLabels = {}
  _.forOwn(APIsearchParams, function (values, key) {
    if (['install.id.vendor_name',
      'install.id.product_name',
      'install.id.active'].includes(key)) {
      values = values[Object.keys(values)[0]]
    }

    let keys = _.split(key, '.')
    let items = _.split(values, ',')
    let paramLabel = ''
    // There are some second level keys that have '.' in them.
    // Get the first key and re-assemble second key if necessary
    let key1 = keys.shift()
    let key2 = keys.join('.')

    if(_.isString(values)) {
      values = _.map(values.split(','), (param) => {
        return param.replace(/(\\"|")/g, '')
      }).join(',')
    }

    if (_.get(cleanSearchOptions, key + '.lookup')) {
      paramLabel = _.reduce(items, (acc, item) => {
        acc.push(cleanSearchOptions[key1][key2].lookup[item])
        return acc
      }, []).join(', ')
    } else {
      paramLabel = _.get(cleanSearchOptions, key + '.label', 'unknown')
    }
    paramsWithLabels[key] =
    { apiString: key,
      value: values,
      label: paramLabel }
  })
  return { paramsWithLabels }
}

export const getLabelForSearchParam = (searchOptions, apiString, values) => {
  let apiPStringFragments = apiString.split('.')
  if (searchOptions[apiPStringFragments[0]][apiPStringFragments[1]].label) {
    return (searchOptions[apiPStringFragments[0]][apiPStringFragments[1]].label)
  }
  let lookup = (searchOptions[apiPStringFragments[0]][apiPStringFragments[1]].lookup)
  let paramSettings = _.map(values.split(','), value => { return lookup[value] })
  return paramSettings.join(', ')
}

/* Here are the two formats we need to convert between:
   from APIformat: (received from the API when getSearchParams is called)
     install.id.active: { 53: true, 83: true, 157: true, 113:true }
     install.id.product_name: { 157: 'VMWare', 113: 'CiscoPIX'}
     install.id.vendor_name: { 53: 'Xerox'}
   to API format (the format we use to set the tech parameters)
     install.53.active: true
     install.83.active: true
     install.157.active: true
     install.113.active: true
     install.157.product_name: 'VMWare'
     install.113.product_name: 'CiscoPix'
     install.113.vendor_name: 'Xerox'
   */
export const convertTechInstallParams = (params) => {
  const techParams = ['install.id.active', 'install.id.product_name', 'install.id.vendor_name']
  var convertedParams = {}
  _.forEach(params, (paramValue, paramKey) => {
    if (techParams.includes(paramKey)) {
      const techParamType = paramKey.split('.')[2]
      _.forEach(paramValue, (value, techId) => {
        const keyString = 'install.' + techId + '.' + techParamType
        convertedParams[keyString] = value.replace(/^"(.+(?="$))"$/, '$1')
      })
    } else convertedParams[paramKey] = paramValue
  })
  return convertedParams
}
