import axios from 'lib/axios'
import config from '../../../config'
import { ShowToast, setGlobalSearchParams } from '../../../modules/global'
import { LOADING_RESULTS, fetchLeads, setLeadsPage } from '../../Results/modules/results'
import _ from 'lodash'
import { addDoubleQuotesToParams } from '../utils/searchComponentUtils'
import {
  searchAPIGetCounts,
  searchAPIGetParams,
  SetMultipleSearchParams,
  searchAPIsetParams,
} from './APIData'
import { formatAutocompleteResponse } from '../../../lib/autocompleteUtils'

const LOAD_LABELS = 'LOAD_LABELS'
const SET_PROPS = 'SET_PROPS'
const UPDATE_RANGE_TYPE = 'UPDATE_RANGE_TYPE'
const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
const LOAD_COUNTS = 'LOAD_COUNTS'
const LOAD_GRAPHS = 'LOAD_GRAPHS'
const SET_DEFAULT_SEARCH = 'SET_DEFAULT_SEARCH'
const SET_DEFAULT_PARAMS_ON_SAVE = 'SET_DEFAULT_PARAMS_ON_SAVE'
const SET_ALL_DEFAULT_PARAMS = 'SET_ALL_DEFAULT_PARAMS'
const SET_LIMITED_PARAMS = 'SET_LIMITED_PARAMS'
const TOGGLE_UPDATE = 'TOGGLE_UPDATE'
const RESET_DEFAULT = 'RESET_DEFAULT'
const SET_PILLBOX_OVERFLOW = 'SET_PILLBOX_OVERFLOW'
const ADD_TECHNOLOGY = 'ADD_TECHNOLOGY'
const UPDATE_TECH_MAJORGROUP = 'UPDATE_TECH_MAJORGROUP'
const UPDATE_TECH_MINORGROUP = 'UPDATE_TECH_MINORGROUP'
const UPDATE_TECH_TECHGROUP = 'UPDATE_TECH_TECHGROUP'
const REMOVE_TECH = 'REMOVE_TECH'
const SET_DEFAULT_TECHNOLOGIES = 'SET_DEFAULT_TECHNOLOGIES'
const HANDLE_SEARCH_OPTION = 'HANDLE_SEARCH_OPTION'
const TOGGLE_TECH_PANEL = 'TOGGLE_TECH_PANEL'
const ADD_ADDITIONAL_CRITERIA = 'ADD_ADDITIONAL_CRITERIA'
const UPDATE_TECH_SEARCH = 'UPDATE_TECH_SEARCH'
const UPDATE_TECH_SEARCH_CRITERIA = 'UPDATE_TECH_SEARCH_CRITERIA'
const REMOVE_PILLS_ON_TECH_DELETE = 'REMOVE_PILLS_ON_TECH_DELETE'
const ENFORCED_CRITERIA_NOTE = 'ENFORCED_CRITERIA_NOTE'
const CLEAR_RANGES = 'CLEAR_RANGES'
const UPDATE_TECH_SUGGESTIONS = 'UPDATE_TECH_SUGGESTIONS'
const QUANTITY_SELECTED = 'QUANTITY_SELECTED'
const CLEAR_CUSTOMQUANTITY = 'CLEAR_CUSTOMQUANTITY'
const CLEAR_ALL_CUSTOMQUANTITY = 'CLEAR_ALL_CUSTOMQUANTITY'
const SET_CUSTOM_QUANTITY = 'SET_CUSTOM_QUANTITY'
const OMNI_SEARCH_OPTION_SELECTED = 'OMNI_SEARCH_OPTION_SELECTED'
const UPDATE_DUNS_SECTION = 'UPDATE_DUNS_SECTION'
const UPDATE_PRIORITY_INDUSTRY = 'UPDATE_PRIORITY_INDUSTRY'
const UPDATE_INDUSTRY_STANDARD = 'UPDATE_INDUSTRY_STANDARD'
const OVERFLOW_POSTAL_CODE_TAB = 'OVERFLOW_POSTAL_CODE_TAB'
const POSTAL_CODE_ADD_VALUE = 'POSTAL_CODE_ADD_VALUE'
const POSTAL_CODE_EMPTY_VALUES = 'POSTAL_CODE_EMPTY_VALUES'

const DESELECT_LEADS = 'DESELECT_LEADS'

export const LEADNEURON = {
  SAVE_ITEMS : 'SAVE_ITEMS',
  REMOVE_ITEMS : 'REMOVE_ITEMS',
  DISPAY_ITEM : 'DISPAY_ITEM',
  UPDATE_PILLSBOX : 'UPDATE_PILLSBOX',
  ADD_SELECTION: 'ADD_SELECTION',
  REMOVE_SELECTION: 'REMOVE_SELECTION',
  CLEAR_ALL_SELECTIONS: 'CLEAR_ALL_SELECTIONS'
}

export const LIST_STANDARD = {
  REMOVE_LST_SELECTION: 'REMOVE_LST_SELECTION',
  CLEAR_ALL_LST_SELECTIONS: 'CLEAR_ALL_LST_SELECTIONS'
}

export function clearSearch () {
  return dispatch => {
    dispatch({ type: TOGGLE_UPDATE, payload: true })
    dispatch({ type: LOADING_RESULTS, payload: true })
    dispatch({ type: CLEAR_ALL_CUSTOMQUANTITY })
    return axios.get(config.API.CLEAR_SEARCH).then(() => {
      return setTimeout(() => dispatch(updateSearchData()), 500)
    })
  }
}

export function clearLeadneuronSelections () {
  return dispatch => {
    dispatch({ type: LEADNEURON.CLEAR_ALL_SELECTIONS })
  }
}

export function clearLeadneuronSelection (index) {
  let paramName = ''
  switch (index) {
    case 0:
      paramName = 'site.site_list_id'
      break
    case 1:
      paramName = 'contact.contact_list_id'
      break
  }
  return dispatch => {
    dispatch({ type: LEADNEURON.REMOVE_SELECTION, payload: index })

    return axios.get(config.API.SET_SEARCH_PARAM, {
      params: {
        param_name: paramName,
        param_value: '',
      },
    }).then((rep) => {
      updateSearchData()
    }).catch((err) => {
    })
  }
}

export function clearListStandardSelection (index) {
  let paramName = ''
  switch (index) {
    case 0:
      paramName = 'site.site_list_id'
      break
    case 1:
      paramName = 'contact.contact_list_id'
      break
  }
  return dispatch => {
    return axios.get(config.API.SET_SEARCH_PARAM, {
      params: {
        param_name: paramName,
        param_value: '',
      },
    }).then((rep) => {
      return dispatch(searchAPIsetParams(paramName, ''))
    }).catch((err) => {
      console.log(err)
    })
  }
}

export function industryPriority (value) {
  return dispatch => {
    dispatch({ type: UPDATE_PRIORITY_INDUSTRY, payload: value })
  }
}

export function industryStandard (value) {
  return dispatch => {
    dispatch({ type: UPDATE_INDUSTRY_STANDARD, payload: value })
  }
}

export function updateSearchData () {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_UPDATE, payload: true })
    dispatch({ type: LOADING_RESULTS, payload: true })
    dispatch(fetchLeads(1, 1))
    dispatch(setLeadsPage(1))
    return axios.get(config.API.GET_SEARCH_PARAMS).then(function(response) {
      dispatch(
        toggleDistanceSearch(
          !response.data.result.search_status[
            'site.location_zip.radius_available'
          ] === 'false'
        )
      )

      let currentSearchData = getState() && getState().search && getState().search.searchData
      const searchLeadNeuronOptions = currentSearchData && currentSearchData.result && currentSearchData.result.site && currentSearchData.result.site.search_options.list_leadneuron.options
      const searchContactLeadNeuronOptions = currentSearchData && currentSearchData.result && currentSearchData.result.contact && currentSearchData.result.contact.search_options.list_leadneuron.options
      const searchStandardOptions = currentSearchData && currentSearchData.result && currentSearchData.result.site && currentSearchData.result.site.search_options.list_standard.options
      const searchContactStandardOptions = currentSearchData && currentSearchData.result && currentSearchData.result.contact && currentSearchData.result.contact.search_options.list_standard.options
      let search_params = response.data.result.search_params

      if (searchLeadNeuronOptions) {
        searchLeadNeuronOptions.forEach(elt => {
          if (_.get(search_params, 'site.site_list_id') == _.get(elt, 'id')) {
            delete search_params['site.site_list_id'] // remove already defined value
            search_params['site.list_leadneuron'] = _.get(elt, 'id')
          }
        })
      }

      if (searchContactLeadNeuronOptions) {
        searchContactLeadNeuronOptions.forEach(elt => {
          if (_.get(search_params, 'contact.contact_list_id') == _.get(elt, 'id')) {
            delete search_params['contact.contact_list_id'] // remove already defined value
            search_params['contact.list_leadneuron'] = _.get(elt, 'id')
          }
        })
      }

      if (searchStandardOptions) {
        searchStandardOptions.forEach(elt => {
          if ((_.get(search_params, 'site.site_list_id') !== undefined) && (_.get(search_params, 'site.site_list_id')).includes(_.get(elt, 'id'))) {
            search_params['site.list_standard'] = search_params['site.site_list_id']
            delete search_params['site.site_list_id'] // remove already defined value
          }
        })
      }

      if (searchContactStandardOptions) {
        searchContactStandardOptions.forEach(elt => {
          if ((_.get(search_params, 'contact.contact_list_id') !== undefined) && (_.get(search_params, 'contact.contact_list_id')).includes(_.get(elt, 'id'))) {
            search_params['contact.list_standard'] = search_params['contact.contact_list_id']
            delete search_params['contact.contact_list_id'] // remove already defined value
          }
        })
      }

      let leadneuronDeletions = getState().search.leadneuron.selections

      if ((leadneuronDeletions[0] !== undefined && leadneuronDeletions[0] === -1) && leadneuronDeletions[1] === undefined) {
        delete search_params['site.list_leadneuron']
      }

      if ((leadneuronDeletions[0] !== undefined && leadneuronDeletions[0] === -1) && (leadneuronDeletions[1] !== undefined) && leadneuronDeletions[1] !== -1) {
        delete search_params['site.list_leadneuron']
      }

      if ((leadneuronDeletions[0] === undefined) && (leadneuronDeletions[1] !== undefined && leadneuronDeletions[1] === -1)) {
        delete search_params['contact.list_leadneuron']
      }

      if ((leadneuronDeletions[0] !== undefined && leadneuronDeletions[0] === -1) && (leadneuronDeletions[1] !== undefined && leadneuronDeletions[1] === -1)) {
        delete search_params['site.list_leadneuron']
        delete search_params['contact.list_leadneuron']
      }
      if ((leadneuronDeletions[0] !== undefined && leadneuronDeletions[0] !== -1) && (leadneuronDeletions[1] !== undefined) && leadneuronDeletions[1] === -1) {
        delete search_params['contact.list_leadneuron']
      }
      dispatch({
        type: SET_ALL_DEFAULT_PARAMS,
        payload: search_params,
      })

      dispatch({ type: SET_DEFAULT_SEARCH, payload: response.data })
      dispatch({ type: ENFORCED_CRITERIA_NOTE, payload: response.data })
      dispatch(setGlobalSearchParams(search_params))
      dispatch(searchAPIGetParams(search_params))
      dispatch(GetCounts())
      dispatch(getTechnologies())
      dispatch({ type: SET_CUSTOM_QUANTITY })
    })
  }
}

export function updateSearchParams () {
  return dispatch => {
    return axios.get(config.API.GET_SEARCH_PARAMS).then(function(response) {
      dispatch(
        toggleDistanceSearch(
          !response.data.result.search_status[
            'site.location_zip.radius_available'
          ] === 'false'
        )
      )

      dispatch({
        type: SET_ALL_DEFAULT_PARAMS,
        payload: response.data.result.search_params,
      })
      dispatch({ type: SET_DEFAULT_SEARCH, payload: response.data })
      dispatch({ type: ENFORCED_CRITERIA_NOTE, payload: response.data })
      dispatch(setGlobalSearchParams(response.data.result.search_params))
      dispatch(searchAPIGetParams(response.data.result.search_params))
      dispatch({ type: SET_CUSTOM_QUANTITY })
    })
  }
}

export function GetCounts (options) {
  return (dispatch, getState) => {
    // We don't want to update the donuts if a popup module is open
    if (
      !_.get(options, 'forceUpdate') &&
      _.get(getState(), 'APIData.popupOpen')
    ) { return }
    dispatch({
      type: SET_PILLBOX_OVERFLOW,
      payload: { filtersChanged: true },
    })
    dispatch({ type: TOGGLE_UPDATE, payload: true })
    axios
      .get(config.API.GET_COUNTS)
      .then(function(response) {
        dispatch({ type: TOGGLE_UPDATE, payload: false })
        if (response && response.data) {
          if (response.data.status_code === config.CUSTOM_STATUS.SUCCESS) {
            dispatch({
              type: LOAD_COUNTS,
              payload: response.data,
            })
            dispatch({
              type: LOAD_GRAPHS,
              payload: response.data,
            })
            dispatch({ type: TOGGLE_UPDATE, payload: false })
            dispatch(
              searchAPIGetCounts(response.data.result, {
                clearResultsTable: _.get(options, 'clearResultsTable'),
              })
            )
          } else if (
            response.data.status_code !== config.CUSTOM_STATUS.SUCCESS
          ) {
            dispatch(
              ShowToast(
                'error',
                '',
                'Hi ' +
                  localStorage.getItem('logged_user_name') +
                  ', ' +
                  response.data.status_msg,
                3000,
                false
              )
            )
          }
        }
      })
      .catch(function(errors) {
        console.log('ERROR', errors)
        dispatch({ type: TOGGLE_UPDATE, payload: false })
      })
  }
}

export function overflowPostalCode (val) {
  return (dispatch, getState) => {
    dispatch({ type: OVERFLOW_POSTAL_CODE_TAB, payload: val })
  }
}

export function addPostalCodeValues (val) {
  return (dispatch, getState) => {
    dispatch({ type: POSTAL_CODE_ADD_VALUE, payload: val })
  }
}

export function emptyPostalCodeValues () {
  return (dispatch, getState) => {
    dispatch({ type: POSTAL_CODE_EMPTY_VALUES })
  }
}

export function getTechnologies () {
  return (dispatch, getState) => {
    dispatch({ type: SET_DEFAULT_TECHNOLOGIES })
  }
}

export function LoadLabels (searchOptions) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_LABELS,
      payload: { result: searchOptions },
    })
  }
}

export function SetDefaultParams (search_id, installParam) {
  return (dispatch, getState) => {
    axios
      .get(config.API.GET_SAVE_SEARCH, { params: { search_id } })
      .then(function(response) {
        if (
          response &&
          response.data &&
          response.data.status_code == config.CUSTOM_STATUS.SUCCESS
        ) {
          if (response.data.result && response.data.result.search_params) {
            let noOfKeys = Object.keys(response.data.result.search_params)
              .length
            if (noOfKeys) {
              Object.keys(response.data.result.search_params).map((sp, i) => {
                dispatch({
                  type: SET_DEFAULT_PARAMS_ON_SAVE,
                  payload: {
                    param_name: sp,
                    param_value: response.data.result.search_params[sp],
                  },
                })
                if (
                  sp &&
                  sp.split('.').length &&
                  sp.split('.')[0] != config.CONSTANTS.TECH_SEARCH_OBJ &&
                  !installParam
                ) {
                  axios
                    .get(config.API.SET_SEARCH_PARAM, {
                      params: {
                        param_name: sp,
                        param_value: response.data.result.search_params[sp],
                      },
                    })
                    .then(function(response) {
                      if (i == noOfKeys - 1) {
                        dispatch(GetSearchParams())
                        dispatch(GetCounts())
                        dispatch({ type: SET_CUSTOM_QUANTITY })
                      }
                    })
                    .catch(function(errors) {})
                } else if (
                  sp &&
                  sp.split('.').length &&
                  sp.split('.')[0] == config.CONSTANTS.TECH_SEARCH_OBJ &&
                  installParam
                ) {
                  if (
                    getState() &&
                    getState().search &&
                    getState().search.searchData &&
                    getState().search.searchData.result &&
                    getState().search.searchData.result.install &&
                    getState().search.searchData.result.install.taxonomy &&
                    getState().search.searchData.result.install.taxonomy
                      .id_to_code
                  ) {
                    if (
                      response.data.result.search_params[sp] &&
                      Object.keys(response.data.result.search_params[sp]).length
                    ) {
                      if (
                        typeof response.data.result.search_params[sp] ===
                        'object'
                      ) {
                        Object.keys(response.data.result.search_params[sp]).map(
                          spms => {
                            // let tech_code = getState().search.searchData.result.install.taxonomy.id_to_code[spms.toString()]
                            axios
                              .get(config.API.SET_SEARCH_PARAM, {
                                params: {
                                  param_name: `${
                                    config.CONSTANTS.TECH_SEARCH_OBJ
                                  }.${spms}.${
                                    sp.split('.')[sp.split('.').length - 1]
                                  }`,
                                  param_value:
                                    response.data.result.search_params[sp][
                                      spms
                                    ],
                                },
                              })
                              .then(function(response) {
                                dispatch(GetSearchParams())
                                dispatch(GetCounts())
                                dispatch({ type: SET_DEFAULT_TECHNOLOGIES })
                              })
                              .catch(function(errors) {})
                          }
                        )
                      } else if (
                        typeof response.data.result.search_params[sp] ===
                        'string'
                      ) {
                        axios
                          .get(config.API.SET_SEARCH_PARAM, {
                            params: {
                              param_name: `${
                                config.CONSTANTS.TECH_SEARCH_OBJ
                              }.${sp.split('.')[sp.split('.').length - 1]}`,
                              param_value:
                                response.data.result.search_params[sp],
                            },
                          })
                          .then(function(response) {
                            dispatch(GetSearchParams())
                            dispatch(GetCounts())
                            dispatch({ type: SET_DEFAULT_TECHNOLOGIES })
                          })
                          .catch(function(errors) {})
                      }
                    }
                  }
                }
              })
            }
          }
          dispatch(
            ShowToast(
              'info',
              'SAVED SEARCH',
              'Hi ' +
                localStorage.getItem('logged_user_name') +
                ', you’re currently viewing a saved search',
              3000,
              false
            )
          )
        } else {
          if (
            response &&
            response.data &&
            response.data.status_code != config.CUSTOM_STATUS.SUCCESS
          ) {
            dispatch(
              ShowToast(
                'error',
                '',
                'Hi ' +
                  localStorage.getItem('logged_user_name') +
                  ', ' +
                  response.data.status_msg,
                3000,
                false
              )
            )
          }
        }
      })
      .catch(function(errors) {})
  }
}
export function SideBarScrollStatus (value) {
  return dispatch => {
    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'sideBarScrollStatus',
        value,
      },
    })
  }
}
function getRequiredKeys (options) {
  let keys = []
  if (options) {
    options.map(opt => {
      keys.push(Object.keys(opt)[0])
    })
  }
  return keys
}

export function quantitySelected (val, grp) {
  return (dispatch, getState) => {
    if (getState() && getState().search) {
      let quantityToggle = Object.assign({}, getState().search.quantityToggle)
      quantityToggle[grp] = val
      dispatch({ type: QUANTITY_SELECTED, payload: quantityToggle })

      if (
        !val &&
        getState().search.CustomQuantity[grp] &&
        getState().search.CustomQuantity[grp].quantity
      ) {
        let Quant = getState().search.CustomQuantity[grp].quantity
        let prevalue = GetModifiedValue(Quant)
        let requireKeys = []
        let param_value = ''
        let param_name = grp

        let selectedDefaultParams =
          getState().search.defaultSearchParams &&
          getState().search.defaultSearchParams[grp]
            ? getState().search.defaultSearchParams[grp]
            : null

        selectedDefaultParams = selectedDefaultParams || ''
        requireKeys = selectedDefaultParams
          ? selectedDefaultParams.split(',')
          : []
        if (requireKeys) {
          if (grp == 'site.sales' && prevalue) {
            prevalue = prevalue.replace(/M/gi, '000000')
            requireKeys = _.remove(requireKeys, function(r) {
              return r != prevalue
            })
          } else if (prevalue) {
            requireKeys = _.remove(requireKeys, function(r) {
              return r != prevalue
            })
          }
          param_value =
            requireKeys && requireKeys.length > 0 ? requireKeys.join(',') : ''
        }

        dispatch(SetSearchParam(param_name, param_value))
        dispatch({
          type: CLEAR_CUSTOMQUANTITY,
          grp,
        })
      }
    }
  }
}
export function RestrictContacts (value, grp) {
  return dispatch => {
    dispatch(SetSearchParam(grp, value))
  }
}
export function OptionSelected (
  value,
  key,
  mainGrp,
  innerGrp,
  optionalgrpOut,
  optionalgrpIn
) {
  return (dispatch, getState) => {
    if (
      mainGrp &&
      innerGrp &&
      getState() &&
      getState().search &&
      getState().search.searchData &&
      getState().search.searchData.result
    ) {
      let requireKeys = []
      let param_name =
        mainGrp != 'tech'
          ? mainGrp + '.' + innerGrp
          : mainGrp +
            '.' +
            innerGrp +
            '.' +
            optionalgrpOut +
            '.' +
            optionalgrpIn
      let param_value = ''

      let selectedDefaultParams = getState().search.defaultSearchParams
        ? mainGrp != 'tech'
          ? getState().search.defaultSearchParams[param_name]
          : getState().search.defaultSearchParams[param_name]
        : null

      selectedDefaultParams = selectedDefaultParams || ''
      requireKeys = selectedDefaultParams
        ? selectedDefaultParams.split(',')
        : []
      if (requireKeys) {
        if (requireKeys.indexOf(key) == -1 && value) {
          requireKeys.push(key)
        } else {
          if (!value) {
            requireKeys = _.remove(requireKeys, function(r) {
              return r != key
            })
            if (
              getState().search.CustomQuantity &&
              getState().search.CustomQuantity[param_name] &&
              getState().search.CustomQuantity[param_name].quantity
            ) {
              let custom = getState().search.CustomQuantity[param_name].quantity
              let val = GetModifiedValue(custom)
              if (val == key) {
                dispatch(quantitySelected(false, param_name))
              }
            }
          }
        }
      }
      param_value =
        requireKeys && requireKeys.length > 0 ? requireKeys.join(',') : ''
      dispatch(SetSearchParam(param_name, param_value))
    }
  }
}

export function RemoveSearchCriteria (searchparam) {
  return (dispatch, getState) => {
    if (searchparam === config.CONSTANTS.NET_PROJECT_FLAG) {
      dispatch(SetNetProject(false))
    } else {
      if (searchparam === config.CONSTANTS.LEADS_PARAM) {
        dispatch({ type: DESELECT_LEADS })
      }

      if (
        getState() &&
        getState().search.CustomQuantity &&
        getState().search.CustomQuantity[searchparam]
      ) {
        dispatch(quantitySelected(false, searchparam))
      }
      dispatch(SetSearchParam(searchparam, ''))
      let sectioncode =
        config.CONSTANTS.DNB_PARAMS.indexOf(searchparam) != -1
          ? 'DNB_PARAMS'
          : config.CONSTANTS.LOCATION_SET_PARAMS.indexOf(searchparam) != -1
          ? 'LOCATION_SET_PARAMS'
          : ''
      setTimeout(() => {
        dispatch(updateSearchParams())
      }, 500)
      // dispatch({
      //  type: REMOVE_SEARCH_CRITERIA_TYPE,
      //  payload: searchparam
      // })

      if (
        sectioncode &&
        getState() &&
        getState().search &&
        getState().search.defaultSearchParams
      ) {
        let remaindDnb = []
        let defaultSearchParams = Object.assign(
          {},
          getState().search.defaultSearchParams
        )
        config.CONSTANTS[sectioncode].map(dnb => {
          if (defaultSearchParams[dnb] && dnb != searchparam) {
            defaultSearchParams[dnb].split(',').map(dnbParam => {
              remaindDnb.push(dnbParam)
            })
          }
        })
        dispatch({
          type: SET_PROPS,
          payload: {
            // key: config.CONSTANTS.SECTION_CODES[sectioncode],
            key:
              sectioncode === 'DNB_PARAMS'
                ? 'dunsection'
                : sectioncode === 'LOCATION_SET_PARAMS'
                ? 'citysection'
                : '',
            value: remaindDnb,
          },
        })
      }
    }
  }
}

export function RemoveTechSearchCriteria (searchCriteriaType, id, optiontype) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_TECH_SEARCH, searchCriteriaType, id, optiontype })
    let tech_id = id
    let param_value = ''
    if (
      getState() &&
      getState().search &&
      getState().search.presentcriteria &&
      getState().search.presentcriteria.length
    ) {
      let param_values = []
      getState().search.presentcriteria.map(pc => {
        if (pc.value) {
          param_values.push(
            (pc.type == 'any' ? '' : pc.type ? pc.type : '') +
              '(' +
              pc.value +
              ')'
          )
        }
      })
      if (param_values && param_values.length > 1) {
        param_value = param_values.join(',')
      } else if (param_values && param_values.length) {
        param_value = param_values[0]
      }
    }
    dispatch({
      type: SET_PROPS,
      payload: { key: 'presentcriteria', value: null },
    })
    if (tech_id && searchCriteriaType) {
      dispatch(
        SaveInstallParams(
          searchCriteriaType.split('.')[
            searchCriteriaType.split('.').length - 1
          ],
          tech_id,
          param_value
        )
      )
    }
  }
}

export function GetSearchParams () {
  return dispatch => {
    axios
      .get(config.API.GET_SEARCH_PARAMS)
      .then(function(response) {
        if (response && response.data) {
          dispatch({ type: SET_DEFAULT_SEARCH, payload: response.data })
          dispatch({ type: ENFORCED_CRITERIA_NOTE, payload: response.data })
          dispatch(searchAPIGetParams(response.data.result.search_params))
        }
      })
      .catch(function(errors) {})
  }
}

export function setDefaultParamsOnSave (paramName, paramValue) {
  return dispatch => {
    dispatch({
      type: SET_DEFAULT_PARAMS_ON_SAVE,
      payload: { paramName, paramValue },
    })
  }
}

export function SetSearchParam (paramName, paramValue, path) {
  console.log('set search param:', paramName, paramValue, path)
  return dispatch => {
    dispatch({
      type: SET_DEFAULT_PARAMS_ON_SAVE,
      payload: {
        param_name: (path === undefined || path === '') ? paramName : path,
        param_value: paramValue,
        path: path
      },
    })
    axios
      .get(config.API.SET_SEARCH_PARAM, {
        params: {
          param_name: paramName,
          param_value: paramValue,
        },
      })
      // .then(function(response) {
      //   dispatch(updateSearchData())
      // })
      .catch(function(errors) {})
  }
}

function getArray (csv) {
  let reqarr = []
  if (csv) {
    reqarr = csv.split(',')
  }
  return reqarr
}

export function handleKeywordsSearch (query, searchType, searchScope) {
  let normalizedQuery = null

  query = query.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')

  const normalizeParam = param => {
    if (!_.isEmpty(param) && !param.includes('"')) {
      return `"${param}"`
    }
    return param
  }

  // If we receive csv we process params indivually
  normalizedQuery = _.map(query.split(','), normalizeParam).join(',')

  // Single parameter
  if (!normalizedQuery) {
    normalizeParam(query)
  }

  // Forward to the generic function
  return HandleTextEdit(normalizedQuery || query, searchType, searchScope)
}

/**
 * HandleTextEdit action wrapper to support native Elastic Search Query
 * @param {string} query String received by a single pill
 * @param {string} searchType Search types: all_with_projects, name, description etc...
 * @param {string} searchScope Search scope: all, site, project etc...
 */
export function HandleTextEdit (value, name, mainGrp) {
  return (dispatch, getState) => {
    let param_name = mainGrp + '.' + name
    let location_exclude_area_val =
      getState() && getState().search
        ? getState().search.location_exclude_area
        : false
    let param_value = value
    if (
      param_name == config.CONSTANTS.ZIPCODE_PARAM_NAME ||
      param_name == config.CONSTANTS.SICCODE_PARAM_NAME ||
      param_name == config.CONSTANTS.SICCODE_COMPANY_PARAM_NAME
    ) {
      let reqParam =
        param_name == config.CONSTANTS.SICCODE_PARAM_NAME ||
        param_name == config.CONSTANTS.SICCODE_COMPANY_PARAM_NAME
          ? 'exclude_industry_code'
          : param_name == config.CONSTANTS.ZIPCODE_PARAM_NAME
          ? 'location_exclude_area'
          : ''
      if (getState().search[reqParam] && param_value) {
        param_value = param_value
          .split(',')
          .map(pv => '-' + pv.replace('-', ''))
        param_value = param_value.join(',')
      }
    }

    dispatch({
      type: SET_DEFAULT_PARAMS_ON_SAVE,
      payload: { param_name, param_value },
    })

    const event = getState().search.searchEvent
    if (event) {
      clearTimeout(event)
    }
    const timer = setTimeout(
      () => dispatch(SetSearchParam(param_name, param_value)),
      config.CONSTANTS.SET_SEARCH_PARAM_DELAY
    )

    if (param_name == 'site.location_zip') {
      setTimeout(() => {
        let location_exclude_area_val =
          getState() && getState().search
            ? getState().search.location_exclude_area
            : false
        if (location_exclude_area_val) {
          dispatch(toggleDistanceSearch(true))
        } else {
          if (
            param_name == config.CONSTANTS.ZIPCODE_PARAM_NAME &&
            getState() &&
            getState().search &&
            getState().search.defaultSearchParams
          ) {
            if (!getState().search.defaultSearchParams['site.location_zip']) {
              dispatch(toggleDistanceSearch(true))
            } else if (
              getState().search.defaultSearchParams['site.location_zip']
            ) {
              let locationzipDefaultParams = getState()
                .search.defaultSearchParams['site.location_zip'].replace(
                  '(',
                  ''
                )
                .replace(')', '')
                .replace('-', '')
              let reqArr = getArray(locationzipDefaultParams)

              if (!reqArr.length) {
                dispatch(toggleDistanceSearch(true))
              } else if (reqArr.length == 1) {
                if (
                  _.inRange(
                    reqArr[0].replace(' ', '').length,
                    config.CONSTANTS.VALID_MIN_ZIP_LENGTH,
                    config.CONSTANTS.VALID_MAX_ZIP_LENGTH + 1
                  )
                ) {
                  dispatch(toggleDistanceSearch(false))
                } else {
                  dispatch(toggleDistanceSearch(true))
                }
              } else if (reqArr.length > 1) {
                dispatch(toggleDistanceSearch(true))
              }
            }
          }
        }
      }, config.CONSTANTS.SET_SEARCH_PARAM_DELAY)
    }

    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'searchEvent',
        value: timer,
      },
    })
  }
}

export function ToggleHeaderPanel (val) {
  return dispatch => {
    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'isHide',
        value: val,
      },
    })
  }
}
export function SetNetProject (val) {
  return (dispatch, getState) => {
    dispatch(
      SetMultipleSearchParams([
        { name: 'project.exclude_expired', value: val ? 'true' : '' },
        { name: 'project.is_active', value: val ? 'true' : '' },
      ])
    )
  }
}

export function UpdateSearchParam (param_name, value, path) {
  return (dispatch, getState) => {
    dispatch(SetSearchParam(param_name, value, path))
  }
}

export function clearSearchParam (param_name) {
  return (dispatch, getState) => {
    dispatch(SetSearchParam(param_name, ''))
  }
}

export function ackNotificationClicked (id) {
  return dispatch => {
    let param = { notification_id: id, dismiss: false }
    axios
      .get(config.API.ACK_NOTIFICATION, {
        params: param,
      })
      .then(function(res) {
        console.log(res)
      })
      .catch(function(error) {})
  }
}

export function setFilter () {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'showAll',
        value: !getState().search.showAll,
      },
    })
    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'viewMore',
        value: !getState().search.viewMore,
      },
    })
  }
}

export function showMoreNavParams () {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LIMITED_PARAMS,
    })
  }
}

export function ShowPopUps () {
  return dispatch => {
    let params = {
      user_id: '',
      type: '',
      include_viewed: true,
      include_dismissed: false,
    }
    axios
      .get(config.API.GET_NOTIFICATION_LIST, {
        params: params,
      })
      .then(function(response) {
        response.data.result.map((obj, i) => {
          if (!obj.viewed) {
            dispatch(
              ShowToast('info', '', obj.subject, 40000, true, () =>
                ackNotificationClicked(obj.id)
              )
            )
          }
        })
      })
      .catch(function(err) {})
  }
}

export function setPillboxOverflowState () {
  return dispatch => {
    dispatch({
      type: SET_PILLBOX_OVERFLOW,
      payload: { filtersChanged: false },
    })
  }
}

export function addTechnology () {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_TECHNOLOGY,
    })
  }
}
export function majorGroupChange (majorGroupId, techIndex) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_TECH_MAJORGROUP,
      techIndex,
      majorGroupId,
    })
  }
}
export function handleOmniSearchOptionEdit (
  techInd,
  key,
  value,
  index,
  keytype
) {
  return (dispatch, getState) => {
    dispatch({
      type: OMNI_SEARCH_OPTION_SELECTED,
      techInd,
      key,
      value,
      index,
      keytype,
    })
    let tech_id =
      getState().search.technologies && getState().search.technologies[techInd]
        ? getTechId(getState().search.technologies[techInd])
        : ''

    if (tech_id != 'all') { dispatch(SaveTree(techInd, true, { techInd, key, value, index, keytype })) } else {
      dispatch(
        handleSearchOptionEdit(
          techInd,
          key && key.split('.').length
            ? key.split('.')[key.split('.').length - 1]
            : '',
          value && value[0] ? [value[0].param_value_label] : '',
          index,
          keytype
        )
      )
    }
  }
}
export function minorGroupChange (minorGroupId, techIndex) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_TECH_MINORGROUP,
      techIndex,
      minorGroupCode:
        minorGroupId &&
        minorGroupId != '0' &&
        minorGroupId != 'all' &&
        getState() &&
        getState().search &&
        getState().search.searchData &&
        getState().search.searchData.result &&
        getState().search.searchData.result.install &&
        getState().search.searchData.result.install.taxonomy &&
        getState().search.searchData.result.install.taxonomy.id_to_code
          ? getState().search.searchData.result.install.taxonomy &&
            getState().search.searchData.result.install.taxonomy.id_to_code[
              minorGroupId.toString()
            ]
          : minorGroupId,
    })
    if (minorGroupId == 'all') {
      dispatch(SaveTree(techIndex))
    }
  }
}
export function techGroupChange (techGroupId, techIndex) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_TECH_TECHGROUP,
      techIndex,
      techGroupCode:
        techGroupId &&
        techGroupId != '0' &&
        techGroupId != 'all' &&
        getState() &&
        getState().search &&
        getState().search.searchData &&
        getState().search.searchData.result &&
        getState().search.searchData.result.install &&
        getState().search.searchData.result.install.taxonomy &&
        getState().search.searchData.result.install.taxonomy.id_to_code
          ? getState().search.searchData.result.install.taxonomy.id_to_code[
              techGroupId.toString()
            ]
          : techGroupId,
    })
    dispatch(SaveTree(techIndex))
  }
}
function SaveTree (techIndex, omni, saveObj) {
  return (dispatch, getState) => {
    if (getState() && getState().search && getState().search.technologies) {
      let tech_id =
        getState().search.technologies &&
        getState().search.technologies[techIndex]
          ? getTechId(getState().search.technologies[techIndex])
          : ''

      if (tech_id) {
        dispatch({
          type: UPDATE_TECH_SEARCH_CRITERIA,
          tech_id,
          param_name: 'install.id.active',
          param_value: 'true',
        })
      }
      if (tech_id) {
        axios
          .get(config.API.SET_SEARCH_PARAM, {
            params: {
              param_name: `install.${tech_id}.active`,
              param_value: true,
            },
          })
          .then(function(response) {
            if (omni) {
              dispatch(
                handleSearchOptionEdit(
                  saveObj.techInd,
                  saveObj.key,
                  saveObj.value && saveObj.value.length
                    ? [saveObj.value[0].param_value_label]
                    : [],
                  saveObj.index,
                  saveObj.keytype
                )
              )
            } else {
              dispatch(GetCounts())
              dispatch(getSuggestions(tech_id, '', 'vendor_name', techIndex))
              dispatch(getSuggestions(tech_id, '', 'product_name', techIndex))
            }
            dispatch({
              type: SET_PROPS,
              payload: {
                key: 'omni',
                value: false,
              },
            })
          })
          .catch(function(errors) {})
      }
    }
  }
}
function removeSearchParameter (techId, techInd) {
  return (dispatch, getState) => {
    let existingTechObj = {}
    if (
      getState().search.defaultSearchParams &&
      Object.keys(getState().search.defaultSearchParams)
    ) {
      existingTechObj = getExistingObj(getState().search.defaultSearchParams)
    }
    if (
      existingTechObj &&
      Object.keys(existingTechObj) &&
      existingTechObj[techId] &&
      Object.keys(existingTechObj[techId])
    ) {
      let responses = 0
      dispatch({ type: REMOVE_PILLS_ON_TECH_DELETE, techId })
      let existedInstall = Object.keys(existingTechObj[techId])
      dispatch(updateInstallData(existedInstall, techId))
    }
    dispatch({
      type: REMOVE_TECH,
      payload: techInd,
    })
  }
}
export function removeActiveTech (techId) {
  return (dispatch, getState) => {
    // let tech_code = getState().search.searchData.result.install.taxonomy.id_to_code[techId]
    let techInd = ''
    if (getState().search && getState().search.technologies) {
      getState().search.technologies.map((tech, index) => {
        if (tech.tech && tech.tech.id == techId) {
          techInd = index
        } else if (
          tech.minor_group &&
          tech.minor_group.id == techId &&
          tech.tech.id == 'all'
        ) {
          techInd = index
        } else if (
          tech.major_group &&
          tech.major_group.id == techId &&
          tech.minor_group.id == 'all'
        ) {
          techInd = index
        }
      })
    }
    dispatch(removeSearchParameter(techId, techInd))
  }
}
export function removeTech (techInd) {
  return (dispatch, getState) => {
    if (getState() && getState().search && getState().search.technologies) {
      let tec = getState().search.technologies[techInd]
      if (
        tec &&
        Object.keys(tec).length &&
        tec.major_group &&
        tec.major_group.id
      ) {
        let techId = ''
        if (
          getState().search.searchData &&
          getState().search.searchData.result &&
          getState().search.searchData.result.install &&
          getState().search.searchData.result.install.taxonomy &&
          getState().search.searchData.result.install.taxonomy.id_to_code &&
          tec.major_group &&
          tec.major_group.id
        ) {
          // tech_code =
          //  getState().search.searchData.result.install.taxonomy.id_to_code[tec.major_group.id.toString()]
          techId = tec.major_group.id.toString()
          if (
            tec.tech &&
            Object.keys(tec.tech).length &&
            tec.tech.id != 'all'
          ) {
            // tech_code =
            //  getState().search.searchData.result.install.taxonomy.id_to_code[tec.tech.id.toString()]
            techId = tec.tech.id.toString()
          } else if (
            tec.minor_group &&
            Object.keys(tec.minor_group) &&
            tec.minor_group.id != 'all'
          ) {
            // tech_code =
            //  getState().search.searchData.result.install.taxonomy.id_to_code[tec.minor_group.id.toString()]
            techId = tec.minor_group.id.toString()
          }
        }
        dispatch(removeSearchParameter(techId, techInd))
      } else if (techInd >= 0) {
        dispatch({
          type: REMOVE_TECH,
          payload: techInd,
        })
      }
    }
  }
}
function updateInstallData (existingInstall, tech_id) {
  return dispatch => {
    if (existingInstall && existingInstall[0] && existingInstall.length) {
      let ei = existingInstall[0].split('.')[
        existingInstall[0].split('.').length - 1
      ]
      axios
        .get(config.API.SET_SEARCH_PARAM, {
          params: {
            param_name:
              tech_id != 'all'
                ? `install.${tech_id}.${ei}`
                : tech_id == 'all'
                ? `install.${ei}`
                : `install.${tech_id}.${ei}`,
            param_value: '',
          },
        })
        .then(function(response) {
          existingInstall = existingInstall.filter(i => i != existingInstall[0])
          dispatch(updateInstallData(existingInstall, tech_id))
        })
    } else {
      dispatch(GetCounts())
    }
  }
}
function getExistingObj (state) {
  let existingTechObj = {}
  let filterItems = state ? Object.keys(state) : []
  if (filterItems.length) {
    filterItems.map(fi => {
      if (
        fi &&
        fi.split('.').length &&
        fi.split('.')[0] == config.CONSTANTS.TECH_SEARCH_OBJ
      ) {
        Object.keys(state).map(df => {
          if (df == fi) {
            if (typeof state[df] === 'object') {
              Object.keys(state[df]).map(dsp => {
                if (existingTechObj && existingTechObj[dsp]) {
                  existingTechObj[dsp][
                    df
                      .split('.')
                      .filter(f => f != config.CONSTANTS.TECH_SEARCH_OBJ)
                      .join('.')
                  ] = state[df][dsp]
                } else {
                  existingTechObj[dsp] = {}
                  existingTechObj[dsp][
                    df
                      .split('.')
                      .filter(f => f != config.CONSTANTS.TECH_SEARCH_OBJ)
                      .join('.')
                  ] = state[df][dsp]
                }
              })
            } else if (typeof state[df] === 'string') {
              if (!existingTechObj['all']) {
                existingTechObj['all'] = {}
              }
              existingTechObj['all'][
                df
                  .split('.')
                  .filter(f => f != config.CONSTANTS.TECH_SEARCH_OBJ)
                  .join('.')
              ] = state[df]
            }
          }
        })
      }
    })
  }
  return existingTechObj
}

export function toggleTechPanel (techInd) {
  return dispatch => {
    dispatch({
      type: TOGGLE_TECH_PANEL,
      techInd,
    })
  }
}

function GetModifiedValue (preQ) {
  let previous
  switch (preQ.range_type) {
    case 'equal':
      previous = preQ.range_change
        ? preQ.range_change.replace(/^[ ]+|[ ]+$/g, '')
        : ''
      break

    case 'lesser':
      previous =
        preQ.range_change && preQ.range_change.replace(/^[ ]+|[ ]+$/g, '')
          ? '-' + preQ.range_change.replace(/^[ ]+|[ ]+$/g, '')
          : ''
      break

    case 'greater':
      previous =
        preQ.range_change && preQ.range_change.replace(/^[ ]+|[ ]+$/g, '')
          ? preQ.range_change.replace(/^[ ]+|[ ]+$/g, '') + '-'
          : ''
      break

    case 'between':
      previous =
        preQ.min_range_change && preQ.max_range_change
          ? preQ.min_range_change + '-' + preQ.max_range_change
          : ''
      break
  }
  return previous
}

export function BusinessQuantityChange (name, value, bustype) {
  return (dispatch, getState) => {
    let previous
    if (
      getState() &&
      getState().search.CustomQuantity &&
      getState().search.CustomQuantity[bustype] &&
      getState().search.CustomQuantity[bustype].quantity &&
      getState().search.CustomQuantity[bustype].quantity[name]
    ) {
      let preQ = getState().search.CustomQuantity[bustype].quantity
      previous = GetModifiedValue(preQ)
    }
    dispatch({
      type: UPDATE_QUANTITY,
      bustype,
      name,
      value,
    })
    if (getState() && getState().search && getState().search.CustomQuantity) {
      Object.keys(getState().search.CustomQuantity).map((cq, i) => {
        if (cq == bustype) {
          // let requireKeys = []
          let param_name = cq
          let currentCustomQuantity = getState().search.CustomQuantity[cq]
            .quantity
          let param_value = ''
          param_value = GetModifiedValue(currentCustomQuantity)

          if (
            getState().search.quantityToggle &&
            getState().search.quantityToggle[param_name] &&
            currentCustomQuantity.range_type == 'between' &&
            !param_value
          ) {
            let requireKeys = []
            let selectedDefaultParams =
              getState().search.defaultSearchParams &&
              getState().search.defaultSearchParams[param_name]
                ? getState().search.defaultSearchParams[param_name]
                : null

            selectedDefaultParams = selectedDefaultParams || ''
            requireKeys = selectedDefaultParams
              ? selectedDefaultParams.split(',')
              : []
            if (requireKeys) {
              if (param_name == 'site.sales' && previous) {
                previous = previous.toUpperCase() // Removing previous value
                if (previous.indexOf('M') > 0) {
                  previous = previous.replace(/M/gi, '000000')
                }
                requireKeys = _.remove(requireKeys, function(r) {
                  return r != previous
                })
              } else if (previous) {
                requireKeys = _.remove(requireKeys, function(r) {
                  return r != previous
                })
              }
              param_value =
                requireKeys && requireKeys.length > 0
                  ? requireKeys.join(',')
                  : ''
            }
            dispatch({
              type: SET_DEFAULT_PARAMS_ON_SAVE,
              payload: { param_name, param_value },
            })
          }

          param_value = GetModifiedValue(currentCustomQuantity)

          if (
            getState().search.quantityToggle &&
            getState().search.quantityToggle[param_name]
          ) {
            let requireKeys = []
            let selectedDefaultParams =
              getState().search.defaultSearchParams &&
              getState().search.defaultSearchParams[param_name]
                ? getState().search.defaultSearchParams[param_name]
                : null

            selectedDefaultParams = selectedDefaultParams || ''
            requireKeys = selectedDefaultParams
              ? selectedDefaultParams.split(',')
              : []
            if (requireKeys) {
              if (param_name == 'site.sales' && previous) {
                previous = previous.toUpperCase()
                // Removing previous value
                if (previous.indexOf('M') > 0) {
                  previous = previous.replace(/M/gi, '000000')
                }
                requireKeys = _.remove(requireKeys, function(r) {
                  return r != previous
                })
              } else if (previous) {
                requireKeys = _.remove(requireKeys, function(r) {
                  return r != previous
                })
              }

              if (param_name == 'site.sales' && param_value) {
                param_value = param_value.toUpperCase() // Pushing new value
                if (param_value.indexOf('M') > 0) {
                  param_value = param_value.replace(/M/gi, '000000')
                }
                requireKeys.push(param_value)
              } else if (param_name != 'site.sales' && param_value) {
                requireKeys.push(param_value)
              }
              param_value =
                requireKeys && requireKeys.length > 0
                  ? requireKeys.join(',')
                  : ''
            }

            dispatch({
              type: SET_DEFAULT_PARAMS_ON_SAVE,
              payload: { param_name, param_value },
            })
            const event = getState().search.searchQuantityEvent
            if (event) {
              clearTimeout(event)
            }
            const timer = setTimeout(
              () =>
                axios
                  .get(config.API.SET_SEARCH_PARAM, {
                    params: {
                      param_name,
                      param_value,
                    },
                  })
                  .then(function(response) {
                    dispatch(GetCounts())
                  })
                  .catch(function(errors) {}),
              config.CONSTANTS.SET_QUANTITY_SEARCH_DELAY
            )
            dispatch({
              type: SET_PROPS,
              payload: {
                key: 'searchQuantityEvent',
                value: timer,
              },
            })
          }
        }
      })
    }
  }
}

export function onQuantityChange (techInd, name, value) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RANGE_TYPE,
      techInd,
      name,
      value,
    })
    if (getState() && getState().search && getState().search.technologies) {
      getState().search.technologies.map((tec, i) => {
        if (techInd == i) {
          let param_name = 'install.id.quantity'
          let search_value = value
          let param_value = ''

          if (name == 'range_type' && value == 'between') { dispatch({ type: CLEAR_RANGES, index: i }) }

          if (tec.search_options && tec.search_options['id.quantity']) {
            if (
              (name == 'range_type' && value == 'between') ||
              (name != 'range_type' &&
                tec.search_options['id.quantity'].range_type == 'between')
            ) {
              if (
                tec.search_options['id.quantity'].min_range_change ||
                tec.search_options['id.quantity'].max_range_change
              ) {
                param_value =
                  (tec.search_options['id.quantity'].min_range_change
                    ? tec.search_options['id.quantity'].min_range_change
                    : '') +
                  '-' +
                  (tec.search_options['id.quantity'].max_range_change
                    ? tec.search_options['id.quantity'].max_range_change
                    : '')
              } else {
                param_value = ''
              }
            } else if (
              (name == 'range_type' && value == 'lesser') ||
              (name != 'range_type' &&
                tec.search_options['id.quantity'].range_type == 'lesser')
            ) {
              param_value =
                tec.search_options['id.quantity'].range_change &&
                tec.search_options['id.quantity'].range_change.replace(
                  /^[ ]+|[ ]+$/g,
                  ''
                )
                  ? '-' +
                    tec.search_options['id.quantity'].range_change.replace(
                      /^[ ]+|[ ]+$/g,
                      ''
                    )
                  : ''
            } else if (
              (name == 'range_type' && value == 'greater') ||
              (name != 'range_type' &&
                tec.search_options['id.quantity'].range_type == 'greater')
            ) {
              param_value =
                tec.search_options['id.quantity'].range_change &&
                tec.search_options['id.quantity'].range_change.replace(
                  /^[ ]+|[ ]+$/g,
                  ''
                )
                  ? tec.search_options['id.quantity'].range_change.replace(
                      /^[ ]+|[ ]+$/g,
                      ''
                    ) + '-'
                  : ''
            } else {
              param_value = tec.search_options['id.quantity'].range_change
                ? tec.search_options['id.quantity'].range_change.replace(
                    /^[ ]+|[ ]+$/g,
                    ''
                  )
                : ''
            }
          }
          let tech_id = getTechId(tec)
          if (tech_id) {
            dispatch({
              type: UPDATE_TECH_SEARCH_CRITERIA,
              tech_id,
              param_name,
              param_value,
            })
          }
          if (
            param_name &&
            tech_id &&
            (name == 'range_change' ||
              name == 'min_range_change' ||
              name == 'max_range_change' ||
              name == 'range_type')
          ) {
            // dispatch(SaveTechParams(param_name, tech_id, param_value))

            const event = getState().search.searchOptionQuantityEvent
            if (event) {
              clearTimeout(event)
            }
            const timer = setTimeout(
              () =>
                axios
                  .get(config.API.SET_SEARCH_PARAM, {
                    params: {
                      param_name: `install.${tech_id}.quantity`,
                      param_value,
                    },
                  })
                  .then(function(response) {
                    dispatch(GetCounts())
                  })
                  .catch(function(errors) {}),
              config.CONSTANTS.SET_QUANTITY_SEARCH_DELAY
            )
            dispatch({
              type: SET_PROPS,
              payload: {
                key: 'searchOptionQuantityEvent',
                value: timer,
              },
            })
          }
        }
      })
    }
  }
}
export function addAdditionalSearchCriteria (techInd, key) {
  return (dispatch, getState) => {
    let tech_id = ''
    if (getState() && getState().search && getState().search.technologies) {
      tech_id = getTechId(getState().search.technologies[techInd])
    }
    dispatch({
      type: ADD_ADDITIONAL_CRITERIA,
      techInd,
      key:
        tech_id && tech_id != 'all'
          ? key
          : tech_id == 'all'
          ? key.split('.')[key.split('.').length - 1]
          : key,
    })
  }
}
function SaveTechParams (param_name, tech_id, param_value, callSave) {
  return dispatch => {
    axios
      .get(config.API.SET_SEARCH_PARAM, {
        params: {
          param_name,
          tech_id,
          param_value,
        },
      })
      .then(function(response) {
        dispatch(GetCounts())
        if (callSave) {
          dispatch(GetSearchParams())
        }
      })
      .catch(function(errors) {})
  }
}
function getUpdatedSearchCriteria (tec, searchCriteriaType, optiontype) {
  if (searchCriteriaType == 'quantity') {
    return {}
  } else {
    if (
      tec.search_options[searchCriteriaType] &&
      tec.search_options[searchCriteriaType].length
    ) {
      let techRem = []
      tec.search_options[searchCriteriaType].map(sct => {
        sct.type = sct.type ? sct.type : 'any'
        if (sct.type != optiontype) {
          techRem.push(sct)
        }
      })
      return techRem && techRem.length ? techRem : [{ type: 'any' }]
    }
  }
}
export function currentSection (sec) {
  return dispatch => {
    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'activeClass',
        value: sec,
      },
    })
  }
}

function getParamValue (options) {
  let param_value = []
  if (options) {
    options.map(opt => {
      param_value.push(Object.keys(opt)[0])
    })
  }
  param_value =
    param_value && param_value.length > 0 ? param_value.join(',') : ''
  return param_value
}
export function toggleSelectAll (eve, mainGrp, mainNode, innerGrp) {
  return (dispatch, getState) => {
    let param_value = ''
    if (
      getState().search &&
      getState().search.searchData &&
      getState().search.searchData.result &&
      getState().search.searchData.result[mainGrp][mainNode][innerGrp]
        .options &&
      eve
    ) {
      param_value = getParamValue(
        getState().search.searchData.result[mainGrp][mainNode][innerGrp].options
      )
    }
    let param_name = mainGrp + '.' + innerGrp
    dispatch(quantitySelected(eve, param_name))
    dispatch(SetSearchParam(param_name, param_value))
  }
}
export function excludeSearchParam (val, key, paramName, majGrp) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        key,
        value: val,
      },
    })
    let searchparam = majGrp + '.' + paramName
    if (
      getState() &&
      getState().search &&
      getState().search.defaultSearchParams &&
      getState().search.defaultSearchParams[searchparam]
    ) {
      if (val) {
        let options = getState().search.defaultSearchParams[searchparam].split(
          ','
        )
        options = options.map(opt => '-' + opt)
        options = options.join(',')
        dispatch(HandleTextEdit(options, paramName, majGrp))
      } else {
        let options = getState().search.defaultSearchParams[searchparam].split(
          ','
        )
        options = options.map(opt => opt.replace('-', ''))
        options = options.join(',')
        dispatch(HandleTextEdit(options, paramName, majGrp))
      }
    }
  }
}

export function toggleDistanceSearch (val) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROPS,
      payload: {
        key: 'isDistanceSearchable',
        value: val,
      },
    })

    if (val) {
      dispatch(HandleTextEdit('', 'location_radius', 'site'))
    }
  }
}

function getTechId (tec) {
  let tech_id = ''
  if (tec.major_group && tec.major_group.id) {
    tech_id = tec.major_group.id.toString()
    if (tec.tech && tec.tech.id != 'all') {
      tech_id = tec.tech.id.toString()
    } else if (tec.minor_group && tec.minor_group.id != 'all') {
      tech_id = tec.minor_group.id.toString()
    }
  }
  return tech_id
}
export function handleSearchOptionEdit (techInd, key, value, index, keytype) {
  return (dispatch, getState) => {
    // key = key.indexOf('id.') == -1 ? 'id.' + key : key
    let reqval = []
    if (keytype == 'value' && value && value.length) {
      value.map(v => {
        if (typeof v === 'object') {
          reqval.push(v.param_value_label)
        } else if (typeof v === 'string') {
          reqval.push(v)
        }
      })
    }
    value = keytype == 'value' ? reqval : value
    let tech_id = getTechId(getState().search.technologies[techInd])
    if (tech_id == 'all') {
      key =
        key.indexOf('vendor_name') != -1
          ? 'vendor_name'
          : key.indexOf('product_name') != -1
          ? 'id.product_name'
          : ''
    }
    dispatch({
      type: HANDLE_SEARCH_OPTION,
      techInd,
      key,
      index,
      value,
      keytype,
    })
    if (getState() && getState().search && getState().search.technologies) {
      getState().search.technologies.map((tec, i) => {
        if (techInd == i) {
          let param_name = `install.${key}`

          let search_Type = ''
          let search_value = []
          let param_value = []

          if (
            keytype == 'value' ||
            (keytype == 'type' && tec.search_options[key][index].value)
          ) {
            if (tec.search_options[key] && tec.search_options[key].length > 1) {
              search_Type = ''
              param_value = []
              tec.search_options[key].map((sp, ind) => {
                search_value = []
                search_Type =
                  tec.search_options &&
                  tec.search_options[key] &&
                  tec.search_options[key][ind]
                    ? tec.search_options[key][ind].type != 'any'
                      ? tec.search_options[key][ind].type
                      : ''
                    : ''

                if (
                  tec.search_options[key][ind].value &&
                  tec.search_options[key][ind].value.length
                ) {
                  tec.search_options[key][ind].value.map(val => {
                    if (typeof val === 'string') search_value.push(val)
                    // else if (typeof (val) == 'object')
                    //  search_value.push(Object.keys(val).length ? val[Object.keys(val)[0]] : '')
                  })
                }
                search_value =
                  search_value.length > 0
                    ? search_value.join(',')
                    : search_value.length == 1
                    ? search_value[0]
                    : ''

                search_Type = !search_Type ? '' : search_Type
                param_value.push(
                  search_value ? `${search_Type}(${search_value})` : ''
                )
                // param_value.push(search_Type == '-' ? `-(${search_value})` : search_Type == '+' ? `+(${search_value})` : `(${search_value})`)
              })
              param_value =
                param_value && param_value.length ? param_value.join(',') : ''
            } else {
              search_Type =
                tec.search_options &&
                tec.search_options[key] &&
                tec.search_options[key][index]
                  ? tec.search_options[key][index].type != 'any'
                    ? tec.search_options[key][index].type
                    : ''
                  : ''
              if (
                tec.search_options &&
                tec.search_options[key] &&
                tec.search_options[key][index].value &&
                tec.search_options[key][index].value.length
              ) {
                tec.search_options[key][index].value.map(val => {
                  search_value.push(val)
                })
              }
              search_value =
                search_value.length > 0
                  ? search_value.join(',')
                  : search_value.length == 1
                  ? search_value[0]
                  : ''
              search_Type = !search_Type ? '' : search_Type
              // param_value = search_Type == '-' ? `-(${search_value})` : search_Type == '+' ? `+(${search_value})` : `(${search_value})`
              param_value = search_value
                ? `${search_Type}(${search_value})`
                : ''
            }
            if (tech_id) {
              dispatch({
                type: UPDATE_TECH_SEARCH_CRITERIA,
                tech_id,
                param_name,
                param_value,
              })
            }
            dispatch(
              SaveInstallParams(
                key.indexOf('vendor_name') != -1
                ? 'vendor_name'
                : key.indexOf('product_name') != -1
                ? 'product_name'
                : '',
                tech_id,
                param_value
              )
            )
          }
        }
      })
    }
  }
}
export function SaveInstallParams (param_name, tech_id, param_value) {
  param_value = param_value ? addDoubleQuotesToParams(param_value) : ''
  return dispatch => {
    axios
      .get(config.API.SET_SEARCH_PARAM, {
        params: {
          param_name:
            tech_id != 'all'
              ? `install.${tech_id}.${param_name}`
              : tech_id == 'all'
              ? `install.${param_name}`
              : '',
          param_value,
        },
      })
      .then(function(response) {
        dispatch(GetCounts())
      })
      .catch(function(errors) {})
  }
}
export function getSuggestions (
  techId,
  context,
  suggestType,
  techInd,
  taxonomy,
  intensity = null
) {
  return (dispatch, getState) => {
    let paramName = techId
      ? techId !== 'all'
        ? `install.${techId}.${suggestType}`
        : `install.id.${suggestType}`
      : `${suggestType}`
    if (!suggestType) {
      paramName =
        config.CONSTANTS.INSTALL_VENDOR_PARAMNAME +
        ',' +
        config.CONSTANTS.INSTALL_PRODUCT_PARAMNAME
    }
    let query = context.replace(/^[ ]+|[ ]+$/g, '')
    let [param, tech, type] = paramName.split('.')

    axios
      .get(config.API.GET_SUGGESTIONS, {
        params: {
          param_name: (param + '.' + type).replace('name', 'id'),
          context: tech === 'id' ? null : tech,
          intensity: intensity,
          query,
        },
      })
      .then(function(response) {
        const formattedResponse = formatAutocompleteResponse(response, taxonomy)
        if (techId && techId !== 'all') {
          dispatch({
            type: UPDATE_TECH_SUGGESTIONS,
            payload: formattedResponse,
            techInd,
            suggestType,
            techId,
          })
        } else if (techId && techId === 'all') {
          let reqSuggestions = []
          if (query) {
            reqSuggestions.push({
              param_context_label: 'All Categories',
              param_value_label: query,
              param_value: 'all',
            })
          }
          formattedResponse.map(sug => {
            reqSuggestions.push(sug)
          })
          dispatch({
            type: UPDATE_TECH_SUGGESTIONS,
            payload: reqSuggestions,
            techInd,
            suggestType,
            techId,
          })
        } else {
          let suggestiontype = suggestType
            ? suggestType.split(',').length > 1
              ? suggestType.split(',')[0].split('.')[
                  suggestType.split(',')[0].split('.').length - 1
                ]
              : suggestType
                  .split('.')
                  .filter(f => f !== suggestType.split('.')[0])
                  .join('.')
            : 'all'
          if (suggestiontype === 'industry.id') {
            let suggestions = []
            let industryids = []
            if (
              getState() &&
              getState().search &&
              getState().search.defaultSearchParams &&
              getState().search.defaultSearchParams['site.industry.id']
            ) {
              industryids = getState()
                .search.defaultSearchParams['site.industry.id'].replace(
                  new RegExp('-', 'g'),
                  ''
                )
                .split(',')
            }
            formattedResponse.map(sug => {
              if (industryids && industryids.length) {
                if (industryids.indexOf(sug.param_value) === -1) {
                  suggestions.push(sug)
                }
              } else {
                suggestions.push(sug)
              }
            })
            dispatch({
              type: SET_PROPS,
              payload: {
                key: `${suggestiontype}_suggestions`,
                value: _.isEmpty(formattedResponse) ? [] : formattedResponse,
              },
            })
          }
          if (
            suggestiontype === 'dnb_all' ||
            suggestiontype === 'location_city_code'
          ) {
            let sugges = []
            let paramkeytype =
              suggestiontype === 'dnb_all' ? 'param_value' : 'param_value_label'
            if (
              getState() &&
              getState().search &&
              !_.isEmpty(formattedResponse)
            ) {
              let availablesuggs =
                suggestiontype === 'dnb_all'
                  ? getState().search.dunsection
                  : suggestiontype === 'location_city_code'
                  ? getState().search.citysection
                  : []
              formattedResponse.map(sugg => {
                if (availablesuggs && availablesuggs.length) {
                  if (availablesuggs.indexOf(sugg[paramkeytype]) === -1) {
                    sugges.push(sugg)
                  }
                } else {
                  sugges.push(sugg)
                }
              })
            }
            dispatch({
              type: SET_PROPS,
              payload: {
                key: `${suggestiontype}_suggestions`,
                value: sugges,
              },
            })
          } else {
            let reqsugges = []
            if (
              config.CONSTANTS.ALLOW_NEW_SUGGESTIONS.indexOf(suggestiontype) !==
                -1 &&
              context
            ) {
              reqsugges.push({
                param_value_label: context,
                param_context_label: context,
                param_value: context,
                param_name: context,
              })
              if (!_.isEmpty(formattedResponse)) {
                formattedResponse.map(sugg => {
                  reqsugges.push(sugg)
                })
              }
            } else {
              reqsugges = formattedResponse
            }
            dispatch({
              type: SET_PROPS,
              payload: {
                key: `${suggestiontype}_suggestions`,
                value:
                  reqsugges && reqsugges.length
                    ? techId === 'all' ||
                      config.CONSTANTS.SUGGESTIONS_WITH_OBJECTS.indexOf(
                        suggestiontype
                      ) != -1
                      ? reqsugges
                      : reqsugges.map(f => f.param_value_label)
                    : [],
              },
            })
          }
        }
      })
      .catch(function(errors) {})
  }
}
export function HandleDNBTextEdit (dnbObj, section, PARAMS, keytype) {
  return (dispatch, getState) => {
    if (
      dnbObj &&
      dnbObj.length &&
      typeof dnbObj[dnbObj.length - 1] === 'object'
    ) {
      let option = dnbObj[dnbObj.length - 1]
      if (
        option &&
        option.param_name &&
        config.CONSTANTS.SEARCH_PARAMS_SET_PARAMS[option.param_name]
      ) {
        option.param_name =
          config.CONSTANTS.SEARCH_PARAMS_SET_PARAMS[option.param_name]
      }
      let value = []
      if (
        getState().search[section] &&
        getState().search[section].length < dnbObj.length
      ) {
        if (getState().search.defaultSearchParams[option.param_name]) {
          getState()
            .search.defaultSearchParams[option.param_name].split(',')
            .map(p => {
              if (p) {
                value.push(p)
              }
            })
          value.push(option[keytype])
          getState().search.defaultSearchParams = Object.assign(
            {},
            getState().search.defaultSearchParams,
            { [option.param_name]: value }
          )
        } else {
          value.push(option[keytype])
          getState().search.defaultSearchParams = Object.assign(
            {},
            getState().search.defaultSearchParams,
            { [option.param_name]: value }
          )
        }
        dispatch({
          type: UPDATE_DUNS_SECTION,
          payload: true,
          value: option[keytype],
          section,
        })
        dispatch(
          HandleTextEdit(
            typeof getState().search.defaultSearchParams[option.param_name] ===
              'object'
              ? getState().search.defaultSearchParams[option.param_name].join(
                  ','
                )
              : getState().search.defaultSearchParams[option.param_name],
            option.param_name.split('.')[1],
            option.param_name.split('.')[0]
          )
        )
      } else if (
        getState().search[section] &&
        getState().search[section].length > dnbObj.length
      ) {
        let missingObj = ''
        let availablename = dnbObj.map(d =>
          typeof d === 'object' ? d[keytype] : d
        )
        if (getState() && getState().search && getState().search[section]) {
          missingObj = getState().search[section].filter(
            f => availablename.indexOf(f) == -1
          )[0]
        }
        if (missingObj) {
          // let param_name = ''
          // let param_value = ''
          config.CONSTANTS[PARAMS].map(dnb => {
            if (
              getState().search.defaultSearchParams[dnb] &&
              getState().search.defaultSearchParams[dnb].indexOf(missingObj) !=
                -1
            ) {
              getState().search.defaultSearchParams = Object.assign(
                {},
                getState().search.defaultSearchParams,
                {
                  [dnb]: getState()
                    .search.defaultSearchParams[dnb].split(',')
                    .filter(f => f != missingObj),
                }
              )
              dispatch(
                HandleTextEdit(
                  typeof getState().search.defaultSearchParams[dnb] === 'object'
                    ? getState().search.defaultSearchParams[dnb].join(',')
                    : getState().search.defaultSearchParams[dnb],
                  dnb.split('.')[1],
                  dnb.split('.')[0]
                )
              )
              dispatch({
                type: UPDATE_DUNS_SECTION,
                payload: false,
                value: missingObj,
                section,
              })
            }
          })
        }
      }
    } else if (
      dnbObj &&
      dnbObj.length &&
      typeof dnbObj[dnbObj.length - 1] === 'string'
    ) {
      let missingObj = ''
      if (getState() && getState().search && getState().search[section]) {
        missingObj = getState().search[section].filter(
          f => dnbObj.indexOf(f) == -1
        )[0]
      }
      if (missingObj) {
        // let param_name = ''
        // let param_value = ''
        config.CONSTANTS[PARAMS].map(dnb => {
          if (
            getState().search.defaultSearchParams[dnb] &&
            getState().search.defaultSearchParams[dnb].indexOf(missingObj) != -1
          ) {
            getState().search.defaultSearchParams = Object.assign(
              {},
              getState().search.defaultSearchParams,
              {
                [dnb]: getState()
                  .search.defaultSearchParams[dnb].split(',')
                  .filter(f => f != missingObj),
              }
            )
            dispatch(
              HandleTextEdit(
                typeof getState().search.defaultSearchParams[dnb] === 'object'
                  ? getState().search.defaultSearchParams[dnb].join(',')
                  : getState().search.defaultSearchParams[dnb],
                dnb.split('.')[1],
                dnb.split('.')[0]
              )
            )
            dispatch({
              type: UPDATE_DUNS_SECTION,
              payload: false,
              value: missingObj,
              section,
            })
          }
        })
      }
    } else if (dnbObj && !dnbObj.length) {
      config.CONSTANTS[PARAMS].map(dnb => {
        if (getState().search.defaultSearchParams[dnb]) {
          dispatch(HandleTextEdit('', dnb.split('.')[1], dnb.split('.')[0]))
          dispatch({
            type: SET_PROPS,
            payload: {
              key: section,
              value: [],
            },
          })
        }
      })
    }
  }
}
function getSelectValues (params) {
  let selectedparams = []
  if (params && params.split(',')) {
    params.split(',').map(p => {
      selectedparams.push(p.replace(new RegExp('"', 'g'), ''))
      // selectedparams.push({
      //  param_value_label: p
      // })
    })
  }
  return selectedparams
}
function checkQuantity (optType, options, searchParams) {
  let dummy = {}
  if (searchParams[optType]) {
    options.map((opt, i) => {
      dummy[Object.keys(opt)[0]] = 'redun'
    })

    let params = searchParams[optType].split(',')

    let val = ''
    params.map((obj, ind) => {
      if (!dummy[obj]) {
        val = obj
      }
    })
    if (!val) return false

    let range_type =
      val.indexOf('-') != -1 &&
      val.indexOf('-') != 0 &&
      val.indexOf('-') != val.length - 1
        ? 'between'
        : val.indexOf('-') != -1
        ? val[0] == '-'
          ? 'lesser'
          : val[val.length - 1] == '-'
          ? 'greater'
          : 'equal'
        : 'equal'

    let retObj = {}
    if (optType == 'site.sales') {
      retObj = {
        range_type: range_type,
        range_change:
          range_type != 'between'
            ? val.replace('-', '') / 1000000 > 1
              ? val.replace('-', '') / 1000000 + 'M'
              : val.replace('-', '')
            : null,
        min_range_change:
          range_type == 'between'
            ? val.split('-').length
              ? val.split('-')[0] / 1000000 > 1
                ? val.split('-')[0] / 1000000 + 'M'
                : val.split('-')[0]
              : ''
            : '',
        max_range_change:
          range_type == 'between'
            ? val.split(',').length
              ? val.split('-')[1] / 1000000 > 1
                ? val.split('-')[1] / 1000000 + 'M'
                : val.split('-')[1]
              : ''
            : '',
      }
    } else {
      retObj = {
        range_type: range_type,
        range_change: range_type != 'between' ? val.replace('-', '') : null,
        min_range_change:
          range_type == 'between'
            ? val.split('-').length
              ? val.split('-')[0]
              : ''
            : '',
        max_range_change:
          range_type == 'between'
            ? val.split(',').length
              ? val.split('-')[1]
              : ''
            : '',
      }
    }

    return retObj
  } else return false
}

const ACTION_HANDLERS = {
  [UPDATE_DUNS_SECTION]: (state, action) => {
    let section = []
    let newState = Object.assign({}, state)
    if (newState[action.section]) {
      newState[action.section].map(d => {
        section.push(d)
      })
    }
    if (action.payload) {
      section.push(action.value)
    } else {
      section = section.filter(f => f != action.value)
    }
    return Object.assign({}, state, { [action.section]: section })
  },
  [OMNI_SEARCH_OPTION_SELECTED]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tech, i) => {
        if (
          i == action.techInd &&
          action.value &&
          action.value.length &&
          action.value[0].param_value &&
          action.value[0].param_value != 'all'
        ) {
          let keytype = action.key.split('.')[action.key.split('.').length - 1]
          let tech_id = action.value[0].param_name
            .split('.')
            .filter(f => f != keytype && f != config.CONSTANTS.TECH_SEARCH_OBJ)
          let techCode =
            state.searchData.result.install.taxonomy.id_to_code[
              parseInt(tech_id)
            ]
          if (tech_id && tech_id.length) {
            tech_id = tech_id[0]
            if (
              techCode &&
              state.searchData.result.install.taxonomy.major_group[techCode]
            ) {
              tech.major_group =
                state.searchData.result.install.taxonomy.major_group[techCode]
              tech.minor_group = { id: 'all', label: 'All Categories' }
              tech.tech = null
            } else if (
              techCode &&
              state.searchData.result.install.taxonomy.tech[techCode]
            ) {
              tech.tech =
                state.searchData.result.install.taxonomy.tech[techCode]
              tech.minor_group =
                tech.tech.minor_group[Object.keys(tech.tech.minor_group)[0]]
              tech.major_group =
                tech.minor_group.major_group[
                  Object.keys(tech.minor_group.major_group)[0]
                ]
            } else if (techCode) {
              if (state.searchData.result.install.taxonomy.major_group) {
                Object.keys(
                  state.searchData.result.install.taxonomy.major_group
                ).map(maj => {
                  let currentMajorGrp =
                    state.searchData.result.install.taxonomy.major_group[maj]
                  if (
                    currentMajorGrp.minor_group &&
                    currentMajorGrp.minor_group[techCode]
                  ) {
                    tech.major_group = currentMajorGrp
                    tech.minor_group = currentMajorGrp.minor_group[techCode]
                    tech.tech = { id: 'all', label: 'All Categories' }
                  }
                })
              }
            }
          }
        } else if (
          i == action.techInd &&
          action.value &&
          action.value.length &&
          action.value[0].param_value &&
          action.value[0].param_value == 'all'
        ) {
          tech.major_group = { id: 'all', label: 'All Categories' }
          tech.minor_group = null
          tech.tech = null
          tech.search_options = {
            'vendor_name': [{ type: 'any' }],
            'product_name': [{ type: 'any' }],
          }
        }
        tech.product_name_suggestions = []
        tech.vendor_name_suggestions = []
        technologies.push(tech)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [UPDATE_TECH_SUGGESTIONS]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tech, i) => {
        let availabletechs = []
        if (i == action.techInd) {
          if (
            tech.search_options &&
            tech.search_options[`id.${action.suggestType}`]
          ) {
            tech.search_options[`id.${action.suggestType}`].map(a => {
              if (a.value && a.value.length) {
                a.value.map(v => availabletechs.push(v))
              }
            })
          }
          let suggestions = []
          action.payload.map(ap => {
            if (availabletechs && availabletechs.length) {
              if (availabletechs.indexOf(ap.param_value_label) == -1) {
                if (action.techId && action.techId == 'all') {
                  suggestions.push(ap)
                } else {
                  suggestions.push(ap.param_value_label)
                }
              }
            } else {
              if (action.techId && action.techId == 'all') {
                suggestions.push(ap)
              } else {
                suggestions.push(ap.param_value_label)
              }
            }
          })
          tech[action.suggestType + '_suggestions'] = suggestions
        }
        technologies.push(tech)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [QUANTITY_SELECTED]: (state, action) => {
    if (state.quantityToggle && action.payload) {
      let quantityToggle = action.payload
      return Object.assign({}, state, { quantityToggle })
    }
    return Object.assign({}, state)
  },
  [CLEAR_RANGES]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tech, i) => {
        if (
          action.index === i &&
          tech.search_options &&
          tech.search_options['id.quantity']
        ) {
          tech.search_options['id.quantity'].range_change = ''
          tech.search_options['id.quantity'].min_range_change = ''
          tech.search_options['id.quantity'].max_range_change = ''
        }
        technologies.push(tech)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [UPDATE_TECH_SEARCH_CRITERIA]: (state, action) => {
    let newState = Object.assign({}, state)
    newState.defaultSearchParams = newState.defaultSearchParams
      ? newState.defaultSearchParams
      : {}
    if (action.tech_id) {
      if (
        newState.defaultSearchParams &&
        Object.keys(newState.defaultSearchParams).indexOf(action.param_name) !==
          -1
      ) {
        Object.keys(newState.defaultSearchParams).map(dsp => {
          if (dsp === action.param_name) {
            let finalValue = action.param_value
            if (
              action.param_name.indexOf('vendor_name') !== -1 ||
              action.param_name.indexOf('product_name') !== -1
            ) {
              finalValue = addDoubleQuotesToParams(finalValue)
            }
            if (action.tech_id !== 'all') {
              newState.defaultSearchParams[dsp][action.tech_id] = finalValue
            } else newState.defaultSearchParams[dsp] = finalValue
          }
        })
      } else if (
        newState.defaultSearchParams &&
        Object.keys(newState.defaultSearchParams).indexOf(action.param_name) ===
          -1
      ) {
        newState.defaultSearchParams[action.param_name] = {}
        newState.defaultSearchParams[action.param_name][action.tech_id] =
          action.param_value
      }
    }
    return Object.assign({}, state, {
      defaultSearchParams: newState.defaultSearchParams,
    })
  },
  [REMOVE_PILLS_ON_TECH_DELETE]: (state, action) => {
    let newState = Object.assign({}, state)
    if (action.techId) {
      if (
        newState.defaultSearchParams &&
        Object.keys(newState.defaultSearchParams).length
      ) {
        Object.keys(newState.defaultSearchParams).map(dsp => {
          if (
            dsp &&
            dsp.split('.').length &&
            dsp.split('.')[0] === config.CONSTANTS.TECH_SEARCH_OBJ
          ) {
            if (action.techId !== 'all') {
              newState.defaultSearchParams[dsp][action.techId] = ''
            } else if (action.techId === 'all') {
              newState.defaultSearchParams[dsp] = ''
            }
          }
        })
      }
    }
    return Object.assign({}, state, {
      defaultSearchParams: newState.defaultSearchParams,
    })
  },
  [UPDATE_TECH_SEARCH]: (state, action) => {
    let technologies = []
    let presentcriteria = ''
    let newState = Object.assign({}, state)
    if (newState.technologies) {
      let groups = action.searchCriteriaType.split('.')
      let searchCriteriaType = groups.filter(f => f != groups[0]).join('.')

      newState.technologies.map((tec, i) => {
        if (tec && Object.keys(tec)) {
          if (
            tec.tech &&
            tec.tech.id &&
            tec.tech.id != 'all' &&
            tec.tech.id.toString() == action.id.toString() &&
            tec.search_options[searchCriteriaType]
          ) {
            tec.search_options[searchCriteriaType] = getUpdatedSearchCriteria(
              tec,
              searchCriteriaType,
              action.optiontype
            )
          } else if (
            tec.minor_group &&
            tec.minor_group.id &&
            tec.minor_group.id != 'all' &&
            tec.minor_group.id.toString() == action.id.toString() &&
            tec.search_options[searchCriteriaType]
          ) {
            tec.search_options[searchCriteriaType] = getUpdatedSearchCriteria(
              tec,
              searchCriteriaType,
              action.optiontype
            )
          } else if (
            tec.major_group &&
            tec.major_group.id &&
            tec.major_group.id == action.id.toString()
          ) {
            tec.search_options[searchCriteriaType] = getUpdatedSearchCriteria(
              tec,
              searchCriteriaType,
              action.optiontype
            )
          }
          presentcriteria = tec.search_options[searchCriteriaType]
        }
        let techid = getTechId(tec)
        if (techid != 'all') {
          technologies.push(tec)
        } else if (techid == 'all') {
          if (tec && tec.search_options) {
            let pushstatus = false
            config.CONSTANTS.VISIBLE_ALL_CATEGORY_SEARCH_OPTIONS.map(so => {
              if (
                tec.search_options[so] &&
                tec.search_options[so].length &&
                tec.search_options[so][0] &&
                tec.search_options[so][0].length
              ) {
                pushstatus = true
              }
            })
            if (pushstatus) {
              technologies.push(tec)
            }
          }
        }
      })
    }
    if (
      newState.defaultSearchParams &&
      Object.keys(newState.defaultSearchParams).length &&
      newState.defaultSearchParams[action.searchCriteriaType]
    ) {
      if (
        action.id != 'all' &&
        newState.defaultSearchParams[action.searchCriteriaType][action.id]
      ) {
        if (
          newState.defaultSearchParams[action.searchCriteriaType][
            action.id
          ].indexOf('),') != -1 &&
          newState.defaultSearchParams[action.searchCriteriaType][
            action.id
          ].split('),').length
        ) {
          let options = []
          newState.defaultSearchParams[action.searchCriteriaType][action.id]
            .split('),')
            .map(sct => {
              let reqword = sct.replace('(', '').replace(')', '')
              if (
                (reqword[0] == '-' || reqword[0] == '+') &&
                action.optiontype != reqword[0]
              ) {
                options.push(
                  reqword[0] + '(' + reqword.substr(1, reqword.length - 1) + ')'
                )
              } else if (
                reqword[0] != '-' &&
                reqword[0] != '+' &&
                action.optiontype != 'any'
              ) {
                options.push('(' + reqword + ')')
              }
            })
          newState.defaultSearchParams[action.searchCriteriaType][action.id] =
            options && options.length ? options.join(',') : ''
        } else {
          newState.defaultSearchParams[action.searchCriteriaType][action.id] =
            ''
        }
      } else if (
        action.id == 'all' &&
        newState.defaultSearchParams[action.searchCriteriaType]
      ) {
        newState.defaultSearchParams[action.searchCriteriaType] = ''
      } else {
        newState.defaultSearchParams[action.searchCriteriaType][action.id] = ''
      }
    }
    return Object.assign({}, state, {
      technologies,
      presentcriteria,
      defaultSearchParams: newState.defaultSearchParams,
    })
  },
  [ADD_ADDITIONAL_CRITERIA]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tec, i) => {
        if (i == action.techInd && tec.search_options) {
          tec.search_options[action.key].push({ type: 'any' })
        }
        technologies.push(tec)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [TOGGLE_TECH_PANEL]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tec, i) => {
        if (i == action.techInd) {
          tec.collapsed = !tec.collapsed
        }
        technologies.push(tec)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [UPDATE_RANGE_TYPE]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tec, i) => {
        if (i == action.techInd) {
          if (tec.search_options) {
            tec.search_options['id.quantity'] = tec.search_options[
              'id.quantity'
            ]
              ? tec.search_options['id.quantity']
              : { [action.name]: action.value.replace(/^[ ]+|[ ]+$/g, '') }
            tec.search_options['id.quantity'][
              action.name
            ] = action.value.replace(/^[ ]+|[ ]+$/g, '')
          } else {
            tec.search_options = {
              quantity: {
                [action.name]: action.value.replace(/^[ ]+|[ ]+$/g, ''),
              },
            }
          }
        }
        technologies.push(tec)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [CLEAR_ALL_CUSTOMQUANTITY]: (state, action) => {
    let CustomQuantity = {}
    let quantityToggle = {}
    return Object.assign({}, state, { CustomQuantity, quantityToggle })
  },
  [CLEAR_CUSTOMQUANTITY]: (state, action) => {
    let CustomQuantity = state.CustomQuantity
      ? Object.assign({}, state.CustomQuantity)
      : {}
    if (CustomQuantity && Object.keys(CustomQuantity).length) {
      delete CustomQuantity[action.grp]
    }
    return Object.assign({}, state, { CustomQuantity })
  },
  [UPDATE_QUANTITY]: (state, action) => {
    let CustomQuantity = state.CustomQuantity
      ? Object.assign({}, state.CustomQuantity)
      : {}
    if (CustomQuantity && Object.keys(CustomQuantity).length) {
      if (CustomQuantity[action.bustype]) {
        CustomQuantity[action.bustype].quantity[action.name] = action.value
      } else {
        let currentQuantity = { quantity: { [action.name]: action.value } }
        action.name != 'range_type'
          ? Object.assign(currentQuantity.quantity, currentQuantity.quantity, {
            range_type: 'equal',
          })
          : null
        CustomQuantity[action.bustype] = currentQuantity
      }
    } else {
      let currentQuantity = { quantity: { [action.name]: action.value } }
      action.name != 'range_type'
        ? Object.assign(currentQuantity.quantity, currentQuantity.quantity, {
          range_type: 'equal',
        })
        : null
      CustomQuantity[action.bustype] = currentQuantity
    }
    return Object.assign({}, state, { CustomQuantity })
  },
  [SET_CUSTOM_QUANTITY]: (state, action) => {
    let CustomQuantity = state.CustomQuantity
      ? Object.assign({}, state.CustomQuantity)
      : {}
    let quantityToggle = state.quantityToggle
      ? Object.assign({}, state.quantityToggle)
      : {}
    let searchParams = state.defaultSearchParams
    if (searchParams && Object.keys(searchParams).length) {
      let obj1 = checkQuantity(
        'site.staff_in_country',
        _.get(
          state,
          'searchData.result.site.search_options.staff_in_country.options',
          {}
        ),
        searchParams
      )
      if (obj1) {
        CustomQuantity['site.staff_in_country'] = { quantity: obj1 }
        quantityToggle['site.staff_in_country'] = true
      }

      let obj2 = checkQuantity(
        'site.staff_in_site',
        _.get(
          state,
          'searchData.result.site.search_options.staff_in_site.options',
          {}
        ),
        searchParams
      )
      if (obj2) {
        CustomQuantity['site.staff_in_site'] = { quantity: obj2 }
        quantityToggle['site.staff_in_site'] = true
      }

      let obj3 = checkQuantity(
        'site.pc_in_country',
        _.get(
          state,
          'searchData.result.site.search_options.pc_in_country.options',
          {}
        ),
        searchParams
      )
      if (obj3) {
        CustomQuantity['site.pc_in_country'] = { quantity: obj3 }
        quantityToggle['site.pc_in_country'] = true
      }

      let obj4 = checkQuantity(
        'site.pc_in_site',
        _.get(
          state,
          'searchData.result.site.search_options.pc_in_site.options',
          {}
        ),
        searchParams
      )
      if (obj4) {
        CustomQuantity['site.pc_in_site'] = { quantity: obj4 }
        quantityToggle['site.pc_in_site'] = true
      }
      let obj5 = checkQuantity(
        'site.sales',
        _.get(state, 'searchData.result.site.search_options.sales.options', {}),
        searchParams
      )
      if (obj5) {
        CustomQuantity['site.sales'] = { quantity: obj5 }
        quantityToggle['site.sales'] = true
      }
    }
    return Object.assign({}, state, { CustomQuantity, quantityToggle })
  },
  [HANDLE_SEARCH_OPTION]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tec, i) => {
        if (
          i == action.techInd &&
          tec.search_options &&
          tec.search_options[action.key] &&
          tec.search_options[action.key].length &&
          tec.search_options[action.key][action.index]
        ) {
          tec.search_options[action.key][action.index][action.keytype] =
            action.value
        }
        technologies.push(tec)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [SET_DEFAULT_TECHNOLOGIES]: (state, action) => {
    let technologies = []
    let filterItems = state.defaultSearchParams
      ? Object.keys(state.defaultSearchParams)
      : []
    let selectedGrpsIds = []
    if (filterItems.length) {
      let existingTechObj = getExistingObj(state.defaultSearchParams)
      if (existingTechObj && Object.keys(existingTechObj).length) {
        Object.keys(existingTechObj).map(ex => {
          let tech = {}
          if (
            state.majorgroupIds &&
            state.majorgroupIds.indexOf(ex.toString()) != -1
          ) {
            // if selected id is a major group's id
            let mj_grp =
              state.searchData.result.install.taxonomy.major_group[
                state.searchData.result.install.taxonomy.id_to_code[
                  ex.toString()
                ]
              ]
            if (mj_grp && Object.keys(mj_grp)) {
              tech.major_group = mj_grp
              tech.minor_group = { id: 'all' }
              tech.tech = null
            }
          } else if (
            state.minorgroupIds &&
            state.minorgroupIds.indexOf(ex.toString()) != -1
          ) {
            // if selected id is a minor group's id
            Object.keys(
              state.searchData.result.install.taxonomy.major_group
            ).map(mj => {
              if (
                state.searchData.result.install.taxonomy.major_group[mj]
                  .minor_group
              ) {
                Object.keys(
                  state.searchData.result.install.taxonomy.major_group[mj]
                    .minor_group
                ).map(mn => {
                  if (
                    state.searchData.result.install.taxonomy.major_group[mj]
                      .minor_group[mn].id == ex.toString()
                  ) {
                    tech.major_group =
                      state.searchData.result.install.taxonomy.major_group[mj]
                    tech.minor_group =
                      state.searchData.result.install.taxonomy.major_group[
                        mj
                      ].minor_group[mn]
                    tech.tech = { id: 'all' }
                  }
                })
              }
            })
          } else if (
            state.techgroupIds &&
            state.techgroupIds.indexOf(ex.toString()) != -1
          ) {
            // if selected id is a tech group's id
            Object.keys(state.searchData.result.install.taxonomy.tech).map(
              tg => {
                if (
                  state.searchData.result.install.taxonomy.tech[tg].id ==
                  ex.toString()
                ) {
                  tech.minor_group =
                    state.searchData.result.install.taxonomy.tech[
                      tg
                    ].minor_group[
                      Object.keys(
                        state.searchData.result.install.taxonomy.tech[tg]
                          .minor_group
                      )[0]
                    ]
                  tech.major_group =
                    tech.minor_group.major_group[
                      Object.keys(tech.minor_group.major_group)[0]
                    ]
                  tech.tech = state.searchData.result.install.taxonomy.tech[tg]
                }
              }
            )
          } else if (ex.toString() == 'all') {
            tech.major_group = { id: 0, label: 'Please Select a Category' }
            tech.minor_group = null
            tech.tech = null
          }
          let preOptionKeys =
            existingTechObj[ex] &&
            Object.keys(existingTechObj[ex]) &&
            Object.keys(existingTechObj[ex]).length
              ? Object.keys(existingTechObj[ex])
              : []
          preOptionKeys = preOptionKeys.sort(function(a, b) {
            if (a.split('.')[1] > b.split('.')[1]) return 1
            return -1
          })
          if (preOptionKeys && preOptionKeys.length) {
            preOptionKeys.map(optionkeys => {
              if (
                optionkeys != 'id.quantity' &&
                optionkeys != 'id.active' &&
                existingTechObj[ex][optionkeys]
              ) {
                let search = []
                let optionAvailable = getSelectValues(
                  existingTechObj[ex][optionkeys]
                )
                var requiredValues = {}
                optionAvailable.map(dv => {
                  if (dv[0] == '-' || dv[0] == '+') {
                    if (requiredValues[dv[0]] && requiredValues[dv[0]].length) {
                      requiredValues[dv[0]].push(dv.substr(1, dv.length - 1))
                    } else {
                      requiredValues[dv[0]] = []
                      requiredValues[dv[0]].push(dv.substr(1, dv.length - 1))
                    }
                  } else {
                    if (requiredValues['any'] && requiredValues['any'].length) {
                      requiredValues['any'].push(dv)
                    } else {
                      requiredValues['any'] = []
                      requiredValues['any'].push(dv)
                    }
                  }
                })
                Object.keys(requiredValues).map(rv => {
                  search.push({ type: rv, value: requiredValues[rv] })
                })
                tech.search_options = tech.search_options
                  ? tech.search_options
                  : {}
                tech.search_options[optionkeys] = search // search options assigning
              } else if (
                optionkeys == 'id.quantity' &&
                existingTechObj[ex][optionkeys]
              ) {
                let quantityType =
                  existingTechObj[ex][optionkeys].indexOf('-') != -1 &&
                  existingTechObj[ex][optionkeys].indexOf('-') != 0 &&
                  existingTechObj[ex][optionkeys].indexOf('-') !=
                    existingTechObj[ex][optionkeys].length - 1
                    ? 'between'
                    : existingTechObj[ex][optionkeys].indexOf('-') != -1
                    ? existingTechObj[ex][optionkeys][0] == '-'
                      ? 'lesser'
                      : existingTechObj[ex][optionkeys][
                          existingTechObj[ex][optionkeys].length - 1
                        ] == '-'
                      ? 'greater'
                      : 'equal'
                    : 'equal'
                tech.search_options = tech.search_options
                  ? tech.search_options
                  : {}

                tech.search_options[optionkeys] = {
                  range_type: quantityType,
                  range_change:
                    quantityType != 'between'
                      ? existingTechObj[ex][optionkeys].replace('-', '')
                      : null,
                  min_range_change:
                    quantityType == 'between'
                      ? existingTechObj[ex][optionkeys].split('-').length
                        ? existingTechObj[ex][optionkeys].split('-')[0]
                        : ''
                      : '',
                  max_range_change:
                    quantityType == 'between'
                      ? existingTechObj[ex][optionkeys].split(',').length
                        ? existingTechObj[ex][optionkeys].split('-')[1]
                        : ''
                      : '',
                }
              } else if (optionkeys == 'id.active') {
                tech['id.active'] = existingTechObj[ex][optionkeys]
                tech.search_options = {
                  'id.quantity': '',
                  'id.vendor_name': [{}],
                  'id.product_name': [{}],
                }
              }
            })
          }
          tech.collapsed = true
          if (tech['id.active'] && tech['id.active'].toLowerCase() == 'true') { technologies.push(tech) } else if (!tech['id.active']) technologies.push(tech)
        })
      }
    }

    if (technologies && technologies.length) {
      technologies.map(tech => {
        let tech_id = getTechId(tech)
        if (tech.search_options && Object.keys(tech.search_options).length) {
          if (tech_id != 'all') {
            config.CONSTANTS.VISIBLE_SEARCH_OPTIONS.map(sop => {
              if (Object.keys(tech.search_options).indexOf(sop) == -1) {
                tech.search_options[sop] =
                  sop == 'id.product_name' || sop == 'id.vendor_name'
                    ? [{ type: 'any' }]
                    : ''
              }
            })
          } else if (tech_id == 'all') {
            config.CONSTANTS.VISIBLE_ALL_CATEGORY_SEARCH_OPTIONS.map(sop => {
              if (Object.keys(tech.search_options).indexOf(sop) == -1) {
                tech.search_options[sop] =
                  sop == 'product_name' || sop == 'vendor_name'
                    ? [{ type: 'any' }]
                    : ''
              }
            })
          }
        }
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [REMOVE_TECH]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tech, techind) => {
        if (techind != action.payload) {
          technologies.push(tech)
        }
      })
    }
    return Object.assign({}, state, { technologies })
  },

  [DESELECT_LEADS]: (state) => {
    return {
      ...state,
      defaultSearchParams: {
        ...state.defaultSearchParams,
        leads: [],
      }
    }
  },

  [UPDATE_TECH_TECHGROUP]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tech, techInd) => {
        if (
          techInd == parseInt(action.techIndex) &&
          action.techGroupCode != 'all' &&
          action.techGroupCode != '0'
        ) {
          tech.tech =
            action.techGroupCode && tech.minor_group && tech.minor_group.tech
              ? tech.minor_group.tech[action.techGroupCode]
              : {}
          tech.search_options = {
            'id.quantity': '',
            'id.vendor_name': [{ type: 'any' }],
            'id.product_name': [{ type: 'any' }],
          }
          tech.active = 'true'
        } else if (
          techInd == parseInt(action.techIndex) &&
          (action.techGroupCode == 'all' || action.techGroupCode == '0')
        ) {
          tech.search_options = null
          tech.tech = { id: action.techGroupCode }
          if (action.techGroupCode == 'all') {
            tech.search_options = {
              'id.quantity': '',
              'id.vendor_name': [{ type: 'any' }],
              'id.product_name': [{ type: 'any' }],
            }
            tech.active = 'true'
          }
        }
        technologies.push(tech)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [UPDATE_TECH_MAJORGROUP]: (state, action) => {
    let technologies = []

    // tech.active = 'true'
    if (action.majorGroupId && action.majorGroupId == 'all') {
      let tech = {}
      tech.major_group = { id: 'all', label: 'All Categories' }
      tech.minor_group = null
      tech.tech = null
      tech.search_options = {
        'id.quantity': '',
        'id.vendor_name': [{ type: 'any' }],
        'id.product_name': [{ type: 'any' }],
      }
      technologies.push(tech)

      if (state.technologies && state.technologies.length) {
        state.technologies.map(tec => {
          if (Object.keys(tec).length) technologies.push(tec)
        })
      }
      return Object.assign({}, state, { technologies })
    }

    let majorGroupCode =
      action.majorGroupId &&
      action.majorGroupId != '0' &&
      state.searchData.result.install.taxonomy &&
      state.searchData.result.install.taxonomy.id_to_code
        ? state.searchData.result.install.taxonomy.id_to_code[
            action.majorGroupId.toString()
          ]
        : 0
    if (state.technologies) {
      state.technologies.map((tech, ind) => {
        if (ind == parseInt(action.techIndex)) {
          tech.major_group =
            majorGroupCode && parseInt(majorGroupCode)
              ? state.searchData.result.install.taxonomy.major_group[
                  majorGroupCode
                ]
              : null
          tech.minor_group = null
          tech.tech = null
        }
        technologies.push(tech)
      })
    }
    if (
      state.searchData &&
      state.searchData.result &&
      state.searchData.result.install &&
      state.searchData.result.install.taxonomy
    ) {
      let allMajorgrps = []
      Object.keys(state.searchData.result.install.taxonomy.major_group).map(
        mg => {
          allMajorgrps.push(
            state.searchData.result.install.taxonomy.major_group[mg]
          )
        }
      )

      let technologiesGrpsIds = []
      if (technologies && technologies.length) {
        technologies.map(tech => {
          if (tech.major_group) {
            technologiesGrpsIds.push(tech.major_group.id)
          }
        })
      }
      let selectedGrpsIds = []
      if (
        state.defaultSearchParams &&
        Object.keys(state.defaultSearchParams).length
      ) {
        Object.keys(state.defaultSearchParams).map(dsp => {
          if (
            dsp.toString() &&
            dsp.toString().split('.').length &&
            dsp.toString().split('.')[0] == config.CONSTANTS.TECH_SEARCH_OBJ
          ) {
            Object.keys(state.defaultSearchParams[dsp]).map(installObj => {
              if (selectedGrpsIds.indexOf(installObj.toString()) == -1) {
                selectedGrpsIds.push(installObj.toString())
              }
            })
          }
        })
      }
    }
    return Object.assign({}, state, { technologies })
  },
  [UPDATE_TECH_MINORGROUP]: (state, action) => {
    let technologies = []
    if (state.technologies) {
      state.technologies.map((tech, ind) => {
        if (
          ind == parseInt(action.techIndex) &&
          action.minorGroupCode != 'all' &&
          action.minorGroupCode != '0'
        ) {
          tech.minor_group =
            tech.major_group &&
            tech.major_group.minor_group &&
            parseInt(action.minorGroupCode)
              ? tech.major_group.minor_group[action.minorGroupCode]
              : {}
          tech.tech = {}
          tech.search_options = null
        } else if (
          ind == parseInt(action.techIndex) &&
          (action.minorGroupCode == 'all' || action.minorGroupCode == '0')
        ) {
          tech.minor_group = { id: action.minorGroupCode }
          tech.tech = null
          tech.search_options = null
          if (action.minorGroupCode == 'all') {
            tech.search_options = {
              'id.quantity': '',
              'id.vendor_name': [{ type: 'any' }],
              'id.product_name': [{ type: 'any' }],
            }
            tech.active = 'true'
          } else {
            tech.search_options = null
          }
        }
        technologies.push(tech)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [ADD_TECHNOLOGY]: (state, action) => {
    let technologies = []
    technologies.push({})
    if (state.technologies) {
      state.technologies.map(tech => {
        tech.collapsed = false
        technologies.push(tech)
      })
    }
    return Object.assign({}, state, { technologies })
  },
  [RESET_DEFAULT]: (state, action) => {
    return Object.assign({}, state, { defaultSearchParams: {} })
  },
  [TOGGLE_UPDATE]: (state, action) => {
    return Object.assign({}, state, { isCountsUpdate: action.payload })
  },
  [SET_DEFAULT_SEARCH]: (state, action) => {
    let location_exclude_area = false
    let exclude_industry_code = false
    let filterItems = action.payload ? Object.keys(action.payload) : []

    if (
      action.payload.result.search_params &&
      action.payload.result.search_params[
        config.CONSTANTS.ZIPCODE_PARAM_NAME
      ] &&
      action.payload.result.search_params[
        config.CONSTANTS.ZIPCODE_PARAM_NAME
      ].split(',').length
    ) {
      let codes = action.payload.result.search_params[
        config.CONSTANTS.ZIPCODE_PARAM_NAME
      ].split(',')
      let negcodes = codes.filter(c => c[0] == '-')
      if (codes.length == negcodes.length) {
        location_exclude_area = true
      }
    }
    if (
      action.payload.result.search_params &&
      action.payload.result.search_params[
        config.CONSTANTS.SICCODE_PARAM_NAME
      ] &&
      action.payload.result.search_params[
        config.CONSTANTS.SICCODE_PARAM_NAME
      ].split(',').length
    ) {
      let codes = action.payload.result.search_params[
        config.CONSTANTS.SICCODE_PARAM_NAME
      ].split(',')
      let negcodes = codes.filter(c => c[0] == '-')
      if (codes.length == negcodes.length) {
        exclude_industry_code = true
      }
    }
    let dunsection = []
    config.CONSTANTS.DNB_PARAMS.map(dnb => {
      if (action.payload.result.search_params[dnb]) {
        action.payload.result.search_params[dnb].split(',').map(d => {
          dunsection.push(d)
        })
      }
    })
    let citysection = []
    config.CONSTANTS.LOCATION_SET_PARAMS.map(lp => {
      if (action.payload.result.search_params[lp]) {
        action.payload.result.search_params[lp].split(',').map(d => {
          citysection.push(d)
        })
      }
    })
    return Object.assign({}, state, {
      defaultSearchParams: action.payload.result.search_params,
      filterItems,
      location_exclude_area,
      exclude_industry_code,
      dunsection,
      citysection,
    })
  },

  [ENFORCED_CRITERIA_NOTE]: (state, action) => {
    return Object.assign({}, state, {
      enforcedCriteriaNote: action.payload.result.enforced_search.label,
    })
  },
  [SET_DEFAULT_PARAMS_ON_SAVE]: (state, action) => {
    let defaultSearchParams = Object.assign({}, state.defaultSearchParams)

    let filterItems = []
    if (action.payload) {
      defaultSearchParams[action.payload.param_name] =
        action.payload.param_value
    }
    filterItems = Object.keys(defaultSearchParams)

    return Object.assign({}, state, {
      defaultSearchParams: defaultSearchParams,
      filterItems: filterItems,
    })
  },

  [SET_ALL_DEFAULT_PARAMS]: (state, action) => {
    let defaultSearchParams = action.payload
    let filterItems = Object.keys(defaultSearchParams)
    return Object.assign({}, state, {
      defaultSearchParams,
      filterItems,
    })
  },

  [UPDATE_PRIORITY_INDUSTRY]: (state, action) => {
    let defaultPriority = action.payload
    return Object.assign({}, state, { defaultPriority })
  },

  [UPDATE_INDUSTRY_STANDARD]: (state, action) => {
    let defaultStandard = action.payload
    return Object.assign({}, state, { defaultStandard })
  },

  [SET_LIMITED_PARAMS]: (state, action) => {
    if (state.showLess) {
      return Object.assign({}, state, {
        showLess: !state.showLess,
      })
    } else {
      return Object.assign({}, state, {
        showLess: !state.showLess,
      })
    }
  },

  [SET_PILLBOX_OVERFLOW]: (state, action) => {
    var stickyChipsContainer = document.getElementsByClassName(
      'sticky-pills-container'
    )[0]
    if (typeof stickyChipsContainer === 'undefined') return state

    var containerWidth = stickyChipsContainer.offsetWidth
    if (
      containerWidth === state.pillboxContainerWidth &&
      !action.payload.filtersChanged
    ) { return state }
    var staticContainer = document.getElementsByClassName('filter-pills')[0]
    var chips = staticContainer.getElementsByClassName('chip-span')
    var maxPills = chips.length
    var cumulatedChipsWidth = 0
    var overflow = false
    // We need to set this buffer so that the show more is easily seen at the edge of the page
    var buffer = 60
    if (chips.length > 0) {
      for (var i = 0; i < chips.length; i++) {
        cumulatedChipsWidth += chips[i].offsetWidth
        if (chips.length - i <= 1) buffer = 20
        if (cumulatedChipsWidth + buffer > containerWidth) {
          overflow = true
          maxPills = i
          break
        }
      }
    }
    return Object.assign({}, state, {
      setPillboxOverflow: false,
      pillboxOverflow: overflow,
      pillboxContainerWidth: containerWidth,
      maxPills: maxPills,
    })
  },

  [LOAD_LABELS]: (state, action) => {
    let majorgroupIds = []
    let minorgroupIds = []
    let techgroupIds = []
    if (_.get(action, 'payload.result.install.taxonomy.majorgroup')) {
      Object.keys(action.payload.result.install.taxonomy.major_group).map(
        mj => {
          majorgroupIds.push(
            action.payload.result.install.taxonomy.major_group[mj].id
          )
          if (
            action.payload.result.install.taxonomy.major_group[mj].minor_group
          ) {
            Object.keys(
              action.payload.result.install.taxonomy.major_group[mj].minor_group
            ).map(min => {
              minorgroupIds.push(
                action.payload.result.install.taxonomy.major_group[mj]
                  .minor_group[min].id
              )
            })
          }
        }
      )
    }
    if (_.get(action, 'payload.result.install.taxonomy.tech')) {
      Object.keys(action.payload.result.install.taxonomy.tech).map(tec => {
        techgroupIds.push(action.payload.result.install.taxonomy.tech[tec].id)
      })
    }
    return Object.assign({}, state, {
      searchData: {
        result: action.payload.result,
      },
      majorgroupIds,
      minorgroupIds,
      techgroupIds,
    })
  },
  [SET_PROPS]: (state, action) => {
    if (action.payload && action.payload.key) {
      return Object.assign({}, state, {
        [action.payload.key]: action.payload.value,
      })
    }
    return Object.assign({}, state)
  },
  [LOAD_COUNTS]: (state, action) => {
    return Object.assign({}, state, {
      countsData: action.payload.result,
    })
  },
  [LOAD_GRAPHS]: (state, action) => {
    let graphdata = {}
    const allCounts = _.get(action, 'payload.result')
    if (!allCounts) return
    _.forEach(allCounts, (value, key) => {
      const count = _.get(value, 'count', 0)
      const total = _.get(value, 'total', 0)
      graphdata[key] = [
        { label: 'count', value: count },
        { label: 'remainder', value: Math.round(total - count) },
      ]
      if (key === 'contact') {
        let emailsCount =
          _.get(value, 'has_email.count', { '0': 0, '1': 0 })[1] || 0
        let emailsTotal =
          _.get(value, 'has_email.total', { '0': 0, '1': 0 })[1] || 0
        graphdata['email'] = [
          { label: 'count', value: emailsCount },
          { label: 'remainder', value: Math.round(emailsTotal - emailsCount) },
        ]
      }
    })
    return Object.assign({}, state, {
      graphData: graphdata,
    })
  },
  [OVERFLOW_POSTAL_CODE_TAB]: (state, action) => {
    let overflowPostalCodeTab = action.payload
    return Object.assign({}, state, { overflowPostalCodeTab })
  },
  [POSTAL_CODE_ADD_VALUE]: (state, action) => {
    return Object.assign({}, state, {
      postalCode: {
        values: state.postalCode.values.push(action.payload[0]),
        ...state.postalCode
      }
    }
    )
  },

  [POSTAL_CODE_EMPTY_VALUES]: (state, action) => {
    return Object.assign({}, state, {
      postalCode: {
        ...state.postalCode,
        values: []
      }
    }
    )
  },

  [LEADNEURON.SAVE_ITEMS]: (state, action) => {
    return Object.assign({}, state, { leadneuron:
    {
      data: action.payload,
      ...state.leadneuron
    } })
  },

  [LEADNEURON.ADD_SELECTION]: (state, action) => {
    state.leadneuron.selections[action.payload.index] = action.payload.value
    return Object.assign({}, state, { leadneuron:
    {
      selections: state.leadneuron.selections,
      ...state.leadneuron
    } })
  },

  [LEADNEURON.REMOVE_SELECTION]: (state, action) => {
    let selections = state.leadneuron.selections
    switch (action.payload) {
      case 0:
        selections[0] = -1
        break
      case 1:
        selections[1] = -1
        break
    }

    return Object.assign({}, state, { leadneuron:
    {
      selections,
      ...state.leadneuron
    }
    })
  },

  [LIST_STANDARD.REMOVE_LST_SELECTION]: (state, action) => {
    let defaultSearchParams = state.defaultSearchParams
    let filterItems = state.filterItems

    switch (action.payload) {
      case 'site.site_list_id':
        delete defaultSearchParams['site.site_list_id']
        delete defaultSearchParams['site.list_standard']
        let filterSIte = filterItems.filter(val => val !== 'site.site_list_id')
        filterItems = filterSIte.filter(el => el !== 'site.list_standard')
        break
      case 'contact.contact_list_id':
        delete defaultSearchParams['contact.contact_list_id']
        delete defaultSearchParams['contact.list_standard']
        let filterContact = filterItems.filter(val => val !== 'contact.contact_list_id')
        filterItems = filterContact.filter(el => el !== 'contact.list_standard')
        break
    }

    return Object.assign({}, state, {
      defaultSearchParams,
      filterItems,
    })
  },

  [LEADNEURON.CLEAR_ALL_SELECTIONS]: (state, action) => {
    return Object.assign({}, state, { leadneuron:
    {
      selections: state.leadneuron.selections.splice(0, 2),
      ...state.leadneuron
    } })
  },
}
const initialState = {
  showAll: false,
  showLess: true,
  pillboxOverflow: false,
  maxPills: 0,
  pillboxContainerWidth: 0,
  isHide: true,
  isCountsUpdate: false,
  exclude_expired: false,
  piechartSize: 150,
  quantityToggle: {},
  isDistanceSearchable: false,
  defaultPriority: '1',
  defaultStandard: 'NAICS',
  overflowPostalCodeTab: false,
  postalCode: {
    values: []
  },
  leadneuron: {
    selections: []
  },
  defaultSearchParams: {},
}
export default function searchReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
