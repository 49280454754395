export const enAdmin = {
  pageTitle: 'Admin',
  administration: 'Administration',
  includeDeactivatedUsers: 'include deactivated users',
  deactivateUser: 'Deactivate User',
  makeUserDeactivated: 'Do you want to deactivate this User?',
  makeUserActive: 'Do you want to make this user active?',
  pleaseEnterEmailAddress: 'Please enter the new user\'s email address',
  emailAddressNote: '*Note: The email address will be used as the new user\'s username. The username must be easy to remember and unique. It cannot be changed manually.',
  searchText: 'Search Text',
  newUser: 'New User'
}
